import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";  
import { useSelector } from 'react-redux';

export const useApiData = (method, apiEndpoint) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const login_token = useSelector((state)=> state.counter.user.login_token);
  const navigate = useNavigate(); // Для перенаправлення на сторінку авторизації
  //localStorage.setItem('login_token', response.login_token)

  useEffect(() => {
    //const login_token = localStorage.getItem('login_token');
    //console.log("login_token",login_token)
    const login_token = localStorage.getItem('login_token');

    const fetchData = async () => {
      try {
        const response = await fetch('https://www.nebo.inupline.com/api/web' + apiEndpoint, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token, // Вам може знадобитися додати змінну login_token або використовувати інші засоби авторизації
          },
        });

        const responseData = await response.json();
        console.log ("useApiData apiEndpoint", 'https://www.nebo.inupline.com/api/web' + apiEndpoint)

        if (response.status === 200) {
          setData(responseData);
          console.log (apiEndpoint, "responseData", responseData)
          setLoading(false);
        } else if (response.status === 409) {
          setError({ status: 409, message: 'Invalid token' });
          //setSchoolClass([]);
          setLoading(false);
          navigate(`/`);

          // Тут ви можете додати код для переадресації на сторінку авторизації
          // Наприклад, використовуючи бібліотеку React Router: history.push("/oauth2");
          //navigate("/oauth2");

        } else {
          setError({ status: response.status, message: responseData.error });
          //setSchoolClass([]);
          setLoading(false);
        }
      } catch (error) {
        setError({ status: 500, message: 'Internal Server Error' });
        //setSchoolClass([]);
        setLoading(false);
      }
    };

    fetchData();
  }, [apiEndpoint ]);

  return { data, loading, error };
};


export const useLoadApiFoto = (method, apiEndpoint) => {
    const [defaultFondo, setDefaultFondo] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPhoto = () => {
      fetch(apiEndpoint, {
        method: method,
        mode: "no-cors"
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Помилка HTTP: ' + response.status);
          }
          setLoading(false);
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            setData(reader.result);
            //console.log (apiEndpoint, "useLoadApiFoto", reader.result)
            setLoading(false);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
            setError({message: error});
            setLoading(false);
        });
    };

    fetchPhoto();
  }, [apiEndpoint]);

  return { data, loading, error };
  };

