import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice ({
    name: 'counter',
    initialState : { 
      counter: 0,
      currentUser: null,
      isLoggedIn: "false",
      error: false,
      missingFile: null,
      posts: {
        id: 123, 
        name: "user_name"
      },
      user: {
        email: "", 
        user_id: "", 
        family_name: "", 
        given_name: "", 
        name: "", 
        picture: "",
        login_token: "",
        open_url: "/",
        ts: "",
      },
      company: {
        id: "", 
        name: ""
      },
      myprojects: [],
      inprojects: []
    },
    reducers:{
      increment(state) {
        state.counter ++;
        console.log("counter +");
      },
      decrement(state) {
        state.counter --;
        console.log("counter -");
      },
      addBy(state, action) {
        state.counter += action.payload;
        console.log("counter =");
      },
      setName: (state, action) => {
        state.user.name = action.payload;
      },
      setFamilyName: (state, action) => {
        state.user.family_name = action.payload;
      },
      setGivenName: (state, action) => {
        state.user.given_name = action.payload;
      },
      setUserEmail: (state, action) => {
        state.user.email = action.payload;
      },
      setUserPicture: (state, action) => {
        state.user.picture = action.payload;
      },
      setLoginToken: (state, action) => {
        state.user.login_token = action.payload;
      },
      setOpenURL: (state, action) => {
        state.user.open_url = action.payload;
      },
      setTS: (state, action) => {
        state.user.ts = action.payload;
      },
      setUserID: (state, action) => {
        state.user.user_id = action.payload;
      },
    }
  })

  //export const CS_actions = counterSlice.actions;  login_token
  export default counterSlice;