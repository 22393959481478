import React, { useState, useEffect } from 'react';
// import AuthenticationContext from 'adal-angular';
// import { GoogleLogin } from '@leecheuk/react-google-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
// import { useGoogleOneTapLogin } from '@react-oauth/google';
//import { googleLogout } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../redux/store';

import { useNavigate} from "react-router-dom";  

import LoadingUI from '../components/loading/loadingui';
import { GetDeviceID } from "../firebase"


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [responce, setResponse] = useState(null);
  const [tokent, setTokent] = useState(null);
  const [loading, setLoading] = useState(true);

  // localStorage.setItem('open_url', "/oauth2")

    //console.log("deviceid",deviceid) 
  const email = useSelector((state)=> state.counter.user.email);
  //const openurl = useSelector((state)=> state.counter.user.open_url);

  const usremail = localStorage.getItem('usremail');
  const usrpicture = localStorage.getItem('usrpicture');
  //const token_device = localStorage.getItem('token_device');
  //const login_token = localStorage.getItem('login_token');
  const usrname = localStorage.getItem('usrname');

  useEffect(() => {
      const login_token = localStorage.getItem('login_token');
      const open_url = localStorage.getItem('open_url');

      // перевірити валідність токену

      fetch('https://www.nebo.inupline.com/api/web/shop/gettime', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
          },
      })
          .then(response => response.json())
          .then(response => {
              if (response.status===200) {
                //console.log("TOKEN OK-" , response , "login_token" ,login_token)
                setLoading(false)

              } else if (response.status===409) {
                //console.log("TOKEN KO-" , response)
                OutME()
                setLoading(false)
              }
          });
          
        GetDeviceID()
      .then(token => {
        // Ваша логіка з використанням отриманого токену
        localStorage.setItem('TokenDevice', token)
      })
      .catch(error => {
        console.error('Помилка TokenDevice:', error);
        // Обробка помилки
      });

    const TokenDevice = localStorage.getItem('TokenDevice');

    const userData = {
        email: usremail,
        name: usrname,
        //picture: usrpicture,
        token_device: TokenDevice
        //birthday: "1900-01-01"
      };

      // якщо ще валідний оновити
  
      fetch('https://www.nebo.inupline.com/api/web/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
          },
          body: JSON.stringify(userData),
      })
          .then(response => response.json())
          .then(response => {
              if (response.status=200) {
                //console.log("api/login auto in", response)
                localStorage.setItem('login_token', response.login_token)
                localStorage.setItem('userid', response.perfil.user_id)

                dispatch(actUser.setUserEmail(response.perfil.user_mail));
                dispatch(actUser.setUserID(response.perfil.user_id));
                dispatch(actUser.setUserPicture(response.perfil.user_picture));
                dispatch(actUser.setName(response.perfil.user_name));
                dispatch(actUser.setLoginToken(response.login_token));
                dispatch(actUser.setTS(response.perfil.ts_accept_v));
                // setResponse(response)
                setTokent(response.login_token)
                    // if (response && response.perfil && response.perfil.ts_accept_v === null) {

                    if (response.perfil.ts_accept_v === null || response.perfil.ts_accept_v === 0) {
                        //console.log("/firstrun auto in")
                        navigate("/firstrun");
                        setLoading(false)
                    } else {
                        //console.log("/ auto in" , response)
                        //navigate(1);
                        setLoading(false)
                    }  
              } else {
                //console.log("Login error-" , response)
              }
              //console.log("userData",response.login_token)
          });          
  }, [tokent]); // AUTOLOAD

  const handleSearchTermChange = (usr) => {
      setLoading(true)

      //console.log("userData",usr)

      // dispatch(actions.posts.id("23"));
      dispatch(actUser.setUserEmail(usr.email));
      dispatch(actUser.setUserPicture(usr.picture));
      dispatch(actUser.setFamilyName(usr.family_name));
      dispatch(actUser.setGivenName(usr.given_name));
      dispatch(actUser.setName(usr.name));

      localStorage.setItem('usremail', usr.email)
      localStorage.setItem('usrpicture', usr.picture)
      localStorage.setItem('usrfamily_name', usr.family_name)
      localStorage.setItem('usrgiven_name', usr.given_name)
      localStorage.setItem('usrname', usr.name)

      GetDeviceID()
      .then(token => {
        // Ваша логіка з використанням отриманого токену
        localStorage.setItem('TokenDevice', token)
      })
      .catch(error => {
        console.error('Помилка TokenDevice:', error);
        // Обробка помилки
      });

    const TokenDevice = localStorage.getItem('TokenDevice');

      const userData = {
        email: usr.email,
        name: usr.name,
        picture: usr.picture,
        token_device: TokenDevice
        //birthday: "1900-01-01"
      };

      //console.log(  "handleSearchTermChange-usr.picture", usr.picture)

      // якщо ще валідний оновити
  
      fetch('https://www.nebo.inupline.com/api/web/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
          },
          body: JSON.stringify(userData),
      })
          .then(response => response.json())
          .then(response => {
              if (response.status=200) {
                //console.log("api/login firs run", response)
                localStorage.setItem('login_token', response.login_token)
                localStorage.setItem('userid', response.perfil.user_id)

                dispatch(actUser.setUserEmail(response.perfil.user_mail));
                //dispatch(actUser.setUserPicture(response.perfil.user_picture));
                dispatch(actUser.setUserID(response.perfil.user_id));
                dispatch(actUser.setName(response.perfil.user_name));
                dispatch(actUser.setLoginToken(response.login_token));
                dispatch(actUser.setTS(response.perfil.ts_accept_v));
                // setResponse(response)
                setTokent(response.login_token)
                  if (response.perfil.ts_accept_v === null || response.perfil.ts_accept_v === 0) {
                    //console.log("/firstrun- firs run", response)
                        navigate("/firstrun");
                    } else {
                        //console.log("/-" , userData)
                        navigate("/");
                    }  
              } else {
              }
          });  
        setLoading(false)
  };

  function OutME() {
    // setUser({});
    // googleLogout();
    dispatch(actUser.setUserEmail(""));
    dispatch(actUser.setUserPicture(""));
    dispatch(actUser.setFamilyName(""));
    dispatch(actUser.setGivenName(""));
    dispatch(actUser.setName(""));
    dispatch(actUser.setLoginToken(""));

    localStorage.setItem('usremail', "")
    localStorage.setItem('usrpicture', "")
    localStorage.setItem('usrfamily_name', "")
    localStorage.setItem('usrgiven_name', "")
    localStorage.setItem('usrname', "")
    localStorage.setItem('login_token', "")
  }

  if (loading){
    return (<LoadingUI tcapt = "Loading"></LoadingUI>)
} else {
  return (
     <GoogleOAuthProvider clientId="993790840435-muhfa9cjp6i88q79fabvav6fd2dn305b.apps.googleusercontent.com">

     <div className="wrapper">
        <header className="header">
          <a href="#" className="logo">
            {/* <svg aria-hidden="true" class="octicon octicon-mark-github" height="48" version="1.1" viewBox="0 0 16 16" width="48"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path></svg> */}
          </a>
          <h1>Sign in NEBO</h1>
        </header>
  
  {email ? 
    <form className="form">
      <input className="button" onClick={() => {OutME()}} defaultValue="out"/>
    </form>
  :
  <form className="form">
    
    <GoogleLogin
          onSuccess={credentialResponse => {
            var decoded = jwt_decode(credentialResponse.credential);
            // console.log(credentialResponse.credential);
            //console.log(decoded);
            // setUser(decoded);
            handleSearchTermChange(decoded);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap = {false }
          type = "icon"
      />
        
  </form>
  }

  <footer className="footer">
    <ul>
      <li>BETA test v3</li>
      {/* <li><a href="#Privacy">Privacy</a></li>
      <li><a href="#Security">Security</a></li>
      <li><a href="#Contact">Contact NEBO v2</a></li> */}
    </ul>
  </footer>
</div>          
    </GoogleOAuthProvider>    
  );
}
}

export default App;
