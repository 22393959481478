import React , {useState, useEffect} from 'react';
import "./style_index.css"
import { useNavigate} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../redux/store';
import iconUsers from './../img/iconUSERS.png';  //sadmin.png avatar.jpeg
import iconUser from './../img/iconUSER.png';  //sadmin.png avatar.jpeg

function Index(props) {
    const navigate = useNavigate();
    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    const dispatch = useDispatch();

    useEffect(() => {
        const userData = {
          email: props.email
        };
    
        fetch('https://www.nebo.inupline.com/api/web/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
              },
              body: JSON.stringify(userData),
          })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
              //console.log("INDEX ", response)
              setProjects(response.project) //joined
              setProjoined(response.joined)
              setInSchool(response.joined)
              dispatch(actUser.setLoginToken(response.login_token)); // оновляємо токен

              //console.log("project ", response.project)
              //console.log("joined ", response.joined)
            }
    }); }, []); // AUTOLOAD http://localhost:3000/projects/new

    const AddProject = () => {
      //cid = null;
      navigate(`/projects/new`);
  };

    return (
        <div>
            <div>
                {/* <div className="project_urls" >
                    <div className="cLabelClassHeaderBar">My Projects</div>
                    <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport" onClick={AddProject}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                </div> */}

                <div className="index_board_project" >
                    {userprojects.map((itemg, index) => ( 
                        <CardProject key = {index} index = {index} obj = {itemg}/>
                    ))}
                </div>
            </div>

            <div>
                {/* <div className="project_urls"  >
                    <div className="cLabelClassHeaderBar">Invited to projects.</div>
                    <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport"><i className="fa fa fa-search" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                </div> */}

                <div className="index_board_project" >
                  {userprojoined.map((itemg, index) => ( 
                      <CardProject key = {index} index = {index} obj = {itemg}/>
                  ))}
                </div>
            </div>
        </div>
    );
}


function CardProject(props) {
    const navigate = useNavigate();
    const [userAll, setUserAll] = useState(0);
    const [userAvatars, setUserAvatars] = useState(0);

    useEffect(() => {
      setUserAll(props.obj.users)
      getFirstUsers()
    }, []); // AUTOLOAD http://localhost:3000/projects/new
  
    const getFirstUsers = () => {
      // Filtramos los user_avatar que no son null
      if (props.obj.users) { 
        const userAvatars = props.obj.users
        .map(user => user.user_avatar)
        .filter(avatar => avatar !== null)
        .slice(0, 3); // Tomamos los primeros tres

        setUserAvatars(userAvatars)
      }
    }
    
    const handleOpenProject = (id) => {
      navigate("/projects");
      localStorage.setItem('projectid', id)
    }
  
    const handleloadurl = (id, dateupdate) => {
      const cacheBuster = new Date(dateupdate).getTime(); // Перетворіть в мілісекунди
      return `https://www.nebo.inupline.com/img/projects/${id}/fondo.jpeg?cache=${cacheBuster}`;
    }
  
    return (
        <div className="index_card_project_list" 
        style={{backgroundImage: `url(${handleloadurl(props.obj.project_id,props.obj.update )})`, backgroundSize: 'cover' }}
        onClick={() => handleOpenProject(props.obj.project_id)}>
            <div className="transbox"></div>
            <div className="numID">{props.obj.project_id}</div>
            <div className="note">{props.obj.project_note }</div>
            <div className="title">{props.obj.project_name}</div>

            <NewIco id={props.obj.project_id} update={"items.update"}/>

            {props.obj.type_id == 6 && <div className="task__levelUser task__levelCard--0" > {props.obj.type_name} </div> }
            {props.obj.type_id == 7 && <div className="task__levelUser task__levelCard--1" > {props.obj.type_name}  </div> }
            {props.obj.type_id == 2 && <div className="task__levelUser task__levelCard--2" > {props.obj.type_name}  </div> }
            {props.obj.type_id == 3 && <div className="task__levelUser task__levelCard--3" > {props.obj.type_name}  </div> }
            {props.obj.type_id == 4 && <div className="task__levelUser task__levelCard--4" > {props.obj.type_name}  </div> }

            {/* <div className="iuButtonUsers">
              <img className="itButtonUsers" src={iconUsers} alt="logo" /> 
              <div className="ittButtonUsers">{!userAll ? "0" :userAll.length } </div>
            </div> */}

            {/* {!props.obj.userico ? null : <div className="AutorBox" >
              <img className= "sLogo" src={!props.obj.userico ? iconUser : props.obj.userico } referrerPolicy="no-referrer" alt="logo "/>
              <div className='arole'>autor:</div>
              <span className="uname"> &nbsp;&nbsp;&nbsp;{props.obj.username}</span>
            </div>} */}

            

            <div className="InvertBox">
              {!userAvatars || userAll.length == 0? 
                <img className= "invertLogo" src={iconUser} referrerPolicy="no-referrer" alt="logo "/> : 
              userAvatars.map((avatar, index) => (
                <img
                  key={index}
                  className={"invertLogo " + avatar}
                  src={!avatar || avatar == null ? iconUser : avatar}
                  referrerPolicy="no-referrer"
                  alt={`User Avatar ${index + 1}`}
                /> 
              ))}
              <div className="invertName">{!userAll ? "0" :userAll.length } </div>
            </div>

            {!props.obj.im_ico ? null : <div className="AutorIcon" >
              <img className= "sLogo" src={!props.obj.im_ico ? iconUser : props.obj.im_ico } referrerPolicy="no-referrer" alt="logo "/>
              <div className='arole'>{props.obj.im_rolename}</div>
              <span className="uname"> &nbsp;&nbsp;&nbsp;{props.obj.im_name}</span>
            </div>}
            
            {/* {!userAll ? null : <div className="UserBox" >
                  <img className= "sLogo" src={!userAll ? iconUser : iconUser } referrerPolicy="no-referrer" alt="logo "/>
                  <div className="uname">{!userAll ? "0" :userAll.length } </div>

                  <div className='arole'>{userAll.}</div>
                  <span className="uname"> &nbsp;&nbsp;&nbsp;{project.im_name}</span>
              </div> } */}
        </div>
    );
  }

  function NewIco(props) {
    const [pid, setPid] = useState(props.id);
    //const randomCacheBuster = Math.floor(Math.random() * 10000);
    const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди
    const [defaultPhoto, setDefaultPhoto] = useState(true);
    const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + props.id + `/logo.png?cache=${cacheBuster}`);
  
    //setPid(id);
    return (
      <img className="prlogo" src={selectedPhoto} alt="Avatar" />
    )
  } 

export default Index;


