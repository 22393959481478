import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import QRCode from 'qrcode.react';
import { useNavigate, useParams} from "react-router-dom";  
import icon_qrscan from '../../img/qrscaner.png';  //sadmin.png avatar.jpeg temp02.png

 const QRscaner = (props) => {
  const [data, setData] = useState(0);
  const [selected, setSelected] = useState("environment");
  const user_leng_ui = localStorage.getItem('user_leng_ui');
  const projectid = localStorage.getItem('projectid');
  const classid = localStorage.getItem('classid');
  const userid = localStorage.getItem('userid');
  const [school, setSchool] = useState('SCHOOL');
  const navigate = useNavigate();

  function OutME(event) {
    navigate(-1); // /invite qrjoin
  }

  function JoinME(event) {
    navigate("/qrjoin"); // /invite qrjoin
  }

  function QRactive(event) {
    setData(event); // /invite qrjoin
  }

  return (
    <div id = "ANKI_MODE" className="ank_main" >
            {/* <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div> */}
            <div className='appinfopanel'>
              <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
                <div className="tHello" >
                    {user_leng_ui === "0" && "QR Share"}
                    {user_leng_ui === "1" && "QR Compartir"}
                    {user_leng_ui === "2" && "QR Поділитися"}
                </div>
                <div className='icon_qrscaner'><img src={icon_qrscan} onClick={(e) => {JoinME(e)}} style={{width:"32px", padding:"7px"}}></img></div>
              </div>

              <div className='TabCheck'> 
                <div className='tab-group'>
                    <div className={data === 0 ? 'a active' : 'a pasive'} onClick={(e) => {QRactive(0)}}> 
                        {user_leng_ui === "0" && "WITH APP"}
                        {user_leng_ui === "1" && "CON APP"}
                        {user_leng_ui === "2" && "З ДОДАТКОМ"}   
                    </div> 
                    <div className={data === 1 ? 'b active' : 'b pasive'} onClick={(e) => {QRactive(1)}}> 
                        {user_leng_ui === "0" && "WITHOUT APP"}
                        {user_leng_ui === "1" && "SIN APP"}
                        {user_leng_ui === "2" && "БЕЗ ДОДАТКУ"} 
                    </div> 
                </div>
              </div>

    {data === 0 ? 
      <>
        <QRCode className="qrLogo" value={"P" + projectid + "C" + classid + "U" + userid} size={360} fgColor={"#000000"} bgColor={"#FFFFFF"} level={"H"} includeMargin={true} imageSettings={{src:"/img/miniU.png", height: 64, width: 45,excavate:true}}/>
        <div className='addnotetext'> 
            {user_leng_ui === "0" && "Code for users who are registered in the app and only want to join a group or class"}
            {user_leng_ui === "1" && "Código para usuarios que están registrados en la aplicación y solo quieren unirse a un grupo o clase"}
            {user_leng_ui === "2" && "Код для користувачів які зареєстровані в додатку та тільки хочуть підключитися до групи або класу"} 
        </div>
      </>:<>
      <QRCode className="qrLogo" value={"https://www.nebo.inupline.com/?code=" + "P" + projectid + "C" + classid + "U" + userid} size={360} fgColor={"#000000"} bgColor={"#FFFFFF"} level={"L"} includeMargin={true} imageSettings={{src:"/img/miniU.png", x: 238, y:220, height: 64, width: 45,excavate:true}}/>
      <div className='addnotetext'> 
            {user_leng_ui === "0" && "Code to directly join a class or group without an app"}
            {user_leng_ui === "1" && "Código para unirse directamente a una clase o grupo sin una aplicación"}
            {user_leng_ui === "2" && "Код для прямого підключення без додатку"} 
      </div>
      </>}
        

      <div className='addcodetext'> P{projectid}C{classid}U{userid} </div>

    </div>
  );
};

export default QRscaner