import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';

import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from '../../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png

function Index(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    //const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    //const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    const login_userid = localStorage.getItem('userid');
    const usrname = localStorage.getItem('usrname');
    const activeProjectId = localStorage.getItem('projectid');

    const [userThemaList, setUserThemaList] = useState([]); // IMPORT USER


    useEffect(() => {
        const userData = {
          email: usremail
        };
    
        fetch('https://www.nebo.inupline.com/api/web/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
              },
              body: JSON.stringify(userData),
          })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
              console.log("INDEX ", response)
              setProjects(response.project) //joined
              setProjoined(response.joined)
              //setInSchool(response.joined)

              // console.log("project ", response.project)
              // console.log("joined ", response.joined)
            }
    }); 
  
    GetUserThemaList()
  }, []); // AUTOLOAD http://localhost:3000/projects/new

    const GetUserThemaList = () => {
      const login_token = localStorage.getItem('login_token');

      fetch('https://www.nebo.inupline.com/api/web/anki/userthemas/' + login_userid, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setUserThemaList(response.decks3)

            console.log("userthemas" , login_userid, response)
          }
      });
    };

  const OPEN_deck = (id) => {
    localStorage.setItem('deckid', id)
    console.log("OPEN_deck" , id)
    navigate(`/deckid`);
  };

  // const OPEN_Projects = () => {
  //   navigate(`/projects`);
  // };

    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
              <div className="index_board_project" >
                {userprojects.map((itemg, index) => ( 
                    <CardProject key = {index} index = {index} obj = {itemg}/>
                ))}

                {userprojoined.map((itemg, index) => ( 
                    <CardProject key = {index} index = {index} obj = {itemg}/>
                ))}
              </div>

              <div className="userThemaslist" >
                {userThemaList.map((itemg, index) => ( 
                    <DecksCard  
                    key = {index} 
                    id={itemg.id} 
                    deckid={itemg.deckid} 
                    title={itemg.title} 
                    label = {itemg.label}  
                    note = {itemg.note}  
                    cs={itemg.title} 
                    cards = {itemg.cards} 
                    ico={itemg.ico} 
                    colort={itemg.colort}
                    colotb={itemg.colotb}
                    status={itemg.status}
                    selid={(id) =>OPEN_deck(id)}/>
                ))}
            </div>
          </div>


                    


        </div>
    );
}

function CardProject(props) {
    const navigate = useNavigate();
    const activeProjectId = localStorage.getItem('projectid');

    const handleOpenProject = (id) => {
      //navigate("/class/" + id);
    }
  
    const handleloadurl = (id, dateupdate) => {
      const cacheBuster = new Date(dateupdate).getTime(); // Перетворіть в мілісекунди
      console.log("fondo cacheBuster", cacheBuster)

      return `https://www.nebo.inupline.com/img/projects/${activeProjectId}/fondo.jpeg?cache=${cacheBuster}`;
    }
    
    useEffect(() => {
        console.log("props.obj", props)
    }, [props]); // AUTOLOAD
  
    return (
        <div className="index_card_project_list" 
        style={{backgroundImage: `url(${handleloadurl(props.obj.project_id,props.obj.project_update )})`, backgroundSize: '200%, 100%' }}
        onClick={() => handleOpenProject(props.obj.project_id)}>
            <div className="transbox"></div>
            <div className="numID">{props.obj.project_id}</div>
            <div className="note">{props.obj.project_note }</div>
            <div className="title">{props.obj.project_name}</div>

            <NewIco id={props.obj.project_id} update={props.obj.project_update}/>
        </div>
    );
  }

  function NewIco(props) {
    const [pid, setPid] = useState(props.id);
    //const randomCacheBuster = Math.floor(Math.random() * 10000);
    const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди
    const [defaultPhoto, setDefaultPhoto] = useState(true);
    const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + props.id + `/logo.png?cache=${cacheBuster}`);
  
    console.log("logo cacheBuster", cacheBuster)
    //setPid(id);
    return (
      <img className="prlogo" src={selectedPhoto} alt="Avatar" />
    )
  } 

export default Index;

export function DecksCard(props) {
  //WELCOM PAGE
  const [imgIco, setImgIco] = useState();

  const openLinkURL = (id) => {
      props.selid (id)
      //console.log(id)
  }

  useEffect(() => {
      setImgIco(tmpimg)     
  }, []); // AUTOLOAD

  return (
      <div id = {"Decks_" + props.id} className={"user_thema "} onClick={props.status === "LOCKED" ? null : () => openLinkURL(props.id)} style={props.status === "LOCKED" ? {opacity: "0.5",backgroundColor: "#808080"} : { backgroundColor: props.colotb}}>
              <img className= "userThemaLogo" src={star_anki} alt="dcLogo"/>
              {/* <img className= "dcimg" src={deck} alt="logo "/> */}
              <div className="dclbl01">ID: {props.id}</div>
              {/* <div className="dclbl02">CARDS: {props.cards}</div> */}
              <div className="dclbl03" style={{ color: props.colort}} >{props.label}</div>
              <div className="dclbl04" style={{ color: props.colort}} >{props.title}</div>
              <div className="dclbl05" style={{ color: props.colort}} >{props.note}</div>
              {props.status === "LOCKED" ? <div className="dclbl06">LOCKED</div> : null}
          {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
      </div>
  );
}
