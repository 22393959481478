import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import QRCode from 'qrcode.react';
import { useNavigate, useParams} from "react-router-dom";  

 const QRscaner = (props) => {
  const [data, setData] = useState('No result');
  const [selected, setSelected] = useState("environment");
  const user_leng_ui = localStorage.getItem('user_leng_ui');
  const projectid = localStorage.getItem('projectid');
  const classid = localStorage.getItem('classid');
  const userid = localStorage.getItem('userid');
  const [school, setSchool] = useState('SCHOOL');
  const navigate = useNavigate();

  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = async (scanData) => {
    console.log(`loaded data data`, scanData);
    // if (scanData && scanData !== "" && !showDialog && !processing) {
    //   console.log(`loaded >>>`, scanData);
    //   // setPrecScan(scanData);
    // }
  };

  function OutME(event) {
    navigate(-1); // /invite
  }

  return (
    <div id = "ANKI_MODE" className="ank_main" >
            {/* <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div> */}
            <div className='appinfopanel'>
              <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
              <div className="tHello" >
                {user_leng_ui === "0" && "Join via QR"  }
                {user_leng_ui === "1" && "Únete vía QR"}
                {user_leng_ui === "2" && "Приєднатися через QR"}
              </div>
              </div>

              <div className='addcodetext'> 
                <select onChange={(e) => setSelected(e.target.value)}>
                  <option value={"environment"}>Back Camera</option>
                  <option value={"user"}>Front Camera</option>
                </select>
              </div>
        
              <div className="scanner-container">
                <div class="scanner"></div>
                <QrReader className='qrcamera'
                    key={selected}
                    constraints={{ facingMode: selected }}
                    onResult={(result, error) => {
                        if (!!result) {
                          setData(result?.text);
                        }
              
                        if (!!error) {
                          console.info(error);
                        }
                      }}
                    style={{ width: "200px", heigth: "100px" }}
                    delay={500}
                    // onError={handleError}
                    // onScan={handleScan}
                />
              </div>
        {/* <QrReader
            key={selected}
            facingMode={selected}
            delay={500}
            onError={handleError}
            onScan={handleScan}
        //   chooseDeviceId={()=>selected}
            style={{ width: "200px", heigth: "100px" }}
        /> */}

      {/* <p>{data}</p> */}

      {/* <QRCode className="qrLogo" value={"P" + projectid + "C" + classid + "U" + userid} size={360} fgColor={"#FFFFFF"} bgColor={"#000000"} level={"L"} includeMargin={true} imageSettings={{src:"/img/miniU.png", x: 238, y:220, height: 64, width: 45,excavate:true}}/>
      <QRCode className="qrLogo" value={"https://www.nebo.inupline.com/" + "P" + projectid + "C" + classid + "U" + userid} size={360} fgColor={"#FFFFFF"} bgColor={"#000000"} level={"L"} includeMargin={true} imageSettings={{src:"/img/miniU.png", x: 238, y:220, height: 64, width: 45,excavate:true}}/> */}
      <div className='addnotetext'> 
            {user_leng_ui === "0" && "To connect directly, scan the companion's code"}
            {user_leng_ui === "1" && "Para conectarse directamente, escanee el código del compañero"}
            {user_leng_ui === "2" && "Для прямого підключення проскунуйте код товариша"} 
      </div>

      <div className='addcodetext'> {data} </div>

    </div>
  );
};

export default QRscaner