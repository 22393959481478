import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams, useHistory } from "react-router-dom";  
//import './style_anki.css';
import logo from '../../img/logo_company.png';  //sadmin.png
import { useSelector } from 'react-redux';
import LoadingUI from '../../components/loading/loadingui';

import MDEditor, {
    commands,
    ICommand,
    EditorContext
  } from "@uiw/react-md-editor";

export default function AnkiRun(props) {
    const navigate = useNavigate();
    const [showSideB, setShowSideB] = useState(false);
    const user_leng_ui = localStorage.getItem('user_leng_ui');

    const [isPlaying, setIsPlaying] = useState(false);

    const [cards_all, setDeck] = useState(false);

    const [seconds, setSeconds] = useState(0);

    const class_all = localStorage.getItem('class_all');
    const [card_c1, setC_1] = useState(0);
    const [card_c2, setC_2] = useState(0);
    const [card_c3, setC_3] = useState(0);

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState("loading ...");
    
    const [nextsee, setNextSee] = useState("2024");


    //const login_token = useSelector((state)=> state.counter.user.login_token);

    const cmdNextCard = () => {
      const login_token = localStorage.getItem('login_token');

      fetch('https://www.nebo.inupline.com/api/web/anki/nextcard', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
      })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            if (!response.cards ) { 
              navigate('/'); 
            } else { 
              setDeck(response)
              setC_1(response.c_new)
              setC_2(response.c_rep)
              setC_3(response.c_pas)
              setLoading(false)

              //console.log("nextcard-" , response)
            } 
          } else {
              console.log("ERROR Deck-" , response)
              setLoading(false)
          }
      }); 
    }

    //const handleGetDeck = (id) => {
useEffect(() => {
    cmdNextCard()
  }, []); // AUTOLOAD

    //console.log("CARDS", cards_all.cards )
    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);

    const handleHome = (childValue) => {
        navigate(-1);
    };

    const handleNextCard = () => {
      setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards_all.cards.length);
      setShowSideB(false)
    };
  
    const handlePreviousCard = () => {
      setCurrentCardIndex((prevIndex) => (prevIndex - 1 + cards_all.cards.length) % cards_all.cards.length);
      setShowSideB(false)
    };

    function formatDateAndSeconds(seconds) {
      const date = new Date();
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      // Додати нулі до чисел, якщо вони менші за 10
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const handleSubmit = (lvl) => {
      setLoading(true)
      //e.preventDefault();
      console.log("lvl -" , lvl)
      console.log("seconds -" , formatDateAndSeconds(seconds))
      const login_token = localStorage.getItem('login_token');

      const userData = {
        card_id: cards_all.cards[0].id,
        time: formatDateAndSeconds(seconds),
        correct: lvl,
        answer: "",
      };

      console.log("userData", userData)

      fetch('https://www.nebo.inupline.com/api/web/anki/stats', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setShowSideB(false)
            cmdNextCard()
            console.log("APP->anki/stats", response)

            setNextSee(response.nextsee)

            //dispatch(application.setUpdateTime(response.update));
          }
      });
    };
    


    if (loading){
      return (<LoadingUI tcapt =  {"Loading " + "NextSeeMe " + nextsee}></LoadingUI>)
  } else {
      return (
      <>
      <div className='appinfopanel'>
          <div className='icon_back' onClick={handleHome}><i className="fa fa-arrow-left" aria-hidden="true"></i></div>
          <div className='refID'>{cards_all && cards_all.cards[0].card[0].a} </div>
          <div className='refB'>{card_c1}</div>
          <div className='refR'>{card_c2}</div>
          <div className='refG'>{card_c3}</div>
      </div>


      <div id = "ANKI_MODE" className="ank_main_run" >

            <div className="aClassRun">
               
                {/* <div dangerouslySetInnerHTML={{ __html: cards_all[currentCardIndex].card[0].b }} /> */}

                <div className="Side_A" > {<MDEditor.Markdown source={cards_all && cards_all.cards[0].card[0].b} />} </div>
                {showSideB ? 
                    <> 
                        <div className="Side_B" > {<MDEditor.Markdown source={cards_all && cards_all.cards[0].card[0].c} />} </div>  
                        {cards_all.cards[0].card[0].d &&  <AudioPlayer audioUrl={cards_all && cards_all.cards[0].card[0].d} playAudio={isPlaying}/>} 
                    </>
                : null}

                {/* <div className='ankRun' onClick={handlePreviousCard}><i className="fa fa-play" aria-hidden="true"></i> + </div>
                <div className='ankRun' onClick={handleNextCard}><i className="fa fa-play" aria-hidden="true"></i> - </div> */}

            </div>
        </div>

        <div className='BoxMainMenu'>
        {!showSideB ? <div className='btnRespuest'><div className='ankShowB' onClick={() => setShowSideB(!showSideB)}>
                    <i className="fa fa-play" aria-hidden="true"></i> 
                    {user_leng_ui === "0" && "Display the response"}
                    {user_leng_ui === "1" && "Mostrar la respuesta"}
                    {user_leng_ui === "2" && "Відобразити відповідь"} 
                </div></div> :
          <div className='btnRespuest'> 
              <div className='btnR1 cb_1' onClick={() => handleSubmit(0)}>
                    {user_leng_ui === "0" && "Again"}
                    {user_leng_ui === "1" && "Otra vez"}
                    {user_leng_ui === "2" && "ЗНОВУ"} 
                    </div>
                    <div className='btnR1 cb_2' onClick={() => handleSubmit(1)}>
                    {user_leng_ui === "0" && "Difficult"}
                    {user_leng_ui === "1" && "Difícil"}
                    {user_leng_ui === "2" && "ВАЖКО"} 
                    </div>
                    <div className='btnR1 cb_3' onClick={() => handleSubmit(2)}>
                    {user_leng_ui === "0" && "Okay"}
                    {user_leng_ui === "1" && "Bien"}
                    {user_leng_ui === "2" && "ДОБРЕ"} 
                    </div>
                    <div className='btnR1 cb_4' onClick={() => handleSubmit(3)}>
                    {user_leng_ui === "0" && "Easy"}
                    {user_leng_ui === "1" && "Fácil"}
                    {user_leng_ui === "2" && "ЛЕГКО"}</div>
          </div>} </div>
      </>
    );
    }
}


const AudioPlayer = ({ audioUrl, playAudio }) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const audioRef = React.createRef(null);
  
    const togglePlay = () => {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (isPlaying === false) {
            audioRef.current.pause();
          } else {
            audioRef.current.play();
          }
          setIsPlaying(!isPlaying);    
    }, [playAudio]); // AUTOLOAD

  
    return (
      <div className=''>
        {/* controls */}
        <audio id="player" ref={audioRef}  >   
            <source src={audioUrl} type="audio/mp3"/> 
        </audio>

        <div className='ankPlay' onClick={togglePlay}>{isPlaying ? <i className="fa fa-play" aria-hidden="true"></i> : <i className="fa fa-pause" aria-hidden="true"></i>}</div>

      </div>
    );
  };