import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';

function Index(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    //const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    //const login_token = localStorage.getItem('login_token');
    const usrname = localStorage.getItem('usrname');
    const user_leng_ui = localStorage.getItem('user_leng_ui');

        
    // useEffect(() => {
    //     const userData = {
    //       email: usremail
    //     };
    
    //     fetch('https://www.nebo.inupline.com/api/web/login', {
    //           method: 'POST',
    //           headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
    //           },
    //           body: JSON.stringify(userData),
    //       })
    //     .then(response => response.json())
    //     .then(response => {
    //         if (response.status===200) {
    //           console.log("INDEX ", response)
    //           setProjects(response.project) //joined
    //           setProjoined(response.joined)
    //           //setInSchool(response.joined)

    //           // console.log("project ", response.project)
    //           // console.log("joined ", response.joined)
    //         }
    // }); }, []); // AUTOLOAD http://localhost:3000/projects/new


  const OPEN_Main = () => {
    navigate(`/`);
  };

  const OPEN_Projects = () => {
    navigate(`/projects`);
  };

    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            {/* <div className='icon_back'><i className="fa fa-sign-out" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
            <div className='icon_back'><i className="fa fa-bell-o" aria-hidden="true"></i></div>
            <div className='icon_back'><i className="fa fa-user-o" aria-hidden="true"></i></div> */}

            {/* <div className="aHello" >Hola</div>
            <div className="aName" >{usrname}</div>
            <div className="aNick" >{usremail}</div> */}

            <div className='BoxRang'>
              <div className='InfoChoisClass'>
                {user_leng_ui === "0" && "MESSAGING module under development"}
                {user_leng_ui === "1" && "Módulo de MESSAGING en desarrollo"}
                {user_leng_ui === "2" && "Модуль ПЕРЕПИСКИ в розробці"}
              </div>
            </div>
                
            {/* <div className='BoxMainMenu'>
              <div className='Box1'><i className="fa fa-home" aria-hidden="true"  onClick={(e) => {OPEN_Main(e)}}></i></div>
              <div className='Box1 select'><i className="fa fa-book" aria-hidden="true" onClick={(e) => {OPEN_Projects(e)}}></i></div>
              <div className='Box1'><i className="fa fa-bar-chart" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-comments" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-user" aria-hidden="true"></i></div>
            </div> */}
        </div>

        </div>
    );
}

export default Index;

