import React, { useState } from 'react';

function Tabs(props) {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
      };

    const tabs = React.Children.toArray(props.children);


  return (
    <div>
        <div>
            <div className="tab-header">
                {tabs.map((tab, index) => (
                <div className={`tab ${activeTab === index ? 'active' : ''}`} key={index} onClick={() => handleTabClick(index)}>
                    {tab.props.label}
                </div>
                ))}
            </div>
            <div className="tab-content">
                {tabs[activeTab]}
            </div>
            </div>

        
    </div>
  );
}

export function Tab({ label, children }) {
    return <div label={label}>{children}</div>;
}

export const TabPage = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export const TabBtn = ({id,name,icon,selindex, onValueChange}) => {

    const handleTabClick = (tabNumber) => {
        onValueChange(tabNumber);
    };

    return (
    <div
        className={`tab ${selindex === 1 ? 'active' : ''}`}
        onClick={() => handleTabClick({id})}
    >
    {name}
    </div>
)
};    

export default Tabs;