import React , {  useState , useEffect} from 'react';
import { useNavigate} from "react-router-dom";  
import '../style_school.css';
import './UserSelect.css';
import {useApiData} from "../../functions/useApiData"
import SinLogo from '../../img/logo_company.png';  

import IconDELETE from '../../img/iconDEL.png';  
import IconEDIT from '../../img/iconEDIT.png';  
import IconOK from '../../img/iconOK.png';  

import IconSAVE from '../../img/iconSAVE.png';
import IconUPDATE from '../../img/iconUPDATE.png';
import IconADD from '../../img/iconADD.png';
import ImgF003 from '../../img/BFimg/F003.jpg';  //sadmin.png
import logo from '../../img/logo_company.png';  //sadmin.png

export function StatisticList(props) {
  //WELCOM PAGE

  const openLinkURL = (index) => {
      props.selid (index)
  }

  return (
      <li className="lisinmarker" >
          <figure className="lisistatic" onClick={() => openLinkURL(props.index)} >
              <span className="linenumero">{props.index}</span>
              <span className="type"><i className="fa fa-credit-card"></i></span>
              <span className="reference"> &nbsp;&nbsp;&nbsp;{props.json.cardref}</span>
              {props.json.answer == 0 && <span className="type cb_1"><i className="fa fa-circle" aria-hidden="true"></i>&nbsp;{props.json.answer}</span>}
              {props.json.answer == 1 && <span className="type cb_2"><i className="fa fa-circle" aria-hidden="true"></i>&nbsp;{props.json.answer}</span>}
              {props.json.answer == 2 && <span className="type cb_3"><i className="fa fa-circle" aria-hidden="true"></i>&nbsp;{props.json.answer}</span>}
              {props.json.answer == 3 && <span className="type cb_4"><i className="fa fa-circle" aria-hidden="true"></i>&nbsp;{props.json.answer}</span>}

              <span className="datatime"><i className="fa fa-clock-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.data}</span>
              <span className="datatime"><i className="fa fa-calendar-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.nexttime}</span>
          </figure>
      </li>
  );
}

function SchoolDecks() {
    const [level, setLevel] = useState(0);

    const activeStudentid = localStorage.getItem('studentid');
    const projectid = localStorage.getItem('projectid');

    const navigate = useNavigate();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // Obtiene solo la parte de la fecha en formato 'YYYY-MM-DD'

    const [statsUserList, setStatUSerList] = useState([]); // IMPORT Group / Course
    const [studentUserID, setStudentUserID] = useState([]); // IMPORT Group / Course

    const [dateSt, setDateSt] = useState(formattedDate);

    const activeProjectId = localStorage.getItem('projectid');

    const [themaList, setThemaList] = useState([]); // IMPORT Group / Course

    const [classGroup, setClassGroup] = useState([]);
    const [classGroupsSel, setClassGroupSel] = useState([]);
    const [classGroupsActive, setClassGroupActive] = useState([]);

    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + activeProjectId + '/logo.png');
    const [defaultFondo, setDefaultFondo] = useState("/img/projects/" + activeProjectId + '/fondo.png');

    const apiEndpoint = '/school/getGroups/' + activeProjectId;
    const { data: ClassDesktop, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpoint);

    const [selectedItem, setSelectedItem] = useState(1); // Estado para almacenar el elemento seleccionado

    const handleItemClick = (index) => {
      setSelectedItem(index); // Establece el elemento seleccionado
      console.log("setSelectedItem", index)
    };

    useEffect(() => { 
        if (ClassDesktop.groups) {
            setClassGroup(ClassDesktop.groups)
            //setCountClassGroup(ClassDesktop.count)

            console.log ("setClassGroup data", ClassDesktop)
        }
    }, [ClassDesktop]); // AUTOLOAD

    const login_token = localStorage.getItem('login_token');

    const [users, setUsers] = useState([])
    const [users2, setUsers2] = useState([{
			"id": "11",
			"deckid": "-1",
			"groupid": "5",
			"orden": "0",
			"title": "0...B2",
			"label": "B2: Intermedio alto",
			"note": "Tema 000",
			"ico": "-1",
			"colort": "#d3dbd5",
			"colotb": "#32785b",
			"created": "2024-02-05 19:39:37",
			"updated": "2024-03-07 16:33:04",
      'status': 'NEW'
		}]);
    const [isCursos, setCursos] = useState([
      {
        "id": "11",
        "deckid": "-1",
        "groupid": "5",
        "orden": "0",
        "title": "0...B2",
        "label": "B2: Intermedio alto",
        "note": "Tema 000",
        "ico": "-1",
        "colort": "#d3dbd5",
        "colotb": "#32785b",
        "created": "2024-02-05 19:39:37",
        "updated": "2024-03-07 16:33:04",
        'status': 'NEW',
        'name': 'User 01',
      },
      {
        "id": "14",
        "deckid": "-1",
        "groupid": "5",
        "orden": "0",
        "title": "0...B2",
        "label": "B2: Intermedio alto",
        "note": "Tema 001",
        "ico": "-1",
        "colort": "#d3dbd5",
        "colotb": "#32785b",
        "created": "2024-03-07 16:03:35",
        "updated": "2024-03-07 16:33:26",
        'status': 'NEW',
        'name': 'User 02',

      },
        // Añade más usuarios si es necesario
      ]);

      const [isLevelUser, setLevelUser] = useState([
        {
          "id": "0",
          "ico": null,
          "value": "0",
          "name": "DISABLED",
        },
        {
          "id": "1",
          "ico": null,
          "value": "0",
          "name": "BASIC",
        },
        {
          "id": "0",
          "ico": null,
          "value": "1",
          "name": "MIDDLE",
        },
        {
          "id": "2",
          "ico": null,
          "value": "2",
          "name": "ADVANCED",
        },{
          "id": "3",
          "ico": null,
          "value": "3",
          "name": "NATIVE",
        },
          // Añade más usuarios si es necesario
        ]);


    const addDay = () => {
      const newDate = new Date(dateSt);
      newDate.setDate(newDate.getDate() + 1);
      const formattedNewDate = newDate.toISOString().slice(0, 10);
      setDateSt(formattedNewDate);
    };
  
    const subtractDay = () => {
      const newDate = new Date(dateSt);
      newDate.setDate(newDate.getDate() - 1);
      const formattedNewDate = newDate.toISOString().slice(0, 10);
      setDateSt(formattedNewDate);
    };

    useEffect(() => {
      readinfo()
      GetThemaList()
      getUserID()

      //GetUserThemaList()
    }, [dateSt]); // AUTOLOAD

    useEffect(() => {
      handleDeleteDuplicates()
      // setTimeout(() => {
      //   handleDeleteDuplicates()
      // }, 1000);

    }, [users2]); // AUTOLOAD
    

    const handleDeleteDuplicates = () => {
      const filteredUsers = users.filter(user => {
        // Compara cada usuario de users con los usuarios de users2
        return !users2.some(user2 => user.id === user2.id);
        // Cambia la condición de comparación según tus necesidades
      });
      setUsers(filteredUsers);
    };

    const getUserID = () => {
      //e.preventDefault();

      const userData = {
        userid: `${activeStudentid}`,
      };

      fetch('https://www.nebo.inupline.com/api/web/login/getuser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setStudentUserID(response.perfil)
            console.log("getUserID", response.perfil )
            //dispatch(application.setUpdateTime(response.update));
          }
      });
    };

    
    const readinfo = () => {
      //e.preventDefault();

      const userData = {
        userid: `${activeStudentid}`,
        sortmode: "1",
        data: `${dateSt}`,
      };

      fetch('https://www.nebo.inupline.com/api/web/anki/statisticuser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setStatUSerList(response.list)
            console.log("statsUserList", response.list , activeStudentid)
            //dispatch(application.setUpdateTime(response.update));
          }
      });
    };

    const GetThemaList = () => {
      //fetch('https://www.nebo.inupline.com/api/web/anki/themas/' + projectid, {

      fetch('https://www.nebo.inupline.com/api/web/anki/themas/' + projectid, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setUsers(response.decks)
            console.log("themas", response , projectid)

          }
      });

      GetUserThemaList()

    };

    const GetUserThemaList = () => {

      fetch('https://www.nebo.inupline.com/api/web/anki/userthemas/' + activeStudentid, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setUsers2(response.decks)

            

            console.log("userthemas", response)
          }
      });
    };

    const saveThema = () => {
      //e.preventDefault();

      const userData = {
        userid: activeStudentid,
        themaid: users2,
      };

      console.log("POST users",  users2)

      fetch('https://www.nebo.inupline.com/api/web/anki/userthemas', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            //setStatUSerList(response.list)
            // console.log("POST userthemas", response , activeStudentid, users)
            //dispatch(application.setUpdateTime(response.update));
          }
      });

      //GetUserThemaList()

      const updatedUsers = users2.map(user => {
        // Crear una copia del usuario y modificar el valor de la propiedad status
        return { ...user, status: 'OLD' };
      });
      // Actualizar el estado con el nuevo array de usuarios actualizados
      setUsers2(updatedUsers);

      const updatedUsersClose = users.map(user => {
        // Crear una copia del usuario y modificar el valor de la propiedad status
        return { ...user, status: 'NEW' };
      });
      // Actualizar el estado con el nuevo array de usuarios actualizados
      setUsers(updatedUsersClose);


    };
    
      const moveUser = (user, isVisible) => {
        if (isVisible) {
          setUsers(users.filter(u => u !== user));
        } else {
          setUsers([...users, user]);
        }
      };

    // const handleHome = (childValue) => {
    //     navigate(-1);
    // };

    const handleGoHome = (childValue) => {
        navigate(-1);
    };

    const handleSubmit = (e) => {
      e.preventDefault();
    }

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };
    
      const handleUserSelect = (user) => {
        console.log('Usuario seleccionado:', user);
        // Realiza alguna acción con el usuario seleccionado, por ejemplo, enviarlo a un componente padre.
      };

    const moveItemThema = (item) => {
      if (users.includes(item)) {
        setUsers(users.filter(i => i !== item));
        setUsers2([...users2, item]);
      } else {
        setUsers2(users2.filter(i => i !== item));
        setUsers([...users, item]);
      }
  };

  const moveItemSlass = (item) => {
    // if (classGroup.includes(item)) {
    //   setClassGroup(classGroup.filter(i => i !== item));
    //   setClassGroupSel([...classGroupsSel, item]);
    // } else {
    //   setClassGroupSel(classGroupsSel.filter(i => i !== item));
    //   setClassGroup([...classGroup, item]);
    // }
    if (classGroup.includes(item)) { setClassGroupActive(item)}
    console.log("classGroupsActive", classGroupsActive)
};

const moveItemClase = (item) => {
  if (classGroup.includes(item)) {
    setClassGroup(classGroup.filter(i => i !== item));
    setClassGroupSel([...classGroupsSel, item]);
  } else {
    setClassGroupSel(classGroupsSel.filter(i => i !== item));
    setClassGroup([...classGroup, item]);
  }
  if (classGroup.includes(item)) { setClassGroupActive(item)}
  console.log("classGroupsActive", classGroupsActive)
};



    const handleChildValue = (childValue) => {
      console.log('handleChildValue:', childValue);
  };

  const handleDateStChange = (e) => {
    setDateSt(e.target.value);
};


    return (
        <div>
          <div className="project_header animate_body" style={{height:"160px"}}>
{/* style={{padding:`0px`, backgroundSize:`100%`,  background:`url(${!defaultFondo ? (ImgF003) : (defaultFondo) }) center no-repeat`}} */}
            <div className=''> 
                <div className="projectlogo" style={{top:"30px"}}>
                    {!studentUserID.user_picture ? ( <img className="plogo" src={logo} alt="logo" />):(
                    <div className="plogo" 
                        style={{width: "100px", height: "100px", backgroundImage:  `url(${studentUserID.user_picture})`, backgroundSize: "100px 100px", borderRadius: "50%"}} >
                    {/* <div className="plogo" 
                    style={{width: "100px", height: "100px", backgroundColor: "rgb(230, 230, 230)", WebkitMaskImage: `url(${selectedPhoto})`, maskImage: `url(${selectedPhoto})`, WebkitMaskRepeat: "no-repeat", maskRepeat: "no-repeat",WebkitMaskSize:"100%",maskSize:"100%"  }} >
                    </div> */}
                    </div>
                        )}
                    </div>

                <div className="label">
                    {"UserID: "+  activeStudentid}
              </div>

                <div className="name"  >
                    {studentUserID.user_name}
                </div>

                <div className="notes">
                    {studentUserID.user_mail} 
                </div>
            
                <div className="company" >
                {/* company */}
                </div>

                <div className="users"  >
                    {/* <i className="fa fa-users" aria-hidden="true"></i> 0   users               */}
                </div>

                
                {/* <div className="type"  >
                type           
                </div>

                <div className="loadlogo"  >
                    load img            
                </div>

                <div className="loadimg"  >
                    load logo             
                </div>

                <div className="thema"  >
                    load thema             
                </div> */}
                </div>
            </div>


                    {/* <div className="project_urls"  > */}
                        {/* <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div> */}

                        {/* <div className="cLabelClassHeaderBar"> Clases & Temas:</div> */}
                        {/* <div className="aButtonClassConfig" onClick={() => handleShowAddClass()}><i className="fa fa-plus" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}

                  <div className="tab-container">
                  <div className={`leftbaritem ${selectedItem === 0 ? 'active' : ''}`} onClick={() => handleGoHome()} style={{width:"120px"}}>
                      <div style={{ margin: "0px", padding:"5px", paddingLeft:"10px"}}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i> 
                        {/* <p className="leftbarboldtext">HOME</p> */}
                      </div>
                    </div>
                    <div className={`leftbaritem ${selectedItem === 1 ? 'active' : ''}`} onClick={() => handleItemClick(1)}>
                      <div style={{display:"inline-block", margin: "0px", padding:"0px 15px"}}>
                        {/* <i className="fa fa-folder-o" aria-hidden="true"></i>  */}
                        <p className="leftbarboldtext">Clases & Temas</p>
                      </div>
                    </div>
                    <div className={`leftbaritem ${selectedItem === 2 ? 'active' : ''}`} onClick={() => handleItemClick(2)} >
                      <div style={{display:"inline-block",margin: "0px", padding:"0px 15px"}}>
                        {/* <i className="fa fa-flag-checkered" aria-hidden="true"></i>  */}
                        <p className="leftbarboldtext">Estadística</p>
                      </div>
                    </div>
                    <div className={`leftbaritem ${selectedItem === 3 ? 'active' : ''}`} onClick={() => handleItemClick(3)}>
                      <div style={{display:"inline-block",margin: "0px", padding:"0px 15px"}}>
                        {/* <i className="fa fa-star" aria-hidden="true"></i>  */}
                        <p className="leftbarboldtext">CHAT</p>
                      </div>
                    </div>
                  </div>

                    {/* </div> */}

                    <div className="form_2">
                    {/* <form onSubmit={handleSubmit}>

                        <label className="desc" id="typeid_l" htmlFor="typeid">
                            Складність (en desarrollo)
                        </label>
                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={0} key={0}>DISABLED</option>
                                <option value={1} key={1}>BASIC</option>
                                <option value={2} key={2}>MIDDLE</option>
                                <option value={3} key={3}>ADVANCED</option>
                                <option value={4} key={4}>NATIVE</option>
                            </select>
                            <div className="select_arrow"></div>
                        </div>


                        <input className="button" type="submit" value="SAVE"/>
                    </form> */}
                    

                    {/* <div className="app">
                        <h1>Listas Paralelas</h1>
                        <div className="list-container">
                            <ListView users={users} onMoveUser={moveUser} />
                            <ListView users={[]} onMoveUser={moveUser} />
                        </div>
                    </div> */}


                    <div className="app">
                        {/* <div className ="label">Щоб перемістити клас або тему, натисніть двічі поспіль ліву клавішою мишки.</div> */}
                        
                        <div className="list-boxes">
                            <ListBoxClases title="CLASES" label= "доступні класи" allclase={classGroup} activeclase={classGroupsSel} onDoubleClick={moveItemClase} />
                            {/* <ListBoxClases title="ACTIVE" label= "доступні класи для студента" items={classGroupsSel} onDoubleClick={moveItemSlass} /> */}
                            <ListBoxGroup  title={classGroupsActive.cursname} label= {classGroupsActive.groupname} descriptions= {classGroupsActive.label} > 
                              <TagHeader tag="zona_header">
                              <label className="desc" id="typeid_l" htmlFor="typeid" style={{height:"45px", display :"inline-block", top: "-15px", position:"relative", alignItems: "center"}}>Складність тем:</label>
                                <div style={{"display": "inline-block", "margin": "3px" ,"top":"-5px", "position": "relative", "right":"0px"}}>
                                  <UserSelect index = "0" users={isLevelUser} descriptions="level" onSelect={handleUserSelect} />
                                </div>
                                <img className ="edit-btn" src={IconUPDATE} alt="update" onClick={() => handleDeleteDuplicates()}/>
                                <img className ="edit-btn" src={IconSAVE} alt="save" onClick={() => saveThema()}/>
                              </TagHeader>
                              <TagBody tag="zona_bodys">
                                <ListBox add = {true} title="Temas" label= "доступні теми" items={users} onDoubleClick={moveItemThema} />
                                <ListBox title="OPEN" label= "доступні теми для студента" items={users2} onDoubleClick={moveItemThema} />
                              </TagBody>
                            </ListBoxGroup>

                        </div>
                    </div>
                    </div>

                    {/* <UserSelect users={isCursos} descriptions="level" onSelect={handleUserSelect} /> */}


                    <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">Estadísticas de las tarjetas</div>
                        {/* <div className="aButtonClassImport"><i className="fa fa-plus" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassConfig"><i className="fa fa-question-circle" aria-hidden="true"></i></div> */}
                    </div>

                    <div className="form_2">
                      <div className="input_field inline"> 
                          <button onClick={subtractDay}>-</button>
                          <div className="input_field"> 
                            <input type="date" id="start_date" name="trip-start" value= {dateSt} min="2024-01-01" max="2030-12-31" onChange={handleDateStChange}/>
                          </div>
                          <button onClick={addDay}>+</button>
                      </div>

                      <ul className="grid_invite_user">
                        <li className="lisinmarker" >
                              <figure className="lisistatic" >
                                <span className="linenumero cb_0">N</span>
                                <span className="type cb_0 ">TYPE</span>
                                <span className="reference cb_0 ">REF</span>
                                <span className="type cb_0 ">BTN</span>
                                <span className="datatime cb_0">Left TIME </span>
                                <span className="datatime cb_0">Next TIME</span>
                            </figure>
                        </li>
                            {statsUserList && statsUserList.map((itemg, index) => ( 
                                <StatisticList 
                                    key = {index} 
                                    index = {index+1}
                                    json={itemg} 
                                    selid={handleChildValue} // Click Function
                                />
                            ))} 
                      </ul>
                    </div>
                </div>
              </div>
    );
}

export default SchoolDecks;


const UserSelect = ({ index, users, descriptions,  onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
  
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const handleSelect = (user) => {
      setSelectedUser(user);
      onSelect(user);
      toggleOpen();
    };

    useEffect(() => { 
      if (index) {
        setSelectedUser(users[0]);
        console.log ("setClassGroup data", users)
      }
  }, [index]); // AUTOLOAD
  
    return (
      <div className="user-select">
        <div className="selected-user" onClick={toggleOpen}>
          {selectedUser ? (
            <>
              <img src={selectedUser.picture ? selectedUser.picture : SinLogo} alt={selectedUser.name} />
              <span>{selectedUser.name}</span>
            </>
          ) : (
            <span> {descriptions}</span>
          )}
        </div>
        {isOpen && (
          <div className="options">
            {users.map((user, index) => (
              <div key={index} onClick={() => handleSelect(user)}>
                <img src={user.picture ? user.picture : SinLogo} alt={user.name} />
                <span>{user.name}</span>
              </div>
            ))}
          </div>
        )}
        <div className="select_arrow"></div>

      </div>
    );
  };
  
  const ListView = ({ users, onMoveUser }) => {
    return (
      <div className="list-view">
        {users.map((user, index) => (
          <UserItem key={index} user={user} onMoveUser={onMoveUser} />
        ))}
      </div>
    );
  };
  
  const UserItem = ({ user, onMoveUser }) => {
    const [isVisible, setIsVisible] = useState(true);
  
    const handleDoubleClick = () => {
      setIsVisible(!isVisible);
      onMoveUser(user, isVisible); // Notifica al componente padre sobre el cambio de visibilidad
    };
  
    return (
      <div className={`user-item ${isVisible ? 'visible' : 'hidden'}`} onDoubleClick={handleDoubleClick}>
        <img src={user.picture ? user.picture : SinLogo} alt={user.name} />
        <span>{user.name}</span>
      </div>
    );
  };

  const ListBox = ({ add, title, label, items, onDoubleClick }) => {
    return (
      <div className="list-box">
        <div className ="title">{title}</div>
        <div className ="label">{label}</div>
          {items.map((item, index) => (
            <div className ="cart_body" key={index} style={{backgroundColor: item.status === "OLD" ? "#ffe5b0" : "#d3ffba"}}>
              <img className ="icon" src={item.picture ? item.picture : SinLogo} alt={item.name} />
              <span className ="name">{item.note}</span>
              <span className ="label">{ item.label}</span>
                <div className="actions">
                    {add ? <img className ="edit-btn" style={{position: "absolute" ,right:"0px"}} src={IconADD} alt={item.name} onClick={() => onDoubleClick(item)}/> : 
                  <>
                    <img className ="edit-btn" src={IconEDIT} alt={item.name} />
                    <img className ="edit-btn" src={IconDELETE} alt={item.name} onClick={() => onDoubleClick(item)}/>
                  </>}
                </div>
            </div>
          ))}
      </div>
    );
  };

  const ListBoxClases = ({ children, title,label, allclase,activeclase, onDoubleClick }) => {

    const handleUserSelect = (user) => {
      console.log('CLASE SELECT:', user);
      onDoubleClick(user)
      // Realiza alguna acción con el usuario seleccionado, por ejemplo, enviarlo a un componente padre.
    };

    return (
      <div className="list-box">
        <div className ="title">{title}</div>
        <div className ="label">{label}</div>
        {/* <ul> */}
          {activeclase.map((item, index) => (
          //   <li key={index} onDoubleClick={() => onDoubleClick(item)} style={{backgroundColor: item.status === "OLD" ? "#fcb322" : "#94e864"}}>
          //     {item.cursname} / { item.groupname}
          //     <p>{item.cursnote} </p>
          //   </li>
          // ))}

          <div className ="cart_body" key={index}  >
              <img className ="icon" src={item.picture ? item.picture : SinLogo} alt={item.name} />
              <span className ="name">{item.cursname}</span>
              <span className ="group">{ item.groupname}</span>
              <span className ="label">{ item.label}</span>

                <div className="actions">
                  {/* <img className ="edit-btn" src={IconDELETE} alt={item.cursname} onClick={() => onDoubleClick(item)}/> */}
                  <img className ="edit-btn" style={{position: "absolute" ,right:"0px"}} src={IconDELETE} alt={item.name} onClick={() => onDoubleClick(item)}/>

                  {/* <img className ="edit-btn" src={IconDELETE} alt={item.groupname} onClick={() => onDoubleClick(item)}/> */}
                </div>
            </div>
            ))}
            
            {allclase.length === 0 ? null : <UserClases index = "0" users={allclase} descriptions="ADD CLAS" onSelect={handleUserSelect} /> }

        {/* </ul> */}

        <div>{children}</div>

      </div>
    );
  };

  const ListBoxGroup = (props) => {
    // Filtrar elementos con etiqueta personalizada "menu"
    const zona_header = React.Children.toArray(props.children).find(
      (child) => child.props.tag === 'zona_header'
    );

    const zona_bodys = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'zona_bodys'
    );

    // const logo_zona = React.Children.toArray(children).find(
    //     (child) => child.props.tag === 'logo_zona'
    // );

    // const ava_zona = React.Children.toArray(children).find(
    //     (child) => child.props.tag === 'ava_zona'
    // );

    return (
      <div className="list-group">
        <div className='header_body'>
          {zona_header}
          <img className ="icon" src={props.picture ? props.picture : SinLogo} alt={props.title} />
          <div className ="name">{props.title}</div>
          <div className ="label">{props.label}</div>
          <div className='descriptions'>{props.descriptions}</div>
        </div>
        {/* <div>{zona_header}</div> */}
        <div>{zona_bodys}</div>
      </div>
    );
  };

  export const TagHeader = ({ tag, children }) => {
    return <div tag={tag} className="list-group-header">{children}</div>;
  };

  export const TagBody = ({ tag, children }) => {
      return <div tag={tag} className="list-group-body">{children}</div>;
  };

  // export const TagLogo = ({ tag, children }) => {
  //     return <div tag={tag}>{children}</div>;
  // };

  // export const TagRMenu = ({ tag, children }) => {
  //     return <div tag={tag}>{children}</div>;
  // };



  const UserClases = ({ index, users, descriptions,  onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
  
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const handleSelect = (user) => {
      setSelectedUser(user);
      onSelect(user);
      toggleOpen();
    };

    useEffect(() => { 
      if (index) {
        setSelectedUser(users[0]);
        console.log ("setClassGroup data", users)
      }
  }, [index]); // AUTOLOAD style={{backgroundColor: item.status === "OLD" ? "#ffe5b0" : "#d3ffba"}}
  
    return (
      <div className="user-select" style={{width:"100%" }}>
        <div className ="cart_body" onClick={toggleOpen} style={{ backgroundColor:"#d3ffba" }}>
          <img className ="icon" src={SinLogo} alt={"add"} />
          <span className ="name">{"ADD CLASES"}</span>
          {/* <span className ="group">{ "+"}</span> */}
          <span className ="label">{ "Eliga nuevo clase para este usuario. Esta disponible: " + users.length + " clase(s)"}</span>

            {/* <div className="actions">
              <img className ="edit-btn" src={IconEDIT} alt={item.cursname} onClick={() => onDoubleClick(item)}/>
              <img className ="edit-btn" src={IconDELETE} alt={item.groupname} onClick={() => onDoubleClick(item)}/>
            </div> */}
        </div>

       
        {isOpen && (
          <div className="options">
            {users.map((user, index) => (
              <div key={index} onClick={() => handleSelect(user)}>
                <img src={user.picture ? user.picture : SinLogo} alt={user.cursname} />
                <span>{user.cursname} - {user.groupname}</span>
              </div>
            ))}
          </div>
        )}
        <div className="select_arrow"></div>

      </div>
    );
  };