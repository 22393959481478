import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';
import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import {DecksCard} from "../school"
import {useApiData} from "../../functions/useApiData"

function Index(props) {
  const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');
    
    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
    const [SchoolGroup, setSchoolGroup] = useState([]); // IMPORT Group / Course
    const [SchoolTitle, setSchoolTitle] = useState([]); // IMPORT Group / Course
    const [SchoolDecks, setDecks] = useState([]); // IMPORT CLASS
    const [selectedID, setSelectedID] = useState(null);
    const [SchoolDeck, setDeck] = useState([]); // IMPORT DECKS

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    //const login_token = localStorage.getItem('login_token');
    const usrname = localStorage.getItem('usrname');

    const apiEndpoint = '/school/getClass/' + 14;
    // if (selectedID) { 
      const apiEndpointClass = '/school/getDesktop/' + selectedID; 
      const { data: ClassDesktop, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);
    // }


    const login_token = useSelector((state)=> state.counter.user.login_token);

    //const { data, loading, error } = useApiData('GET', apiEndpoint);
    const { data: ClassData, loading: loading, error: ClassError } =  useApiData('GET', apiEndpoint);

    useEffect(() => { 

      //setSchoolGroup(ClassData.levels)
      if (ClassData.groups ) {
          setSchoolClass(ClassData.groups)
          //console.log ("getClass groups", ClassData)
          //console.log ("getClass groups re", ClassData.groups.title)
          //dispatch(application.setClassName(ClassData));
          //const jsonData = JSON.stringify(ClassData);
          const jsonData = JSON.stringify(ClassData);

          localStorage.setItem('class_all', jsonData)

          //console.log ("getClass groups", ClassData)

          // dispatch(application.setAnkiCards(ClassData));
      }

      if (ClassData.levels ) {
          setSchoolGroup(ClassData.levels)
      }
  }, [ClassData]); // AUTOLOAD

  useEffect(() => { 
      //console.log ("getDesktop data", ClassDesktop)
      if (ClassDesktop && ClassDesktop.length > 0) {
          //setClassData(ClassDesktop)
      }

      if (ClassDesktop.groups ) {
          setSchoolTitle(ClassDesktop.groups)
      }

      if (ClassDesktop.decks ) {
          setDecks(ClassDesktop.decks)
      }

      if (ClassDesktop.users ) {
          //setStudents(ClassDesktop.users)
      }

      if (ClassDesktop.teachers ) {
          //setTeachers(ClassDesktop.teachers)
      }

      handleGetDeck(11)

  }, [ClassDesktop,selectedID]); // AUTOLOAD

  const handleGetDeck = (id) => {
    localStorage.setItem('cards_all', "")

    fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + id, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
    })
    .then(response => response.json())
    .then(response => {
        if (response.status===200) {
            //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
            //setCards(response.cards)
            setDeck(response)
            // setLoading(false)

            //(application.setAnkiCards(response));
            const jsonData = JSON.stringify(response);

            localStorage.setItem('cards_all', jsonData)


            console.log("cards_all jsonData" , JSON.parse(jsonData))
            // console.log("/cards-" , response.cards[0])

            // setInfoText(response.message)
            // setInfodeck(true)

            // Створення масиву setPlayList
            const loadPlayList = response.cards.map(cardData => {
                const card = cardData.card[0];
            
                return {
                name: card.a,
                author: card.c,
                duration: card.e,
                url: card.d,
                };
            });

            //setPlayList(loadPlayList)

            //console.log("playList",loadPlayList)
        } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR Deck-" , response)
        //setLoading(false)
        }
    }); 
};

  const OPEN_Main = () => {
    navigate(`/`);
  };

  const OPEN_Projects = () => {
    navigate(`/projects`);
  };

  const handleChildValue = (childValue) => {
    setSelectedID(childValue);
    console.log(childValue)
  };

  const handleShowDecks = (childValue) => {
    //navigate(`/anki/${selectedID}`);
    navigate(`/anki/run`);

  };

    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            {/* <div className='icon_back'><i className="fa fa-sign-out" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
            <div className='icon_back'><i className="fa fa-bell-o" aria-hidden="true"></i></div>
            <div className='icon_back'><i className="fa fa-user-o" aria-hidden="true"></i></div>

            <div className="aHello" >Hola</div>
            <div className="aName" >{usrname}</div>
            <div className="aNick" >{usremail}</div> */}

            <div className='BoxRang'>
              <div className="classgroup" > 
                {SchoolClass && SchoolClass.map((itemg, index) => ( 
                    <GroupsCard 
                    key = {index} // OK
                    ids={itemg.id} // OK
                    title={itemg.title} // OK - G1 ! => GRUPGROUP.GRUPNAME
                    time = {itemg.time}  // OK ! TMLNTIMELINE.TMLNTIME + 
                    timeend = {itemg.timeend}  // OK ! TMLNTIMELINE.TMLNTIME + 
                    nameclass={itemg.nameclass} // OK ! => TMLNTIMELINE.TMLNCLASS
                    descrclass={itemg.descrclass} // OK ! => GRUPGROUP.GRUPABOUT
                    label = {itemg.label} // Label ? ! => GRUPGROUP.GRUPLABEL
                    level = {itemg.level} // Level ? ! => GRUPGROUP.GRUPLEVEL
                    icon={itemg.icoAva} // OK  ! => GRUPGROUP.GRUPICON
                    colort = {itemg.colort} // Text color ! => GRUPGROUP.GRUPCOLORT
                    colorb = {itemg.colorb} // Fon color ! => GRUPGROUP.GRUPCOLORB
                    fonfile = {itemg.fonfile} // Fon color ! => GRUPGROUP.GRUPCOLORB
                    selid={handleChildValue} // Click Function
                    />
                ))}
              </div>          
                
              {selectedID && <div className='classlist'> 
                <div className="classDeckslist" >
                  {SchoolDecks.map((itemg, index) => ( 
                      <DecksCard style={{ backgroundColor: itemg.colorb}} 
                      key = {index} 
                      id={itemg.id} 
                      deckid={itemg.deckid} 
                      title={itemg.title} 
                      label = {itemg.label}  
                      note = {itemg.note}  
                      cs={itemg.title} 
                      cards = {itemg.cards} 
                      ico={itemg.ico} 
                      colort={itemg.colort}
                      selid={handleShowDecks}
                      />
                  ))}
              </div> </div> }
            </div>
            <div className='BoxMainMenu'>
              <div className='Box1'><i className="fa fa-home" aria-hidden="true"  onClick={(e) => {OPEN_Main(e)}}></i></div>
              <div className='Box1 select'><i className="fa fa-book" aria-hidden="true" onClick={(e) => {OPEN_Projects(e)}}></i></div>
              <div className='Box1'><i className="fa fa-bar-chart" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-comments" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-user" aria-hidden="true"></i></div>
            </div>
        </div>

        </div>
    );
}


function CardProject(props) {
    const navigate = useNavigate();
  
    const handleOpenProject = (id) => {
      navigate("/projects" );
    }
  
    const handleloadurl = (id, dateupdate) => {
      const cacheBuster = new Date(dateupdate).getTime(); // Перетворіть в мілісекунди
      return `https://www.nebo.inupline.com/img/projects/${id}/fondo.jpeg?cache=${cacheBuster}`;
    }
    
    useEffect(() => {
        console.log("props.name", props.name)
    }, []); // AUTOLOAD
  
    return (
        <div className="index_card_project_list" 
        style={{backgroundImage: `url(${handleloadurl(props.obj.project_id,props.obj.update )})`, backgroundSize: 'cover' }}
        onClick={() => handleOpenProject(props.obj.project_id)}>
            <div className="transbox"></div>
            <div className="numID">{props.obj.project_id}</div>
            <div className="note">{props.obj.project_note }</div>
            <div className="title">{props.obj.project_name}</div>

            <NewIco id={props.obj.project_id} update={"items.update"}/>
        </div>
    );
  }

  function NewIco(props) {
    const [pid, setPid] = useState(props.id);
    //const randomCacheBuster = Math.floor(Math.random() * 10000);
    const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди
    const [defaultPhoto, setDefaultPhoto] = useState(true);
    const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + props.id + `/logo.png?cache=${cacheBuster}`);
  
    //setPid(id);
    return (
      <img className="prlogo" src={selectedPhoto} alt="Avatar" />
    )
  } 

export default Index;


export function GroupsCard(props) {
  //WELCOM PAGE
  const [imgIco, setImgIco] = useState();
  //const dispatch = useDispatch();

  const openLinkURL = (url) => {
      props.selid (props.ids)
  }

  const iconLink = () => {
      setImgIco(tmpimg)     
  }

  useEffect(() => {
      iconLink()
  }, []); // AUTOLOAD


  return (

      <div id = {"Card_" + props.ids} className={"card_group card-1"} onClick={openLinkURL} style={{ backgroundColor: props.colorb}}>
              {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
              {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
              {/* <img className= "lLogo" src={!props.imgs ? imgIco : "/img/" + props.imgs} alt="logo "/> */}
              <div className="gTitle" style={{ color: props.colort }}>{props.title}</div>
              <div className="gLabel" style={{ color: props.colort }}>{props.label}</div>
              <div className="gLevel" style={{ color: props.colort }}>{props.level}</div>
              <div className="gTime" style={{ color: props.colort }}>{props.time + "-" + props.timeend}</div>
              <div className="gNameclass"style={{ color: props.colort }}>{props.nameclass}</div>
          {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
      </div>
  );
}