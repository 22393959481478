import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>¿Estás seguro de que deseas eliminar este archivo?</h2>
            <div className="modal-buttons">
              <button onClick={onClose}>Cancelar</button>
              <button onClick={onConfirm}>Eliminar</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;