import React , { useRef, useState , useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';


function ImportCard(props) {
    const fileInputRef = useRef(null);
    //const user_id = useSelector((state)=> state.counter.user.user_id);
    //const login_token = useSelector((state)=> state.counter.user.login_token);

    

    const [file, setFile] = useState(null);

    const handleClose = (e) => {
        //cid = null;
        props.close (e)
    };

    const handleImportDeck = (e) => {
        e.preventDefault();

        const deck_id = localStorage.getItem('deckid');
        const user_id = localStorage.getItem('userid');
        const login_token = localStorage.getItem('login_token');
    
        const formData = new FormData();
        formData.append("fileInput", fileInputRef.current.files[0]);
        formData.append("DeckID", deck_id);
        formData.append("UserID", user_id);
        // formData.append("DeckName", deckName);
    
        // Luego, puedes realizar tu solicitud o enviar formData a través de tu lógica de manejo de API
        // Ejemplo con fetch:
        fetch("https://www.nebo.inupline.com/api/web/ws_upload_card", {
          method: "POST",
          headers: {
            'Authorization': 'Bearer ' + login_token,
            },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            // Manejar la respuesta de la API
            console.log("ws_upload_card OK", data);

            props.uploadid (props.projectid)
            props.apimsg({title: data.message, typeid: "success" })
          })
          .catch(error => {
            // Manejar errores
            console.error("ws_upload_card ERROR", error);
            props.uploadid (props.projectid)
            props.apimsg({title: error.message, typeid: "warning" })
          });
      };

    return (
        <div className={"info-panel info-panel--"}>
            <i className="icon fa fa-upload" aria-hidden="true"></i>
            <div className="info-panel__content">
                <label htmlFor="fileInput" className="btn btn-tertiary js-labelFile">
                        <input
                            type="file"
                            name="fileInput"
                            id="fileInput"
                            accept=".apkg"
                            ref={fileInputRef}
                            onChange={(event) => setFile(event.target.files[0])}
                            // onChange={handleFileInputChange}

                            style={{
                                width: "0.1px",
                                height: "0.1px",
                                opacity: "0",
                                border: "1px solid #ccc",
                                margin: "10px 0",
                              }} 
                        /> 
                        {file ? <b> {file.name} </b> : "Choose a file"}
                </label>
            </div>
            <button className="button--icon" onClick={handleImportDeck}>IMPORTAR</button>
            <button className="button--icon" onClick={() => handleClose(false)}>CANCEL</button>
        </div>
    );
}

export default ImportCard;