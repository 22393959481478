import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate, Link} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';
import {useApiData} from "../../functions/useApiData"

function Index(props) {
    const navigate = useNavigate();
    // const dispatch = useDispatch();


    const [finCard, setFinCard] = useState(false); // IMPORT USER
    const user_leng_ui = localStorage.getItem('user_leng_ui');

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const [card_c1, setC_1] = useState(0);
    const [card_c2, setC_2] = useState(0);
    const [card_c3, setC_3] = useState(0);

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    const login_token = localStorage.getItem('login_token');
    const usrname = localStorage.getItem('usrname');
    const [newmsg, setNewMsg] = useState(0); // IMPORT USER

    const deck_id = localStorage.getItem('deckid');

    // const apiEndpointClass = '/msg/newlist'; 
    // const { data: NewNotificaciones, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);

  //   useEffect(() => { 
  //     //console.log ("getDesktop data", ClassDesktop)
  //     if (NewNotificaciones.countmsg ) {
  //         // NewNotificaciones.notification
  //         setNewMsg(NewNotificaciones.countmsg)
  //         console.log("NewNotificaciones",NewNotificaciones)
  //     }
  // }, [NewNotificaciones]); // AUTOLOAD

  // useEffect(() => { 
  //   cmdNextCard()
  // }, []); // AUTOLOAD

    useEffect(() => {
      // Establece un intervalo para llamar a tu función cada 5 segundos
      cmdNextCard()
      cmdUpdeteMSG()
      const intervalo = setInterval(() => {
        cmdNextCard()
        cmdUpdeteMSG()
      }, 5000); // 5000 milisegundos = 5 segundos
  
      // Limpia el intervalo cuando el componente se desmonte o actualice
      return () => clearInterval(intervalo);
    }, []); // El segundo argumento del useEffect es un array vacío para que solo se ejecute una vez al montar el componente

      const cmdUpdeteMSG = () => {
      const login_token = localStorage.getItem('login_token');

      fetch('https://www.nebo.inupline.com/api/web/msg/newlist', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
      })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setNewMsg(response.countmsg)
            console.log("countmsg-" , response.countmsg)

          } 
      }); 
    }
    
    const cmdNextCard = () => {

      const login_token = localStorage.getItem('login_token');

      fetch('https://www.nebo.inupline.com/api/web/anki/nextcard', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
      })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            if (!response.cards ) { 
              setFinCard(true)
              console.log("setFinCard(true)")
            } else { 
              setFinCard(false)
            }
              setC_1(response.c_new)
              setC_2(response.c_rep)
              setC_3(response.c_pas)

              console.log("nextcard-" , response)
          } else {
              console.log("ERROR Deck-" , response)
          //setLoading(false)
          }
      }); 
    }

  const handleHome = (childValue) => {
      navigate(-1);
  };

  const handleShowDecks = (childValue) => {
    navigate(`/anki/run`);
  };

  const handleShowNotifications= (childValue) => {
    navigate(`/notifications`);
  };

  const handleShowUsersInClass= (childValue) => {
    navigate(`/usersinclass`);
  };

  const handleShowQRscaner= (childValue) => {
    navigate(`/qradd`);
  };

    return (
      <div id = "ANKI_MODE" className="ank_main" >
        {/* <div className='icon_back'><i className="fa fa-sign-out" aria-hidden="true" onClick={(e) => {OPEN_exit(e)}}></i></div> */}
        <div className='icon_back_main'>
          <i className="fa fa-bell-o" aria-hidden="true" onClick={(e) => {handleShowNotifications(e)}}></i>
          {newmsg > 0 && <span className="num-count" style={{top:"5px", right:"12px"}}>{newmsg}</span>}
        </div>
        <div className='icon_back_main'><i className="fa fa-user-o" aria-hidden="true" onClick={(e) => {handleShowUsersInClass(e)}}></i></div>
        <div className='icon_back_main'><i className="fa fa-qrcode" aria-hidden="true" onClick={(e) => {handleShowQRscaner(e)}}></i></div>

        <div className="studentinfo">
          <div className="aHello" >
            {user_leng_ui === "0" && "Hello"}
            {user_leng_ui === "1" && "Hola"}
            {user_leng_ui === "2" && "Привіт"}
          </div>
          <div className="aName" >{usrname}</div>

          <div className="aNick" >{usremail}</div>
        </div>
        
        <div className="aClassList">
          <div className='ThemaName'> ACTIVE </div>
          <div className='BoxRangBody'>
            <img className= "ankLogo" src={selectedPhoto} alt="logo "/>
            <div className="ankName" >SP0B2</div>
            <div className="ankPowerBy" >Іспанська з 0 до В2</div>
            <div className="ankThema" >thema 001</div>
            <div className='ankSellvl'>Lv. B2</div>
            <div className='ankProgres'>0%</div> 
          </div>

          <div className='ThemaName'> THEMA 001 </div>
            <div className='BoxRangNext'>
                <div className='Box1' style={{"borderBottom": "#0085FF solid 8px"}}>{card_c1}
                  <div className='Box1lbl'>
                    {user_leng_ui === "0" && "News"}
                    {user_leng_ui === "1" && "Nuevos"}
                    {user_leng_ui === "2" && "Нові"}
              </div>
                </div>
                <div className='Box1' style={{"borderBottom": "#C42400 solid 8px"}}>{card_c2}
                  <div className='Box1lbl'>
                    {user_leng_ui === "0" && "Repeat"}
                    {user_leng_ui === "1" && "Repetir"}
                    {user_leng_ui === "2" && "Повторити"} 
                  </div>
                </div>
                <div className='Box1' style={{"borderBottom": "#14AE5C solid 8px"}}>{card_c3}
                  <div className='Box1lbl'>
                    {user_leng_ui === "0" && "Training"}
                    {user_leng_ui === "1" && "Formación"}
                    {user_leng_ui === "2" && "Навчання"}
                  </div>
                </div>
            </div>

            <div className='BoxRun'>
              { !finCard ? <div className='ankRun' onClick={(e) => {handleShowDecks(e)}}><i className="fa fa-play" aria-hidden="true"></i> 
                {user_leng_ui === "0" && " Teach now"}
                {user_leng_ui === "1" && " Enseñar ahora"}
                {user_leng_ui === "2" && " ВЧИТИ ЗАРАЗ"}
              </div> 
              : <div className='InfoFinCardClass'>
                {user_leng_ui === "0" && "Congratulations! At this moment, you have finished working with this deck. If you wish to study not only according to the schedule, you can use additional learning."}
                {user_leng_ui === "1" && "¡Felicidades! En este momento, has completado el trabajo con esta baraja. Si deseas estudiar no solo según el horario, puedes utilizar aprendizaje adicional."}
                {user_leng_ui === "2" && "Вітаємо!В даний момент ви закінчили роботу з цією колодою. Якщо Ви бажаєте вивчати не лише за розкладом, то можете скористатись додатковим навчанням."}
              </div> } 

              <div className='ankRunChoice' onClick={(e) => {handleShowDecks(e)}}>...</div> 
            </div>
        </div>
      </div>
    );
}

  function NewIco(props) {
    const [pid, setPid] = useState(props.id);
    //const randomCacheBuster = Math.floor(Math.random() * 10000);
    const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди
    const [defaultPhoto, setDefaultPhoto] = useState(true);
    const activeProjectId = localStorage.getItem('projectid');


    const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + activeProjectId + `/logo.png?cache=${cacheBuster}`);
  
    //setPid(id);
    return (
      <img className="prlogo" src={selectedPhoto} alt="Avatar" />
    )
  } 

export default Index;


