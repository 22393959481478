import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import './style_school.css';
// import {Card} from "../components/cards/cards";
// import LoadingUI from '../components/loading/loadingui';
// import ImgF003 from './../img/BFimg/F003.jpg';  //sadmin.png avatar.jpeg
// import Avatar from './../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
// import tmpimg from './../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from './../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png

function DeckSteps(props) {
    return (
        <div className='wrapper_form'>
            <div className='steps' id='steps'>
                <div className='step'>
                    <div className='number completed'>
                    1
                    </div>
                    <div className='info'>
                    <p className='title'>
                        Creación del mazo
                    </p>
                    <p className='text'>Antes de continuar con la importación de cartas o su creación desde cero, pasa por este paso.</p>
                    </div>
                </div>

                <div className='step'>
                    <div className='number completed'>2</div>
                    <div className='info'>
                    <p className='title'>Importación de cartas</p>
                    <p className='text'>Después de recibir la confirmación de la creación del mazo, haz clic en importar o crea carta por carta.</p>
                    </div>
                </div>

                <div className='step'>
                    <div className='number completed'>3</div>
                    <div className='info'>
                    <p className='title'>Vista</p>
                    <p className='text'>Revisa el resultado, cómo se verá en el uso diario.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}


function DeckForm(props) {
    // Estados para almacenar los valores de los campos
    const [name, setName] = useState('0...B2');
    const [level, setLevel] = useState('B2: Intermedio alto');
    const [lngname, setLngname] = useState('Тема 000');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#32785b'); // Valor inicial del color en negro
    // const [alphaT, setAlphaT] = useState('0.1'); // Valor inicial del color en negro
    // const [alphaB, setlphaB] = useState('0.1'); // Valor inicial del color en negro
    const navigate = useNavigate();

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');
    const activeProjectId = localStorage.getItem('projectid');

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    const handleLNGNameChange = (e) => {
        setLngname(e.target.value);
    };

    const handleColorTChange = (e) => {
        setColorT(e.target.value);
    };

    const handleColorBChange = (e) => {
        setColorB(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor
        console.log('name:', name);
        console.log('level:', level);
        console.log('lngname:', lngname);
        console.log('colorT:', colorT); 
        console.log('colorB:', colorB);
        console.log('login_token:', login_token);

        const userData = {
            projectid: activeProjectId,
            name: name,
            level: level,
            lngname: lngname,
            colorT: colorT,
            colorB: colorB,
            ico: "-1",
        };

        fetch('https://www.nebo.inupline.com/api/web/school/postAddDeck', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddDeck", response)
            navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          }
      });

    };

      function rgbaToHex(rgbaColor) {
        const match = rgbaColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
      
        if (!match) {
        //   throw new Error('Invalid RGBA color format');
        }
      
        const [, r, g, b, a] = match;
      
        // Конвертувати значення кожного каналу в шістнадцятковий формат
        const hexR = (+r).toString(16).padStart(2, '0');
        const hexG = (+g).toString(16).padStart(2, '0');
        const hexB = (+b).toString(16).padStart(2, '0');
        const hexA = Math.round(+a * 255).toString(16).padStart(2, '0');
      
        // Об'єднати значення кольору та альфа-каналу
        // const hexColor = `#${hexR}${hexG}${hexB}${hexA}`;
        const hexColor = `#${hexR}${hexG}${hexB}`;

        return hexColor;
      }


    return (
        <div className="form_pages">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />
                
                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={""} key={0}>CEFR</option>
                                <option value={"A1: Principiante"} key={1}>A1: Principiante</option>
                                <option value={"A2: Elemental"} key={2}>A2: Elemental</option>
                                <option value={"B1: Intermedio bajo"} key={3}>B1: Intermedio bajo</option>
                                <option value={"B2: Intermedio alto"} key={4}>B2: Intermedio alto</option>
                                <option value={"C1: Avanzado"} key={5}>C1: Avanzado</option>
                                <option value={"C2: Maestría"} key={6}>C2: Maestría</option>
                            </select>
                            <div className="select_arrow"></div>
                          </div>

                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="title" name="title" type="text" placeholder="NAME" value={name} required onChange={handleNameChange}/>
                        </div>

                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={lngname} required onChange={handleLNGNameChange}/>
                        </div>

                        <div className="input_field" > 
                            Text color <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorT} required onChange={handleColorTChange}/>
                            {/* <input type="range" min="0" max="1" step="0.1"/> */}
                        </div>

                        <div className="input_field"> 
                            Back color <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div>

                        <input className="button" type="submit" value="CREAR"/>
                    </form>


                    <div style={{top: "0px", left: "500px", position: "absolute", width: "280px", color:"#b3b3b3", padding: "15px"}}>
                        PREVIEW
                        <div id = {"Card_" + props.ids} className={"card_deck"} style={{ backgroundColor: colorB, margin: "15px"}}>
                            <img className= "dcLogo" src={star_anki} alt="dcLogo"/>
                            <div className="dclbl01">DECK_ID: N</div>
                            <div className="dclbl02">CARDS: 0</div>
                            <div className="dclbl03" style={{ color: colorT }}>{level}</div>
                            <div className="dclbl04" style={{ color: colorT }}>{name}</div>
                            <div className="dclbl05" style={{ color: colorT }}>{lngname}</div>
                        </div>
                    </div>
              </div> 
    );
}

function SchoolDecks(props) {
    const [SchoolCards, setCards] = useState([]); // IMPORT DECKS

    const login_token = useSelector((state)=> state.counter.user.login_token);
    const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const navigate = useNavigate();

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };

      const handleUpload = (id) => {
        if (!file) {
          console.error("Файл не вибраний");
          return;
        }
    
        const formData = new FormData();
        formData.append('fileInput', file);
        formData.append('DeckID', cid);
    
        fetch('https://www.nebo.inupline.com/api/web/school/ImportDeck/' + id, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + login_token,
            },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            console.log('Відповідь з сервера:', data);
            // Додаткова обробка відповіді тут
          })
          .catch(error => {
            console.error('Помилка:', error);
            // Обробка помилок тут
          });
      };

    // console.log('/api/school/getDeck/' + cid)

        useEffect(() => {
            handleGetDeck(cid) }, [cid]); // AUTOLOAD


        const handleGetDeck = (id) => {
            fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + id, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
                },
            })
            .then(response => response.json())
            .then(response => {
                if (response.status===200) {
                    //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                    setCards(response.cards)
                    // setLoading(false)
                    console.log("/Deck-" , response)
                } else {
                    // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                    console.log("ERROR Deck-" , response)
                    setCards([])
                //setLoading(false)
                }
            }); 
        };

        const handleDelDeck = (id) => {
            fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + id, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
                },
            })
            .then(response => response.json())
            .then(response => {
                if (response.status===200) {
                    setCards(response.cards)
                    handleGetDeck(cid)                
                } else {
                    console.log("ERROR DELETE-" , response)
                }
            }); 
        };

        const handleHome = (childValue) => {
            //cid = null;
            navigate(-1);
        };

        const handleChildValue = (childValue) => {
            //cid = null;
        };
        

        return (
            <div>
                <div>
                    <div className="project_urls"  >
                        <div className="aButtonClassBack" onClick={handleHome}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                        <div className="cLabelClassHeaderBar">Creación del mazo</div>
                        {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <DeckSteps />
                    <DeckForm pid = {pid}/>

                </div>
        </div>
        );
}

export default SchoolDecks;

