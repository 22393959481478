import React , { useEffect,useState } from 'react';
import { useNavigate} from "react-router-dom";  

// import IcoHome from '../../img/home.png';  //sadmin.png

import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';

import './style.css';

import Tabs,{Tab, TabPage,TabBtn } from '../tabs/tabs';

function CardProject(props) {
  const navigate = useNavigate();
  //const randomCacheBuster = Math.floor(Math.random() * 10000);

  //const [defaultFondo, setDefaultFondo] = useState(`https://www.nebo.inupline.com/img/projects/` + 3 + `/fondo.png?cache=${randomCacheBuster}`);


  //WELCOM PAGE
  // console.log ("CardProject props.json",props.json)

  const handleOpenProject = (id) => {
    //console.log ("CardProject id", id)
    toggleClass1();
    navigate("/projects/" + id);
  }

  const handleloadurl = (id, dateupdate) => {
    //console.log ("CardProject id", id)
    const cacheBuster = new Date(dateupdate).getTime(); // Перетворіть в мілісекунди

    return `https://www.nebo.inupline.com/img/projects/${id}/fondo.jpeg?cache=${cacheBuster}`;
  }

  // useEffect(() => {
  //   //props.json.map((items, index) => (setPid(items.project_id) ));
  //   const img = new Image();
  //   img.src = 'https://www.nebo.inupline.com/img/projects/' + pid + '/logo.png';

  //   img.onload = () => {
  //     // La imagen existe, no necesitas hacer nada.
  //     setDefaultPhoto(false);
  //     setSelectedPhoto('https://www.nebo.inupline.com/img/projects/' + pid + '/logo.png');
  //     console.log("! existe")
  //   };
  //   img.onerror = () => {
  //     // La imagen no existe, cambia la URL por otra.
  //     setDefaultPhoto(true);
  //     console.log("no existe", img.src)
  //   };

    
  // }, [pid]);

  const toggleClass1 = () => {
    const oldClassName = document.getElementById('Menu02').className;
    const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
    document.getElementById('Menu02').className=newClassName;
  }

  return (
    <div>
      {props.json ? props.json.map((items, index) => ( 
        <div className="card_project" style={{backgroundImage: `url(${handleloadurl(items.project_id,items.update )})`, backgroundSize: 'cover' }} key = {index} onClick={() => handleOpenProject(items.project_id)}>
          <div className="transbox"></div>
          <div className="item1">{items.company_name}</div>
          <NewIco id={items.project_id} update={items.update}/>
          <div className="item3">{items.project_name}</div>  
          <div className="item4">{items.type_name}</div>
          {/* <div className="item5">Copy link</div> 
          <div className="item6">Invited people</div>
          <div className="item7">5 users</div> */}
          <div className="cButton">...</div>
        </div>
      )): null}
    </div>
  );
}

function NewIco(props) {
  const [pid, setPid] = useState(props.id);
  //const randomCacheBuster = Math.floor(Math.random() * 10000);

  const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди


  const [defaultPhoto, setDefaultPhoto] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + props.id + `/logo.png?cache=${cacheBuster}`);

  //setPid(id);
  return (
    <img className="item2" src={selectedPhoto} alt="Avatar" />
  )
} 

function RightMenu(props) {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usrpicture = localStorage.getItem('usrpicture');


    const [QRfondo, setQRfondo] = useState('#000000');
    const [QRcolor, setQRcolor] = useState('#FFFFFF');
    const [darkMode, setDarkMode] = useState(false);
    const [iconSize, setIconSize] = useState("180");

    const changeIcoSize = (e) =>{
        //setDarkMode(current => !current)
        console.log(e.target.id)
        if (e.target.id === "is-1") {
          document.documentElement.style.setProperty('--size_card','135px');
        } else if (e.target.id === "is-2") {    
          document.documentElement.style.setProperty('--size_card','180px');
        } else if (e.target.id === "is-3") {    
          document.documentElement.style.setProperty('--size_card','230px');
        }
      }
  
      // const openLink = (url) => {
      //   window.open(url, "")
      // } 

    const changeTheme = (e) =>{
        setDarkMode(current => !current)
        if (darkMode === true) {
          document.documentElement.style.setProperty('--menu_backcolor','RGB(255,255,255)');
          document.documentElement.style.setProperty('--body_backcolor','RGB(245,245,245)');
          document.documentElement.style.setProperty('--border_backcolor','RGB(225,225,225)');
          document.documentElement.style.setProperty('--text_color_menu','RGB(44, 62, 80)');
          document.documentElement.style.setProperty('--text_color_form','RGB(44, 62, 80)');
          document.documentElement.style.setProperty('--card_backcolor','RGB(255,255,255)');
          document.documentElement.style.setProperty('--card_text_color','RGB(215,130,52)');              
          document.documentElement.style.setProperty('--img_invert','0%');
  
          setQRfondo('#000000');
          setQRcolor('#FFFFFF');
  
        } else {
          document.documentElement.style.setProperty('--menu_backcolor','hsl(var(--brand-hue) 10% 20%)');
          document.documentElement.style.setProperty('--body_backcolor','hsl(var(--brand-hue) 10% 25%)');
          document.documentElement.style.setProperty('--border_backcolor','hsl(var(--brand-hue) 5% 30%)');
          document.documentElement.style.setProperty('--text_color_menu','hsl(var(--brand-hue) 30% 81%)');
          document.documentElement.style.setProperty('--text_color_form','hsl(var(--brand-hue) 90% 11%)');
          document.documentElement.style.setProperty('--card_backcolor','hsl(var(--brand-hue) 5% 80%)');
          document.documentElement.style.setProperty('--card_text_color','hsl(var(--brand-hue) 100% 10%)');              
          document.documentElement.style.setProperty('--img_invert','80%');
  
          setQRfondo('rgb(146, 159, 165)');
          setQRcolor('rgb(46, 53, 56)');
        }
  
        // function getProperty(elementId, property) {
        //   var obj = document.getElementById(elementId);
        //   return window.getComputedStyle(obj).getPropertyValue(property);
        // }
  
      }

      function OutME(event) {
        // setUser({});
        // googleLogout();
        dispatch(actUser.setUserEmail(""));
        dispatch(actUser.setUserPicture(""));
        dispatch(actUser.setFamilyName(""));
        dispatch(actUser.setGivenName(""));
        dispatch(actUser.setName(""));
        dispatch(actUser.setLoginToken(""));

        localStorage.setItem('usremail', "")
        localStorage.setItem('usrpicture', "")
        localStorage.setItem('usrfamily_name', "")
        localStorage.setItem('usrgiven_name', "")
        localStorage.setItem('usrname', "")
        localStorage.setItem('login_token', "")

        //console.log("OutME")
        // navigate("oauth2", {replace: true});
      }

      const handleSelTabIndex = (e) =>{
        console.log("handleSelTabIndex", e)
      }

    return (
        <div className="menu2hide" id="Menu02">
            <div className = 'UserMenuBox'>
              <Tabs> 
                <Tab label="My Projects">
                  <div className="GrLabelProject">
                      <label className="label_001">My Projects.</label>
                      <label className="label_002">+</label>
                  </div>

                  { props.json_project !== undefined && props.json_project.length === 0 ? <div className="card_project_new">
                      <div className="item0">Create your own project</div>
                      <div className="cButton"><i className="fas fa-bolt">...</i></div>
                    </div> : <CardProject json ={props.json_project} />              
                  }
                </Tab>
                <Tab label="Invited...">
                  <div className="GrLabelProject">
                        <label className="label_001">Invited to projects.</label>
                        <label className="label_002">Find</label>
                    </div>

                    {props.userprojoined !== undefined && props.userprojoined.length === 0 ? 
                    <div className="card_project" style={{height:"30px"}}>
                    <div className="item1">sin Invited</div>
                  </div>
                  : <CardProject json ={props.userprojoined} />               
                    }
                </Tab>
                <Tab label="Ajustes">
                  <div className="custom-check" onChange={(e) => changeTheme(e)}>
                      <input className="custom-check-input" id="someCheck" name="someCheck" type="checkbox" />
                      <label className="custom-check-elem" htmlFor="someCheck"></label>
                      <label className="custom-check-label" htmlFor="someCheck">DARK MODE</label>
                  </div>

                  <div className="radio-box">
                      <input type="radio" id="is-1" name="radio-01" onChange={(e) => changeIcoSize(e)}/>
                      <label htmlFor="is-1">1x</label>
                      
                      <input type="radio" id="is-2" name="radio-01" onChange={(e) => changeIcoSize(e)}/>
                      <label htmlFor="is-2">2x</label>
                      
                      <input type="radio" id="is-3" name="radio-01" onChange={(e) => changeIcoSize(e)}/>
                      <label htmlFor="is-3">3x</label>
                  </div>

                  <div className="login">
                    <div className="aButtonOpen" style={{display: "inline-block", width: "30%"}} onClick={(e) => {OutME(e)}}>LOGIN OUT</div>
                  </div>

                  <div className="smile">
                    <div className="face">
                      {/* <img className="smile_ava" src={usrpicture} alt="Avatar" /> */}
                        <div className="eye"></div>
                        <div className="eye right"></div>
                        <div className="mouth happy"></div>
                      </div>
                    <div className="shadow scale"></div>
                  </div>
                </Tab>
              </Tabs>

                {/* <div className='policy'> © 2023 - Restaurant Brands Iberia</div>
                <div className='prvt'> Privacidad</div> */}
            </div>
        </div>
    )
};


export default RightMenu;


