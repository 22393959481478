//import './mapstogo.css';
import React from "react";
import $ from 'jquery';
//import { myUsers, miUser, LogoCompany, LoadAvatar ,LoadAvatarHis} from "../../../redux/state";


export default class SWlistbox extends React.Component{
  constructor(props) {
    super(props);
      this.state = {
        DtUsers: [],
        DtHoras: [],
      };
      this.updateIds = this.updateIds.bind(this)
    }
    
  updatecaption(){
    //tcapt ('USER EDITOR');v
    //AppCaption('WEB EDITOR');
    //myUsers.callUser();
  }

  useropenid(){
    //console.log('work');
  }

  updateIds = (ids) => {
    //MW1info(ids); // show modal
  }

  
  GetDtUsers(){
    var data = { 
      "cmd": 'FindText',
      "colum": "work",
      "tbl": "users",
      'find': "miUser.work"//this.props.search, 
    };
    //console.log(data);
    fetch('https://inupline.com/post.php',
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    )
    .then (response => response.json())
      .then(response => {
        this.setState({
          DtUsers: response
        });
        console.log(response);
      });
  }


  GetDtHoras(){
    var data = { 
      "cmd": 'FindText',
      "colum": "id_empresa",
      "tbl": "db_horario",
      'find': "miUser.work"//this.props.search, 
    };
    //console.log(data);
    fetch('https://inupline.com/post.php',
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    )
    .then (response => response.json())
      .then(response => {
        this.setState({
        DtHoras: response
        });
        console.log(response);
      });
  }


  ShowDtHoras(idz) {
    const { DtHoras } = this.state;
    var drawweek = 0;

    for (var i = 0, len = DtHoras.length; i < len; i++) {
      if(DtHoras[i].id_user === idz){
        drawweek = drawweek + Number(DtHoras[i].c_horas)
      }
      console.log("id_user " , DtHoras[i].id_user);
    }
    console.log("tt " , idz);
    return drawweek
  }

  componentDidMount() {
    var $mainContent = document.querySelector('.content');
    var $listsWrap = document.querySelector('.scrollbale-content');
    var $lists = document.querySelector('.lists');
    var currentIndex = 0;

    var newIndex = 0;
    
    var switcher = function(_newIndex,bl,evt) {

    newIndex = _newIndex;
    
    var $list = document.getElementById(newIndex);
    
    $('.swipe').removeClass('animate animate2');

    if (typeof($list) != "undefined" &&  $list != null) { 
      if (bl === -1 ) { 
        $list.classList.add('animate2');
      }

        if (bl === 1 ) { 
          $list.classList.add('animate');
        }
        if (bl === 0 ) { 
          $('.swipe').removeClass('animate animate2');
        }
      }
    };

  $listsWrap.addEventListener('touchstart', handleTouchStart, true);
  $listsWrap.addEventListener('touchmove', handleTouchMove, true);

  $mainContent.addEventListener('mousedown', handleTouchStart, true);
  $mainContent.addEventListener('mousemove', handleTouchMove, true);
  $mainContent.addEventListener('mouseup', handleTouchEnd, true);

  var xDown = null;
  var yDown = null;

  function handleTouchStart(evt) {

    let index = evt.target.id

    if (!index) {
      $('.swipe').removeClass('animate');
  }

    if ('touchstart' === evt.type) {
      xDown = evt.touches[0].clientX;
      yDown = evt.touches[0].clientY;
    } else {
      xDown = evt.clientX;
      yDown = evt.clientY;
    }

  }

  function handleTouchEnd(evt) {
    xDown = null;
    yDown = null;
  }

  function handleTouchMove(evt) {
    var xUp ,yUp;
    let index = evt.target.id

    if ( ! xDown || ! yDown ) {
      return;
    }

    if ('touchmove' === evt.type) {
      xUp = evt.touches[0].clientX;
      yUp = evt.touches[0].clientY;
    } else {
      xUp = evt.clientX;
      yUp = evt.clientY;
    }

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      if ( xDiff > 0 ) {
        if ( currentIndex < 1 || (xDiff = 0) ) { currentIndex = currentIndex +1 } else { currentIndex = 1 }
      } else {
        if ( currentIndex > -1 || (xDiff > 1 )) { currentIndex = currentIndex -1} else { currentIndex = -1 }
      }
      switcher(index,currentIndex,evt);
    } 
    xDown = null;
    yDown = null;
}



  }

  render() {
    const { DtHoras, DtUsers } = this.state;
    let isd = 0
    return (
          <div className="content">
            <div className="scrollbale-content">
              <ul className="lists">
                {DtUsers.map((item, index) => (

                <li className="swipe" key={item.id} id={'swipe_'+index}>
                  <div className="toolfirm firma"><i className="fa fa-check-square-o" aria-hidden="true"></i></div>
                  <div className="box">

                    {/* {LoadAvatar(item, "list_partes_user", null ,'m')} */}

                    <div className="pusername">{item.fname} {item.lname}</div>
                    
                    <div className="pcompany">EMPRESA</div>
                    <div className="plugar">LUGAR DE TRABAJO</div>
                    <div className="pdate">01-05 ENERO 2025</div>
                    <div className="phoras">{this.ShowDtHoras(item.id)}</div>

                    <div className="dsem">
                      <div className="sem sd1">9</div>
                      <div className="sem sd2">9</div>
                      <div className="sem sd3">9</div>
                      <div className="sem sd4">9</div>
                      <div className="sem sd5">8</div>
                      <div className="sem sd6">0</div>
                      <div className="sem sd7">0</div>
                    </div>

                    <div className="nsem">
                      <div className="sen ">l</div>
                      <div className="sen ">m</div>
                      <div className="sen ">x</div>
                      <div className="sen ">j</div>
                      <div className="sen ">v</div>
                      <div className="sen ">s</div>
                      <div className="sen ">d</div>
                    </div>
                    


                  </div>
                  <div className="toolbox trash"><i className="fa fa-pencil" aria-hidden="true"></i></div>
                  <div className="toolbox view"><i className="fa fa-eye" aria-hidden="true"></i></div>
                  {/*<div className="toolbox edit"><i className="fa fa-pencil" aria-hidden="true"></i></div>*/}

                </li>
                ))}
              </ul>
            </div>
          </div>
        

    );
  }
}   

{/*
<li className="swipe" id="1">
                  <div className="toolfirm firma"><i className="fa fa-check-square-o" aria-hidden="true"></i></div>
                  <div className="box">
                    <h1>Title 2</h1>
                    <p>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars...</p>
                  </div>
                  <div className="toolbox trash"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                  <div className="toolbox view"><i className="fa fa-eye" aria-hidden="true"></i></div>
                  <div className="toolbox edit"><i className="fa fa-pencil" aria-hidden="true"></i></div>
                </li>

                <li className="swipe" id="2">
                  <div className="toolfirm firma"><i className="fa fa-check-square-o" aria-hidden="true"></i></div>
                  <div className="box">
                    <h1>Title 2</h1>
                    <p>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars...</p>
                  </div>
                  <div className="toolbox trash"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                  <div className="toolbox view"><i className="fa fa-eye" aria-hidden="true"></i></div>
                  <div className="toolbox edit"><i className="fa fa-pencil" aria-hidden="true"></i></div>
</li>*/}