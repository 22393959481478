import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams, useHistory } from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
// import {Card} from "../components/cards/cards";
import LoadingUI, {LoadingUI2} from '../../../components/loading/loadingui';
//import ImgF003 from '../../../../img/BFimg/F003.jpg';  //sadmin.png avatar.jpeg
//import Avatar from '../../../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
import tmpimg from '../../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from '../../../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png
// import { Document, Page, pdfjs  } from 'react-pdf';
// import { Document, Page } from '@react-pdf/renderer';
// import DocViewer from "react-doc-viewer";
import ImportCard from "./cards_import"
import {LevelCard_List,LevelCard_box} from "./LevelCard"
import ConfirmationModal from './ConfirmationModal';
import MDEditor, {  commands,  ICommand,  EditorContext } from "@uiw/react-md-editor";

import './audioplayer.css';
import './decks.css';
import './Sample.css';

function SchoolDecks(props) {
    const [editCards, setEditCards] = useState([]); // IMPORT DECKS
    const [SchoolCards, setCards] = useState([]); // IMPORT DECKS
    const [SchoolDeck, setDeck] = useState([]); // IMPORT DECKS
    const [fileList, setFileList] = useState([]); // IMPORT DECKS
    const [fileType, setFileType] = useState(); // FileType
    const [fileBase64, setFileBase64] = useState(null);
    const [selFile, setSelFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const [preguntas, setPreguntas] = useState([
      {
        pregunta: "El Primer Parque Disney se iba a construir en Burbank, California.",
        respuestaCorrecta: 0,
        respuestas: ["Verdadero", "Falso"],
        explicacion: "La idea original era crear un parque junto a los estudios Disney en Burbank, tras la montaña del mítico cartel de Hollywood. Pero las autoridades consideraron que aquel no era un lugar adecuado para todo el público que podría atraer un ambiente carnavalesco y de berbena.",
        puntos: 5
      },
      {
        pregunta: "¿Cuál es el primer espacio con el que se encuentra el visitante en el Parque de Anaheim?",
        respuestaCorrecta: 0,
        respuestas: ["Main Street", "Adventureland", "Frontierland", "Tomorrowland"],
        explicacion: "Todos los parques Disney cuentan con una calle principal para tiendas y restaurantes. El de Anaheim, Orlando y París se llama Main Street USA",
        puntos: 5
      },
      {
        pregunta: "¿En qué año se inauguró el primer parque?",
        respuestaCorrecta: 2,
        respuestas: ["1950", "1953", "1955", "1956", "1957"],
        explicacion: "El Parque fue inaugurado el 17 de Julio de 1955",
        puntos: 5
      }
    ]); 

    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(null);
    const [val_nextDeckID, setNextDeckID] = useState("0");
    const [val_minPoint, setMinPoint] = useState("0");

    const agregarNextDeckID = (index) => {
      setNextDeckID(index);
    };

    const agregarMinPoint = (index) => {
      setMinPoint(index);
    };

    const agregarPregunta = (nuevaPregunta) => {
      setPreguntas([...preguntas, nuevaPregunta]);
    };

    const actualizarPregunta = (preguntaActualizada) => {
      const nuevasPreguntas = [...preguntas];
      const index = nuevasPreguntas.findIndex(pregunta => pregunta === preguntaSeleccionada);
      if (index !== -1) {
        nuevasPreguntas[index] = preguntaActualizada;
        setPreguntas(nuevasPreguntas);
        setPreguntaSeleccionada(null);
      }
    };

    const seleccionarPregunta = (pregunta) => {
      setPreguntaSeleccionada(pregunta);
    };

    const eliminarPregunta = (index) => {
      const nuevasPreguntas = [...preguntas];
      nuevasPreguntas.splice(index, 1);
      setPreguntas(nuevasPreguntas);
    };
    
    const [editdeck, setEditdeck] = useState(false);
    const [importdeck, setImportdeck] = useState(false);
    const [adddeck, setAdddeck] = useState(false);
    const [deldeck, setDeldeck] = useState(false);
    const [infodeck, setInfodeck] = useState(false);

    const [filedeck, setFiledeck] = useState(false);
    const [examendeck, setExamendeck] = useState(false);

    const [msgInfoText, setInfoText] = useState({title: "test_title", typeid: 2 });
    const [playList, setPlayList] = useState([]); // IMPORT DECKS

    const deck_id = localStorage.getItem('deckid');
    const activeProjectId = localStorage.getItem('projectid');

    const login_token = useSelector((state)=> state.counter.user.login_token);
    
    const user_id = useSelector((state)=> state.counter.user.user_id);

    const [selectedItem, setSelectedItem] = useState(3); // Estado para almacenar el elemento seleccionado
    //activeProjectIdconst { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const navigate = useNavigate();
    // const history = useHistory();

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };

      const handleUpload = (id) => {
        if (!file) {
          console.error("Файл не вибраний");
          return;
        }
    
        const formData = new FormData();
        formData.append('fileInput', file);
        formData.append('DeckID', deck_id);
    
        fetch('https://www.nebo.inupline.com/api/web/school/ImportDeck/' + id, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + login_token,
            },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            console.log('Відповідь з сервера:', data);
            // Додаткова обробка відповіді тут
          })
          .catch(error => {
            console.error('Помилка:', error);
            // Обробка помилок тут
          });
      };

    // console.log('/api/school/getDeck/' + cid)

        useEffect(() => { 
          handleGetDeck(deck_id);
          handleGetFileDeck(deck_id); 

          GetExamen()
          //OpenFile(fileList[0]);
        }, []); // AUTOLOAD


        const handleGetDeck = (id) => {
            setCards([])
            
            fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + id, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
                },
            })
            .then(response => response.json())
            .then(response => {
                if (response.status===200) {
                    //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                    setCards(response.cards)
                    setDeck(response.deck[0])
                    // setLoading(false)
                    console.log("/cards-all" , response.cards)
                    // console.log("/deck-" , response.deck[0])
                    // console.log("/cards-" , response.cards[0])

                    // setInfoText(response.message)
                    // setInfodeck(true)

                    setInfodeck(false)
                    setImportdeck(false)
                    setEditdeck(false)
                    setDeldeck(false)
                    setAdddeck(false)

                    // Створення масиву setPlayList
                    const loadPlayList = response.cards.map(cardData => {
                        const card = cardData.card[0];
                    
                        return {
                        name: card.a,
                        author: card.c,
                        duration: card.e,
                        url: card.d,
                        };
                    });

                    setPlayList(loadPlayList)

                    console.log("playList",loadPlayList)
                } else {
                    // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                    console.log("ERROR Deck-" , response)
                    setCards([])
                    setInfoText(response.message)
                    setInfodeck(true)
                //setLoading(false)
                }
            }); 
        };

        const handleHome = (childValue) => {
            //cid = null;
            //navigate(`/projects/${pid}`);
            navigate(-1);
            
        };

        const handleChildValue = (childValue) => {
            // Виклик функції при необхідності
            // scrollToTop();
            
            setEditCards(childValue)
            setAdddeck(true)

            // const element = document.getElementById("body");
            // element.scroll(50, 10);
        };

        const handleCloseInfo = (val) => {
            setInfodeck(val)
        };

        const handleCloseDeleteBox = (val) => {
            setDeldeck(val)
        };

        const handleCloseEditCard = (val) => {
            setAdddeck(val)
        };


        const handleShowMSG = (val) => {
            setInfoText(val)
            setInfodeck(true)
            setImportdeck(false)
            setEditdeck(false)
            setDeldeck(false)
        };

        const handleCloseImportCard = (val) => {
            setImportdeck(val)
        };

        // Función para manejar el clic en un elemento de la barra lateral
  const handleItemClick = (index) => {
    setSelectedItem(index); // Establece el elemento seleccionado
  };

  const handleGetFileDeck = (id) => {
    setFileList([])
    fetch('https://www.nebo.inupline.com/api/web/files/list/' + id, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
    })
    .then(response => response.json())
    .then(response => {
        if (response.status===200) {
            //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
            setFileList(response.files)

            //setFileType(-2)
            // setLoading(false) 
            
            console.log("GET FILES",response.files)
        } else {
            console.log("GET FILES ERROR-" , response)
        }
    }); 
};

const OpenFile = (file) => {
  // Obtener la extensión del archivo
  setLoading(true)
  setFileBase64([])
  const fileExtension = file.name.split('.').pop();
  setFileType(fileExtension)
  setSelFile(`https://www.nebo.inupline.com/users/69/decks/${deck_id}/files/` + file.name); // Establece el elemento seleccionado


  console.log(`https://www.nebo.inupline.com/users/69/decks/${deck_id}/files/` + file.name)
  if (fileExtension != "pdf" & fileExtension != "md") { setLoading(false)}
  if (fileExtension == "pdf" || fileExtension == "md") { GetBase64File(file.name)}

  //console.log("https://www.nebo.inupline.com/users/69/decks/11/files/" + file.name)
  //console.log("file",file, fileExtension)
  // Crea una URL Blob a partir de la cadena base64
  // const blob = new Blob([fileBase64], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  // const url = URL.createObjectURL(blob);
  // setSelFile(url)
};

const GetBase64File = (filename) => {
  setFileBase64([])
  const userData = {
    userid: 69,
    deckid: deck_id,
    filename: filename,
};

  fetch('https://www.nebo.inupline.com/api/web/files/get' , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + login_token,
      },
      body: JSON.stringify(userData),
  })
    .then(response => response.json())
    .then(response => {
      setFileBase64(response.Base64)
      setLoading(false)
      //console.log('Відповідь з сервера:', response);
      // Додаткова обробка відповіді тут
    })
    .catch(error => {
      //console.error('Помилка:', error);
      // Обробка помилок тут
    });
};


const SetExamen = () => {
  const formData = new FormData();
  formData.append('deckid', deck_id);
  formData.append('array', JSON.stringify(preguntas) );
  formData.append('nextdeckid', val_nextDeckID);
  formData.append('minpoints', val_minPoint);

  console.log("deck_id", deck_id ,"val_nextDeckID" , val_nextDeckID ,"val_minPoint" , val_minPoint)
    
  fetch('https://www.nebo.inupline.com/api/web/school/setExamen/' + deck_id , {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + login_token,
      },
      body: formData ,
  })
    .then(response => response.json())
    .then(response => {
      //setFileBase64(response.Base64)
      //setLoading(false)
      console.log('Відповідь з сервера:', response);
      // Додаткова обробка відповіді тут
    })
    .catch(error => {
      console.error('Помилка:', error);
      // Обробка помилок тут
    });
};

const GetExamen = () => {
  fetch('https://www.nebo.inupline.com/api/web/school/getExamen/' + deck_id, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + login_token,
        },
        // body: JSON.stringify(userData),
    })
  .then(response => response.json())
  .then(response => {
      if (response.status===200) {
        const parsedExamen = JSON.parse(response.examen);
        if(parsedExamen) { 
          setPreguntas(parsedExamen);
          setNextDeckID(response.nextID);
          setMinPoint(response.minPoint);
        }
        console.log("getExamen ", response)
      }
  });
};


        return (
            <div>
              {infodeck? <InfoBox msg={msgInfoText} close={handleCloseInfo} />: null }

              <div className='h-container'>
                <div className="v-container">
                  <div>
                    <div className={`leftbaritem home`} onClick={() => handleHome()}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i> <p className="leftbarboldtext">BACK</p>
                      </div>
                    </div>
                    <PreviewImg mydeck = {SchoolDeck} onClick={() => handleItemClick(0)}/>
                    <div className={`leftbaritem ${selectedItem === 1 ? 'active' : ''}`} onClick={() => handleItemClick(1)}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-folder-o" aria-hidden="true"></i> <p className="leftbarboldtext">Files</p>
                      </div>
                    </div>
                    <div className={`leftbaritem ${selectedItem === 2 ? 'active' : ''}`} onClick={() => handleItemClick(2)}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-flag-checkered" aria-hidden="true"></i> <p className="leftbarboldtext">Examen</p>
                      </div>
                    </div>
                    <div className={`leftbaritem ${selectedItem === 3 ? 'active' : ''}`} onClick={() => handleItemClick(3)}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-star" aria-hidden="true"></i> <p className="leftbarboldtext">Cards</p>
                      </div>
                    </div>

                    <div className={`leftbaritem ${selectedItem === 4 ? 'active' : ''}`} onClick={() => handleItemClick(4)}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-file-audio-o" aria-hidden="true"></i> <p className="leftbarboldtext">Audio</p>
                      </div>
                    </div>

                    <div className={`leftbaritem ${selectedItem === 5 ? 'active' : ''}`} onClick={() => handleItemClick(5)}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-share-alt" aria-hidden="true"></i> <p className="leftbarboldtext">Shared</p>
                      </div>
                    </div>

                    <div className={`leftbaritem ${selectedItem === 6 ? 'active' : ''}`} onClick={() => handleItemClick(6)}>
                      <div style={{margin: "0px", padding:"0px 15px"}}>
                        <i className="fa fa-link" aria-hidden="true"></i> <p className="leftbarboldtext">URL</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>

                <div className='main-container'>

                    {selectedItem === 0 && 
                      <DeckForm mydeck = {SchoolDeck} pid = {activeProjectId} apimsg = {handleShowMSG} uploadid={handleGetDeck}/>
                    }

                    {selectedItem === 1 && <div>

                      { loading ?
                         <LoadingUI2 tcapt = "Loading"></LoadingUI2>
                      :
                        <div>
                          {fileType == null && <p style={{border: "2px dashed rgb(204, 204, 204)", borderRadius: "5px", padding: "20px",margin: "0px"}}>La carpeta está vacía</p> }
                          {fileType == -1 && <p>Fichero eliminado {selFile}</p> }
                          {fileType == -2 && <p>Abre un fichero ...</p> }
                          {/* <FileLoadList getFiles = {fileList} selFiles={OpenFile}/> */}
                          {/* <PDFview base64={fileBase64} /> */}

                          {/* <DocViewer documents={docs} /> */}

                          {fileType == "pdf" && <iframe title="PDF Viewer" src={`data:application/${fileType};base64,${fileBase64}`} width="100%" height="1080" sandbox="allow-same-origin allow-scripts"/>}
                          {fileType == "mp4" && <VideoPlayer url={selFile} />}
                          {fileType == "png" && <ImgView url={selFile} />}
                          {fileType == "md" && <MDEditor.Markdown source={atob(fileBase64)} style={{borderRadius:"10px", padding:"20px"}}/>}
                        </div>
                      }

                    </div>
                    }

                          {selectedItem === 2 && <div>

                          { loading ?
                            <LoadingUI2 tcapt = "Loading"></LoadingUI2>
                          :
                          <div>
                            <div className="project_urls" style={{ backgroundColor: (SchoolDeck && SchoolDeck.colotb ? SchoolDeck.colotb : "grey") }}>
                              {/* <div className="aButtonClassBack" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={handleHome}><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                              <div className="cLabelClassHeaderBar" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}}>Preguntas</div>
                              {/* <div className="aButtonExamenAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setExamendeck(!examendeck))}><i className="fa fa-flag-checkered" aria-hidden="true"></i></div> */}
                              {/* <div className="aButtonFileAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setFiledeck(!filedeck))}><i className="fa fa-files-o" aria-hidden="true"></i></div> */}
                              <div className="aButtonClassAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setAdddeck(!adddeck),setEditCards("new"))}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                              <div className="aButtonClassDelete" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => SetExamen()}><i className="fa fa-floppy-o" aria-hidden="true"></i></div>
                              <div className="aButtonClassImport" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} ><i className="fa fa-youtube-play" aria-hidden="true"></i></div>
                              <div className="aButtonClassConfig" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => GetExamen()}><i className="fa fa-refresh" aria-hidden="true"></i></div>
                            </div>

                            {/* <button className="button" onClick={() => SetExamen()}>SAVE {val_nextDeckID} - {val_minPoint}</button> */}
                            {/* <button className="button" onClick={() => GetExamen()}>LOAD</button> */}
                            
                            <PreguntaList preguntas={preguntas} seleccionarPregunta={seleccionarPregunta} eliminarPregunta={eliminarPregunta} />

                            <div className="project_urls" style={{ backgroundColor: (SchoolDeck && SchoolDeck.colotb ? SchoolDeck.colotb : "grey") }}>
                              {/* <div className="aButtonClassBack" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={handleHome}><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                              <div className="cLabelClassHeaderBar" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}}>Next Level</div>
                              {/* <div className="aButtonExamenAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setExamendeck(!examendeck))}><i className="fa fa-flag-checkered" aria-hidden="true"></i></div> */}
                              {/* <div className="aButtonFileAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setFiledeck(!filedeck))}><i className="fa fa-files-o" aria-hidden="true"></i></div> */}
                              {/* <div className="aButtonClassAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setAdddeck(!adddeck),setEditCards("new"))}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div> */}
                              {/* <div className="aButtonClassDelete" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => SetExamen()}><i className="fa fa-floppy-o" aria-hidden="true"></i></div> */}
                              {/* <div className="aButtonClassImport" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} ><i className="fa fa-youtube-play" aria-hidden="true"></i></div> */}
                              {/* <div className="aButtonClassConfig" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => GetExamen()}><i className="fa fa-refresh" aria-hidden="true"></i></div> */}
                            </div>

                            <NextLevel vnextid={val_nextDeckID}  vminpoints={val_minPoint} nextDeckID = {agregarNextDeckID} minPoint = {agregarMinPoint}/>

                          </div>
                          }

                          </div>
                          }


                    {selectedItem === 3 && <div>
                      <div className="project_urls" style={{ backgroundColor: (SchoolDeck && SchoolDeck.colotb ? SchoolDeck.colotb : "grey") }}>
                        <div className="aButtonClassBack" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={handleHome}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                        <div className="cLabelClassHeaderBar" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}}>{SchoolDeck.title + " (" + SchoolDeck.note + ")" + " " + SchoolDeck.label + "" } id: {SchoolDeck.id}</div>
                        {/* <div className="aButtonExamenAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setExamendeck(!examendeck))}><i className="fa fa-flag-checkered" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonFileAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setFiledeck(!filedeck))}><i className="fa fa-files-o" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassAdd" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => (setAdddeck(!adddeck),setEditCards("new"))}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => setDeldeck(!deldeck)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => setImportdeck(!importdeck)}><i className="fa fa-upload" aria-hidden="true"></i></div>
                        {/* <div className="aButtonClassConfig" style={{ color: (SchoolDeck && SchoolDeck.colort ? SchoolDeck.colort : "black")}} onClick={() => setEditdeck(!editdeck)}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                      </div>

                      {deldeck? <DelDeck msg={{title: "Que deseas elimina: ", typeid: "warning" }} projectid={deck_id} pid = {activeProjectId} uploadid={handleGetDeck} close={handleCloseDeleteBox}/>: null }
                      {importdeck? <ImportCard projectid={deck_id} uploadid={handleGetDeck} apimsg = {handleShowMSG} close={handleCloseImportCard}/>: null }

                      <LevelCard_List data = {SchoolCards} selid={handleChildValue}/></div>
                    }


                    {selectedItem === 4 && 
                      // <MusicPlayer list= {playList}/>
                      <AudioList list= {playList}/>
                    }

                    {selectedItem === 5 && 
                      // <MusicPlayer list= {playList}/>
                      <p style={{border: "2px dashed rgb(204, 204, 204)", borderRadius: "5px", padding: "20px"}}>Shared - EN DESARROLLO</p>
                    }

                    {selectedItem === 6 && 
                      // <MusicPlayer list= {playList}/>
                      <p style={{border: "2px dashed rgb(204, 204, 204)", borderRadius: "5px", padding: "20px"}}>URL - EN DESARROLLO</p>
                    }


                </div>

                <div className="divider"></div>
                <div className='right-container'>
                    {adddeck & selectedItem === 3 ? <AddCardForm cards = {SchoolCards} mydeck = {SchoolDeck} cardid = {editCards ? editCards : "new"} cid = {deck_id} pid = {activeProjectId} apimsg = {handleShowMSG} uploadid={handleGetDeck} close={handleCloseEditCard}/>: null }
                    
                    {selectedItem === 1 ? <div>
                        <FileUpload deckid={deck_id} update = {() => handleGetFileDeck(deck_id)}/>
                        <FileLoadList getFiles = {fileList} selFiles={OpenFile} update = {() => handleGetFileDeck(deck_id)}/>

                        {/* <VideoPlayer url={selfile} /> */}

                    </div>: null }
                    {selectedItem === 2 ? <div>
                      <PreguntaForm index={0} preguntaSeleccionada={preguntaSeleccionada} actualizarPregunta={actualizarPregunta} agregarPregunta={agregarPregunta} />
                      <TestList preguntas={preguntas}/>
                    </div>: null }
                    {/* <MusicPlayer list= {playList}/> */}
                  </div>
              </div>
        </div>
        );
}

function DelDeck(props) {
    //WELCOM PAGE
    // const user_id = useSelector((state)=> state.counter.user.user_id);

    const login_token = useSelector((state)=> state.counter.user.login_token);
    const navigate = useNavigate();


    const handleClose = (e) => {
        //cid = null;
        props.close (e)
    };

    const handleDelCards = (id) => {
        fetch('https://www.nebo.inupline.com/api/web/school/delCards/' + id, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
            },
        })
        .then(response => response.json())
        .then(response => {
            console.log("DELETE", response)
            if (response.status===200) {
                // handleGetDeck(cid)
                props.uploadid (id)                
            } else {
                console.log("ERROR DELETE-" , response)
            }
        }); 
    };

    const handleDelDeck = (id) => {
        fetch('https://www.nebo.inupline.com/api/web/school/delDeck/' + id, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
            },
        })
        .then(response => response.json())
        .then(response => {
            console.log("DELETE", response)
            if (response.status===200) {
                // handleGetDeck(cid)
                props.uploadid (id) 
                navigate(`/projects}`);               
            } else {
                console.log("ERROR DELETE-" , response)
            }
        }); 
    };

    return (
        <div className={"info-panel info-panel--" + props.msg.typeid}>
            <i className="icon fa fa-trash-o" aria-hidden="true"></i>
            <div className="info-panel__content">
                <p>{props.msg.title}</p>
            </div>
            <button className="button--icon" onClick={() => handleDelDeck(props.projectid)}> DELETE DECK & CARDS</button>
            <button className="button--icon" onClick={() => handleDelCards(props.projectid)} > CLEAR (delete only all cards)</button>
            <button className="button--icon" onClick={() => handleClose(false)}>CANCEL</button>
        </div>
    );
}


function InfoBox(props) {
    //WELCOM PAGE
    // const user_id = useSelector((state)=> state.counter.user.user_id);
    const handleClose = (e) => {
        //cid = null;
        props.close (e)
    };
    //TYPE BOXS
    //1 #0070D2; - norm
    //2 #C23934; - warning
    //3 #39c234; - success
    //4 #ff9113; - pending

    const MINUTE_MS = 3000;

    useEffect(() => {
    const interval = setInterval(() => {
        handleClose(false)
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])


    return (
        <div>
            <div className={"info-panel info-panel--" + props.msg.typeid}>
                <i className="icon fa fa-check-square-o" aria-hidden="true"></i>
                <div className="info-panel__content">
                    <p>{props.msg.title}</p>
                </div>
                <button className="button--icon" onClick={() => handleClose(false)}><i className="fa fa-times" aria-hidden="true"></i></button>
            </div>
        </div>
    );
}

// Component  DeckForm
function DeckForm(props) { 
    const [name, setName] = useState('A2+');
    const [level, setLevel] = useState('B2: Intermedio alto');
    const [lngname, setLngname] = useState('Тема 000');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#32785b'); // Valor inicial del color en negro
    const navigate = useNavigate();

    const login_token = useSelector((state)=> state.counter.user.login_token);


    useEffect(() => {
        setName(props.mydeck.title)     
        setLevel(props.mydeck.label)
        setLngname(props.mydeck.note)
        setColorT(props.mydeck.colort)
        setColorB(props.mydeck.colotb)
    }, [props]); // AUTOLOAD

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    const handleLNGNameChange = (e) => {
        setLngname(e.target.value);
    };

    const handleColorTChange = (e) => {
        setColorT(e.target.value);
    };

    const handleColorBChange = (e) => {
        setColorB(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor
        // console.log('name:', name);
        // console.log('level:', level);
        // console.log('lngname:', lngname);
        // console.log('colorT:', colorT); 
        // console.log('colorB:', colorB);
        // console.log('login_token:', login_token);

        const userData = {
            projectid: props.pid,
            name: name,
            level: level,
            lngname: lngname,
            colorT: colorT,
            colorB: colorB,
            ico: "-1",
        };

        fetch('https://www.nebo.inupline.com/api/web/school/putAddDeck/' + props.mydeck.id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->putAddDeck", response)
            props.uploadid (props.mydeck.id)
            props.apimsg({title: response.message, typeid: "success" })

          }
      });

    };

      function rgbaToHex(rgbaColor) {
        const match = rgbaColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
      
        if (!match) {
        //   throw new Error('Invalid RGBA color format');
        }
      
        const [, r, g, b, a] = match;
      
        // Конвертувати значення кожного каналу в шістнадцятковий формат
        const hexR = (+r).toString(16).padStart(2, '0');
        const hexG = (+g).toString(16).padStart(2, '0');
        const hexB = (+b).toString(16).padStart(2, '0');
        const hexA = Math.round(+a * 255).toString(16).padStart(2, '0');
      
        // Об'єднати значення кольору та альфа-каналу
        // const hexColor = `#${hexR}${hexG}${hexB}${hexA}`;
        const hexColor = `#${hexR}${hexG}${hexB}`;

        return hexColor;
      }


    return (
        <div className="form_0" style={{ boxShadow: "0px"}} >
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />

                        
                
                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={"CEFR"} key={0}>CEFR</option>
                                <option value={"A1: Principiante"} key={1}>A1: Principiante</option>
                                <option value={"A2: Elemental"} key={2}>A2: Elemental</option>
                                <option value={"B1: Intermedio bajo"} key={3}>B1: Intermedio bajo</option>
                                <option value={"B2: Intermedio alto"} key={4}>B2: Intermedio alto</option>
                                <option value={"C1: Avanzado"} key={5}>C1: Avanzado</option>
                                <option value={"C2: Maestría"} key={6}>C2: Maestría</option>
                            </select>
                            <div className="select_arrow"></div>
                          </div>

                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="title" name="title" type="text" placeholder="NAME" value={name} required onChange={handleNameChange}/>
                        </div>

                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={lngname} onChange={handleLNGNameChange}/>
                        </div>

                        <div className="input_field" > 
                            Text color <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorT} required onChange={handleColorTChange}/>
                            {/* <input type="range" min="0" max="1" step="0.1"/> */}
                        </div>

                        <div className="input_field"> 
                            Back color <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div>

                        <input className="button" type="submit" value="UPDATE"/>
                    </form>


                    <div style={{top: "300px", left: "0px", position: "absolute", width: "280px", color:"#b3b3b3", padding: "15px"}}>
                        PREVIEW
                        <div id = {"Card_" + props.ids} className={"card_deck"} style={{ backgroundColor: colorB, margin: "15px"}}>
                            <img className= "dcLogo" src={star_anki} alt="dcLogo"/>
                            <div className="dclbl01">DECK_ID: N</div>
                            <div className="dclbl02">CARDS: 0</div>
                            <div className="dclbl03" style={{ color: colorT }}>{level}</div>
                            <div className="dclbl04" style={{ color: colorT }}>{name}</div>
                            <div className="dclbl05" style={{ color: colorT }}>{lngname}</div>
                        </div>
                    </div>
              </div> 
    );
}


function PreviewImg(props) { 
  const [name, setName] = useState('A2+');
  const [level, setLevel] = useState('B2: Intermedio alto');
  const [lngname, setLngname] = useState('Тема 000');
  const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
  const [colorB, setColorB] = useState('#32785b'); // Valor inicial del color en negro
  const navigate = useNavigate();

  useEffect(() => {
      setName(props.mydeck.title)     
      setLevel(props.mydeck.label)
      setLngname(props.mydeck.note)
      setColorT(props.mydeck.colort)
      setColorB(props.mydeck.colotb)
  }, [props]); // AUTOLOAD

  return (
    <div id = {"Card_" + props.mydeck.id} className={"card_deck"} style={{boxShadow: "none", padding:"0", backgroundColor: colorB, margin: "5px", marginTop: "15px", marginBottom: "15px", width:"100%"}} onClick={props.onClick}>
        <img className= "dcLogo" src={star_anki} alt="dcLogo"/>
        <div className="dclbl01">DECK_ID: N</div>
        <div className="dclbl02">CARDS: 0</div>
        <div className="dclbl03" style={{ color: colorT }}>{level}</div>
        <div className="dclbl04" style={{ color: colorT }}>{name}</div>
        <div className="dclbl05" style={{ color: colorT }}>{lngname}</div>
    </div>
                  
  );
}

// Component  AddCardForm
function AddCardForm(props) {

    const fileInputRef = useRef(null);

    const [cardid, setCardId] = useState(null);
    const [cardRf, setCardrf] = useState(null);
    const [sideA, setSideA] = useState('<div><b>Хтось</b>приїхав/прийшов/прибув.&nbsp;<br></div>Є тут/вдома <b>хтось/хто-небуть</b>?<br><img src="SP0B2-020-06.png"><br>');
    const [sideB, setSideB] = useState('¿Hay alguien en la habitación? No hay nadie en la calle.');
    //const [img, setImg] = useState(null); // Valor inicial del color en negro
    const [imgFile, setImgFile] = useState(null);
    const [imgFileSideB, setImgFileSideB] = useState(null);

    const [imgUrl, setImgUrl] = useState(null);
    const [imgUrlsideB, setImgUrlsideB] = useState(null);

    const [cardNumber, setCardNumber] = useState(null);
    const [level, setLevel] = useState(0);


    const navigate = useNavigate();

    const [mp3File, setMP3File] = useState(null);

    const [audioFile, setAudioFile] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    const deck_id = localStorage.getItem('deckid');
    const user_id = localStorage.getItem('userid');
    const login_token = localStorage.getItem('login_token');

    // const login_token = useSelector((state)=> state.counter.user.login_token);
    // const user_id = useSelector((state)=> state.counter.user.user_id);

    const handleSubmitPost = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('img', imgFile);
        formData.append('imgB', imgFileSideB);
        formData.append('audio', mp3File);
        //formData.append('level', level); // En BBDD ANNOLVL

        // Використовуємо регулярний вираз для видалення всіх <img> тегів
        const newText = sideA.replace(/<img[^>]*>/g, '');

        let  newSideA = newText
        if (imgFile) {newSideA = newText + `<img src="${imgFile.name}">`};

        const newTextB = sideB.replace(/<img[^>]*>/g, '');
        let  newSideB = newTextB
        if (imgFileSideB) {newSideB = newTextB + `<img src="${imgFileSideB.name}">`};

        if (imgUrlsideB) {
            // Використовуємо об'єкт URL для аналізу URL
            const parsedUrlimgB = new URL(imgUrlsideB);
            const fileNameimgB = parsedUrlimgB.pathname.split('/').pop(); // Останній елемент шляху

            newSideB = newTextB + `<img src="${fileNameimgB}">`
        };

        var  userData = {}

        if (mp3File === null) {
            userData = {
                deckid: props.mydeck.id,
                referenc: cardRf,
                sideA: newSideA,
                sideB: newSideB,
                level: level,
                sound: ""
            };
        } else {
            userData = {
                deckid: props.mydeck.id,
                referenc: cardRf,
                sideA: newSideA,
                sideB: newSideB,
                level: level,
                sound: "[sound:" + mp3File.name + "]", 
            };
        }
        
        // Додайте інші дані в JSON форматі, якщо потрібно
        formData.append('json', JSON.stringify(userData));

        fetch('https://www.nebo.inupline.com/api/web/school/postAddCard', {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + login_token,
            },
            body: formData,
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->putAddDeck", response)
            props.uploadid (props.mydeck.id)
          }
      });
    };

    const handleSubmitPut = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('img', imgFile);
        formData.append('imgB', imgFileSideB);
        formData.append('audio', mp3File);
        //formData.append('level', level); // En BBDD ANNOLVL

        // Використовуємо регулярний вираз для видалення всіх <img> тегів
        const newText = sideA.replace(/<img[^>]*>/g, '');
        let  newSideA = newText

        if (imgFile) {newSideA = newText + `<img src="${imgFile.name}">`};

    
        if (imgUrl) {
            // Використовуємо об'єкт URL для аналізу URL
            const parsedUrlimg = new URL(imgUrl);
            const fileNameimg = parsedUrlimg.pathname.split('/').pop(); // Останній елемент шляху

            newSideA = newText + `<img src="${fileNameimg}">`
        };

        const newTextB = sideB.replace(/<img[^>]*>/g, '');
        let  newSideB = newTextB
        if (imgFileSideB) {newSideB = newTextB + `<img src="${imgFileSideB.name}">`};

        if (imgUrlsideB) {
            // Використовуємо об'єкт URL для аналізу URL
            const parsedUrlimgB = new URL(imgUrlsideB);
            const fileNameimgB = parsedUrlimgB.pathname.split('/').pop(); // Останній елемент шляху

            newSideB = newTextB + `<img src="${fileNameimgB}">`
        };

        let fileName = ""
        if (audioFile) {
            // Використовуємо об'єкт URL для аналізу URL
            const parsedUrl = new URL(audioFile);

            // Отримуємо ім'я файлу та розширення
            fileName = parsedUrl.pathname.split('/').pop(); // Останній елемент шляху
            //const [name, extension] = fileName.split('.');
        }

        var  userData = {}

        if (mp3File === null) {
            userData = {
                deckid: props.mydeck.id,
                referenc: cardRf,
                sideA: newSideA,
                sideB: newSideB,
                level: level,
                sound: fileName
            };
        } else {
            userData = {
                deckid: props.mydeck.id,
                referenc: cardRf,
                sideA: newSideA,
                sideB: newSideB,
                level: level,
                sound: "[sound:" + mp3File.name + "]", 
            };
        }

        console.log(cardid, userData)
        
        // Додайте інші дані в JSON форматі, якщо потрібно
        formData.append('json_data', JSON.stringify(userData));

        fetch('https://www.nebo.inupline.com/api/web/school/editAddCard/' + cardid, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + login_token,
            },
            body: formData,
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->putAddCard", response)
            props.uploadid (props.mydeck.id)
          } else {
            console.log("API->putAddCard", response)
          }
      });

      console.log("formData",formData)

    };

    const handleFileIMG = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            setImgFile(file);
            const temporaryURL = URL.createObjectURL(file);
            // Використовуємо регулярний вираз для видалення всіх <img> тегів
            const newText = sideA.replace(/<img[^>]*>/g, '');

            const newSideA = newText + `<img src="${temporaryURL}">`;
            // const newSideA = newText + `<img src="${file.name}">`;

            setImgUrl("https://www.nebo.inupline.com/users/" + user_id + "/decks/" + props.mydeck.id + "/" + file.name );
            setSideA(newSideA);
        }
    };

    const handleFileIMGsideB = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            setImgFileSideB(file);
            const temporaryURL = URL.createObjectURL(file);
            // Використовуємо регулярний вираз для видалення всіх <img> тегів
            const newTextB = sideB.replace(/<img[^>]*>/g, '');

            const newSideB = newTextB + `<img src="${temporaryURL}">`;
            // const newSideA = newText + `<img src="${file.name}">`;

            setImgUrlsideB("https://www.nebo.inupline.com/users/" + user_id + "/decks/" + props.mydeck.id + "/" + file.name );
            setSideB(newSideB);
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            setMP3File(file)
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const dataUrl = event.target.result;
            setAudioFile(dataUrl);
            setIsPlaying(false); // Забезпечити зупинку відтворення при зміні файлу
          };
    
          reader.readAsDataURL(file);
        }
    };
    
    const handlePlayPause = () => {
        if (audioFile) {
            const audio = audioRef.current;

            if (isPlaying) {
            audio.pause();
            } else {
            audio.play();
            }

            setIsPlaying(!isPlaying);
        }
    };

    const handleCardID = (e) => {
        // Логіка завантаження файлу на сервер
        setCardrf(e.target.value)
        console.log('File uploaded successfully!');
        //e.target.value
    };

    useEffect(() => {
        if (props.cards.length > 0) {
            setCardNumber(props.cards[0].card[0].a.slice(0, -2))
            setCardrf(props.cards[0].card[0].a.slice(0, -2) + "-" + (props.cards.length + 1))
        }
    }, [props.cards]); // AUTOLOAD

    useEffect(() => {
        if (props.cardid != "new" ) {
            setCardId(props.cardid)

            const foundObject = props.cards.find(item => item.id === props.cardid);
            setCardrf (foundObject.card[0].a);
            setSideA(foundObject.card[0].b);
            setSideB(foundObject.card[0].c);
            //const AUFL = (foundObject.card[0].d && "https://www.nebo.inupline.com/users/" + user_id + "/decks/" + props.mydeck.id + "/"  + foundObject.card[0].d)
            setAudioFile(foundObject.card[0].d);

            setLevel(foundObject.card[0].lv);
            console.log("audioFile", audioFile)
            // Регулярний вираз для витягування імені файлу з тегу img
            const regex = /<img[^>]*src="([^"]+)"/g;
            // Масив для зберігання імен файлів
            const imageFileNames = [];
            const imageFileNamesB = [];

            

            let match;
            while ((match = regex.exec(foundObject.card[0].b)) !== null) {
            // Додаємо ім'я файлу в масив
                imageFileNames.push(match[1]);
            }

            if ((imageFileNames.length === 0) ) {
                setImgUrl(null)
                setImgFile(null)
            } else {
                setImgUrl(imageFileNames)
                setImgFile(null)
            }

            let matchB;
            while ((matchB = regex.exec(foundObject.card[0].c)) !== null) {
            // Додаємо ім'я файлу в масив
            imageFileNamesB.push(matchB[1]);
            }
            
            if ((imageFileNamesB.length === 0) ) {
                setImgUrlsideB(null)
                setImgFileSideB(null)
            } else {
                setImgUrlsideB(imageFileNamesB)
                setImgFileSideB(null)
            }

            


        } else {
            setCardId(props.cardid)
        }
    }, [props.cardid]); // AUTOLOAD

    const handleDelCard = (e) => {
        e.preventDefault();
        
        fetch('https://www.nebo.inupline.com/api/web/school/delCard/' + cardid, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
            },
        })
        .then(response => response.json())
        .then(response => {
            console.log("DELETE", response)
            if (response.status===200) {
                // handleGetDeck(cid)
                props.uploadid (props.mydeck.id)  
                console.log("OK DELETE CARD-" , response)
              
            } else {
                console.log("ERROR DELETE-" , response)
            }
        }); 
    };

    const deleteIMGsideA = (e) => {
        e.preventDefault();
        setImgFile(null)
        setImgUrl(null)

        const newText = sideA.replace(/<img[^>]*>/g, '');
        setSideA(newText)
    }

    const deleteIMGsideB = (e) => {
        e.preventDefault();
        setImgFileSideB(null)
        setImgUrlsideB(null)

        const newTextB = sideB.replace(/<img[^>]*>/g, '');
        setSideB(newTextB)
    }

    const deleteMP3sideB = (e) => {
        e.preventDefault();
        setMP3File(null)
        setAudioFile(null);
        setIsPlaying(false); // Забезпечити зупинку відтворення при зміні файлу
    }

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    const GetFileName= (url) => {
         // Використовуємо об'єкт URL для аналізу URL
         if (url !== null) { 
            try {
                const parsedUrl = new URL(url);

                // Отримуємо ім'я файлу та розширення
                const fileName = parsedUrl.pathname.split('/').pop(); // Останній елемент шляху
                const [name, extension] = fileName.split('.');
                return fileName
            } catch (error) {
                return null; // або повернення пустого рядка або іншого значення за замовчуванням
            }
          }
          return null; // або повернення пустого рядка або іншого значення за замовчуванням
    }

    const handleClose = (e) => {
        //cid = null;
        props.close (e)
    };
    

    return (
        <div>
            <div className="form_container" >
                    <form>
                        <div className="input_field"> <span>Card ID <b>{cardid}</b> <i aria-hidden="true" className="fa fa-crosshairs"></i> Referenc Number  </span>
                            <input id="title" style={{ width: "80px"}} name="title" type="text" placeholder="Referenc Number" value={cardRf} required onChange={handleCardID}/>
                        </div>

                        <div className='editSideA'>
                        <div className="LabelID" style={{ color: props.mydeck.colotb}}>Side A</div>
                        <MDEditor className='editorMDsider'
                            value={sideA}
                            preview="edit"
                            height="160px"
                            visibleDragbar={false}
                            extraCommands={[]}
                            commands={[
                                commands.bold, 
                                commands.italic, 
                                commands.strikethrough , 
                                commands.divider, // X
                                commands.hr,
                                commands.title,
                                commands.divider,
                                // commands.link,
                                commands.quote,
                                commands.code,
                                commands.codeBlock,
                                commands.comment,
                                // commands.image,
                                commands.divider,
                                commands.unorderedListCommand,
                                commands.orderedListCommand,
                                commands.checkedListCommand
                            ]}
                            // components={{
                            //     toolbar: (command, disabled, executeCommand) => {
                            //       if (command.keyCommand === 'code') {
                            //         return (
                            //           <button 
                            //             aria-label="Insert code"
                            //             disabled={disabled}
                            //             onClick={(evn) => {
                            //               evn.stopPropagation();
                            //               executeCommand(command, command.groupName)
                            //             }}
                            //           >
                            //             <i className="fa fa-crosshairs" aria-hidden="true"></i>

                            //           </button>
                            //         )
                            //       }
                            //     }}}
                            onChange={(val) => {
                            setSideA(val);
                            }}
                        /> 
                    <div className='editSideC' style={{ padding: "0px"}}>
                        <label htmlFor="fileInput" className="btn btn-tertiary js-labelFile">
                            <input
                                type="file"
                                name="fileInput"
                                id="fileInput"
                                accept=".png, .jpeg, .gif"
                                
                                // onChange={(event) => setImgFile(event.target.files[0])}
                                onChange={handleFileIMG}

                                style={{
                                    width: "0.1px",
                                    height: "0.1px",
                                    opacity: "0",
                                    border: "1px solid #ccc",
                                    margin: "10px 0",
                                }} 
                            /> 
                            {imgFile ? <b> {imgFile.name} </b> : imgUrl ? "Change IMG" : "Choose IMG"}

                        </label>

                        {imgUrl ? <label className="btn btn-tertiary js-labelFile" style={{backgroundColor:"red"}} onClick={deleteIMGsideA}>
                            {/* {imgUrl && "Del " + GetFileName(imgUrl) } */}
                            {imgUrl && <i className="fa fa-trash" aria-hidden="true"></i>}
                        </label> : null}
                        </div>
                    </div>
                        <div className='editSideB'>
                            <div className="LabelID" style={{ color: props.mydeck.colotb}}>Side B</div>

                            <MDEditor className='editorMDsider'
                                value={sideB}
                                preview="edit"
                                height="160px"
                                visibleDragbar={false}
                                extraCommands={[]}
                                commands={[
                                    commands.bold, 
                                    commands.italic, 
                                    commands.strikethrough , 
                                    commands.divider, // X
                                    commands.hr,
                                    commands.title,
                                    commands.divider,
                                    // commands.link,
                                    commands.quote,
                                    commands.code,
                                    commands.codeBlock,
                                    commands.comment,
                                    // commands.image,
                                    commands.divider,
                                    commands.unorderedListCommand,
                                    commands.orderedListCommand,
                                    commands.checkedListCommand
                                ]}
                                onChange={(val) => {
                                setSideB(val);
                                }}
                            /> 
                        
                    <div className='editSideC' style={{ padding: "0px"}}>
                        <label htmlFor="fileInputB" className="btn btn-tertiary js-labelFile">
                            <input
                                type="file"
                                name="fileInputB"
                                id="fileInputB"
                                accept=".png, .jpeg, .gif"
                                onChange={handleFileIMGsideB}
                                style={{
                                    width: "0.1px",
                                    height: "0.1px",
                                    opacity: "0",
                                    border: "1px solid #ccc",
                                    margin: "10px 0",
                                }} 
                            /> 
                            {imgFileSideB ? <b> {imgFileSideB.name} </b> : imgUrlsideB ? "Change IMG " : "Choose IMG"}

                        </label>

                        {imgUrlsideB ? <label className="btn btn-tertiary js-labelFile" style={{backgroundColor:"red"}} onClick={deleteIMGsideB}>
                            {/* {imgUrlsideB && "Del " + GetFileName(imgUrlsideB) } */}
                            {imgUrlsideB && <i className="fa fa-trash" aria-hidden="true"></i>}

                        </label> : null}
                        </div>
                    </div>

                    <div className='editSideC' style={{ paddingLeft: "15px"}}>
                    <div className="LabelID" style={{ color: props.mydeck.colotb}}>MP3</div>

                    <label htmlFor="audiourl" className="btn btn-tertiary js-labelFile">
                        <input
                            type="file"
                            name="audiourl"
                            id="audiourl"
                            accept="audio/mp3"
                            onChange={handleFileChange}
                            style={{
                                width: "0.1px",
                                height: "0.1px",
                                opacity: "0",
                                border: "1px solid #ccc",
                                margin: "10px 0",
                              }} 
                        /> 
                        {mp3File ?  mp3File.name : audioFile ?  GetFileName(audioFile) : "Choose a MP3"  }
                    </label>

                    {audioFile ? <label className="btn btn-tertiary js-labelFile" style={{backgroundColor:"red"}} onClick={deleteMP3sideB}>
                        {/* {audioFile && "Del " + GetFileName(audioFile) } */}
                        {audioFile && <i className="fa fa-trash" aria-hidden="true"></i>}

                    </label> : null}
                    </div>
            </form>
                    

                    <div style={{top: "580px", left: "0px", position: "absolute", width: "100%", color:"#b3b3b3", padding: "15px"}}>
                        PREVIEW
                        <div className ="card_list" style={{ opacity: 1, backgroundColor: "#fff", borderLeft: props.mydeck.colotb + " solid  5px"}}>
                            {/* <img className= "lLogo" src={!props.imgs ? imgIco : "/img/" + props.imgs} alt="logo "/> */}
                            <div className="LabelID" style={{ color: props.mydeck.colotb}}>{cardRf}</div>
                            <div className="LabelNumber" style={{ color: props.mydeck.colotb}}>{props.cards.length + 1}</div>
                            {/* <div className="lNote">{props.card[0].b}</div> */}
                            
                            {/* <div className="SideA" dangerouslySetInnerHTML={{ __html: sideA }}></div> */}
                            
                            {/* <div className="SideA" > {cleartext(props.card[0].b)} </div> */}
                            <div className="SideA">{<MDEditor.Markdown source={sideA} style={{ backgroundColor: "rgba(0,0,0,0)"}} />}</div>
                            <div className="SideB">{<MDEditor.Markdown source={sideB} />}</div>
                            
                            <div className='BTNplay'>
                                {audioFile && (
                                    <div>
                                        <audio ref={audioRef} src={audioFile} />
                                    </div>
                                )}

                                {audioFile && <button onClick={handlePlayPause}>{isPlaying ? <i className="fa fa-pause" aria-hidden="true"></i> : <i className="fa fa-play" aria-hidden="true"></i>}</button>}

                            </div>
                            {level == 0 && <div className="task__levelCard task__levelCard--0" > DISABLED </div> }
                            {level == 1 && <div className="task__levelCard task__levelCard--1" > Lv. BASIC </div> }
                            {level == 2 && <div className="task__levelCard task__levelCard--2" > Lv. MIDDLE </div> }
                            {level == 3 && <div className="task__levelCard task__levelCard--3" > Lv. ADVANCED </div> }
                            {level == 4 && <div className="task__levelCard task__levelCard--4" > Lv. NATIVE </div> }
                        </div>

                        <br></br>
                        <label className="desc" id="typeid_l" htmlFor="typeid">
                            Складність
                        </label>
                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={0} key={0}>DISABLED</option>
                                <option value={1} key={1}>BASIC</option>
                                <option value={2} key={2}>MIDDLE</option>
                                <option value={3} key={3}>ADVANCED</option>
                                <option value={4} key={4}>NATIVE</option>
                            </select>
                            <div className="select_arrow"></div>
                          </div>
                        <br></br>
                        {cardid==="new" ? <button className="btn btn-tertiary js-labelFile" onClick={handleSubmitPost}> ADD</button> :<button className="btn btn-tertiary js-labelFile" onClick={handleSubmitPut}> UPDATE</button> }
                        {cardid==="new" ? null : <button className="btn btn-tertiary js-labelFile" onClick={handleDelCard}> DELETE</button> }
                        <button className="btn btn-tertiary js-labelFile" onClick={() => handleClose(false)}> CANCEL </button>
                        </div>
                    </div>
              </div> 
    );

    
}

const MusicPlayer = ({list}) => {
    const [songs, setSongs] = useState([]);
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
  
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    
    const [isMuted, setIsMuted] = useState(false);
    const progressBarRef = useRef(null);

    const [listPlayer, setListPlayer] = useState(false);


    useEffect(() => {
        if (list) {setSongs(list)};
    }, [list]);

    useEffect(() => {
      if (isPlaying) {
        // Ваш код для відтворення пісні (використовуйте аудіо API або бібліотеки)
        const audio = new Audio(songs[currentSongIndex]?.url);
        audio.play();

  
        audio.addEventListener('ended', () => {
          // При завершенні пісні переходимо на наступну
          nextSongHandler();
        });

        const handleTimeUpdate = () => {
          setCurrentTime(Math.floor(audio.currentTime));


            // Оновлюємо ширину прогрес-бару відповідно до часу відтворення
            const progressPercent = (audio.currentTime / audio.duration) * 100;
            progressBarRef.current.style.width = `${progressPercent}%`;
        };
    
        const handleLoadedMetadata = () => {
          setDuration(Math.floor(audio.duration));
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);

        audio.volume = isMuted ? 0 : 1;

        return () => {
          // При зміні пісні або вимиканні компонента зупиняємо відтворення
          audio.pause();
          //audio.currentTime = 0;
        };
      }
    }, [currentSongIndex, isPlaying, songs, isMuted]);
  
    const playPauseHandler = () => {
      setIsPlaying(!isPlaying);
    };
  
    const prevSongHandler = () => {
      setCurrentSongIndex((prevIndex) => (prevIndex - 1 + songs.length) % songs.length);
    };
  
    const nextSongHandler = () => {
      setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
    };

    const playSongHandler = (index) => {
        setCurrentSongIndex((index));
        setIsPlaying(true);
        console.log("playSongHandler", index)
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };
  
    return (
      <div>
        <DraggableItem>
        <div className="player-ctn">
            <div className="infos-ctn">
                <div className="timer"> {currentTime}</div>
                <div className="title">{songs[currentSongIndex]?.name}</div>
                <div className="duration">{duration}</div>
            </div>
            <div id="myProgress">
                <div ref={progressBarRef} id="myBar"></div>
            </div>
            <div className="btn-ctn">
                <div className="btn-action first-btn" onClick={prevSongHandler}>
                    <div id="btn-faws-back">
                    <i className='fas fa fa-step-backward'></i>
                    </div>
                </div>
                {/* <div className="btn-action">
                    <div id="btn-faws-rewind">
                        <i className='fas fa fa-backward'></i>
                    </div>
                </div> */}
                <div className="btn-action">
                    <div id="btn-faws-play-pause" onClick={playPauseHandler}>
                    {isPlaying ? <i className='fas fa fa-pause'></i> : <i className='fas fa fa-play'></i>}
                    </div>
                </div>
                {/* <div className="btn-play">
                    <div id="btn-faws-forward">
                    <i className='fas fa fa-forward'></i>
                    </div>
                </div> */}
                <div className="btn-action" onClick={nextSongHandler}>
                    <div id="btn-faws-next">
                    <i className='fas fa fa-step-forward'></i>
                    </div>
                </div>
                {/* <div className="btn-mute" id="toggleMute" onClick={toggleMute}>
                    <div id="fa fa-volume-up">
                    <i className='fas fa fa-volume-up'></i>
                    <i className='fas fa fa-volume-off' style={{display: "none"}}></i>
                    </div>
                </div> */}

                <div className="btn-mute" id="toggleMute" onClick={()=>setListPlayer(!listPlayer) }>
                    <i className="fas fa fa-list" aria-hidden="true"></i>
                </div>

                
            </div>

            {listPlayer ? <div className="playlist-ctn" >
                {songs && songs.length > 0 ? songs.map((itemg, index) => ( 
                    <ListSound key = {index} keys = {index} track={itemg} act = {currentSongIndex} playindex = {playSongHandler}/>
                    )) :  <div>Список відтворення пустий (у цій колоді немає аудіо файлів)</div>
                }
            </div> : null}
        </div>
        </DraggableItem >
      </div>
    );
  };

  const AudioList = ({list}) => {
    const [songs, setSongs] = useState([]);
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
  
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    
    const [isMuted, setIsMuted] = useState(false);
    const progressBarRef = useRef(null);

    const [listPlayer, setListPlayer] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null); // Estado para almacenar el elemento seleccionado


    useEffect(() => {
        if (list) {setSongs(list)};
    }, [list]);

    useEffect(() => {
      if (isPlaying) {
        // Ваш код для відтворення пісні (використовуйте аудіо API або бібліотеки)
        const audio = new Audio(songs[currentSongIndex]?.url);
        audio.play();

  
        audio.addEventListener('ended', () => {
          // При завершенні пісні переходимо на наступну
          nextSongHandler();
        });

        const handleTimeUpdate = () => {
          setCurrentTime(Math.floor(audio.currentTime));


            // Оновлюємо ширину прогрес-бару відповідно до часу відтворення
            const progressPercent = (audio.currentTime / audio.duration) * 100;
            progressBarRef.current.style.width = `${progressPercent}%`;
        };
    
        const handleLoadedMetadata = () => {
          setDuration(Math.floor(audio.duration));
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);

        audio.volume = isMuted ? 0 : 1;

        return () => {
          // При зміні пісні або вимиканні компонента зупиняємо відтворення
          audio.pause();
          //audio.currentTime = 0;
        };
      }
    }, [currentSongIndex, isPlaying, songs, isMuted]);
  
    const playPauseHandler = () => {
      setIsPlaying(!isPlaying);
    };
  
    const prevSongHandler = () => {
      setCurrentSongIndex((prevIndex) => (prevIndex - 1 + songs.length) % songs.length);
    };
  
    const nextSongHandler = () => {
      setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
    };

    const playSongHandler = (index) => {
        setCurrentSongIndex((index));
        setIsPlaying(true);
        console.log("playSongHandler", index)

        //playPauseHandler()
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };
  
    return (
      <div>

        {/* <div className="player-ctn">
            <div className="infos-ctn">
                <div className="timer"> {currentTime}</div>
                <div className="title">{songs[currentSongIndex]?.name}</div>
                <div className="duration">{duration}</div>
            </div>
            <div id="myProgress">
                <div ref={progressBarRef} id="myBar"></div>
            </div>
            
            <div >
                {songs && songs.length > 0 ? songs.map((itemg, index) => ( 
                    <ListSound key = {index} keys = {index} track={itemg} act = {currentSongIndex} playindex = {playSongHandler}/>
                    )) :  <div>Список відтворення пустий (у цій колоді немає аудіо файлів)</div>
                }
            </div>
        </div> */}

        <ul className='uploadfilelist'>
          {songs.map((itemg, index) => (
            <li key={index} className={`lisinmarker ${selectedItem === index ? 'active' : ''}`} onClick={() => setSelectedItem(index)}>
              <figure className="userlist" >
                <div className='linenumero'> {index + 1} </div>
                <div className='reference'>{itemg.name} </div>
                <div className='delfile' onClick={index === currentSongIndex & isPlaying ? () => playPauseHandler() : () => playSongHandler(index) }>{index === currentSongIndex & isPlaying ? <i className='fas fa fa-pause'></i> : <i className='fas fa fa-play'></i>}</div>
              </figure>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const ListSound = (props) => {

    const handlePlayPause = (index) => {
        props.playindex(index)
    }

    return (
        <div id = {"Card_" + props.keys} className='playlist-ctn' onClick={() => handlePlayPause(props.keys)}>
            <div className="playlist-track-ctn" id="ptc-0" data-index="0">
                <div className="playlist-btn-play" id="pbp-0" >
                    {props.keys === props.act ? <i className='fas fa fa-pause' height="40" width="40"></i> : <i className='fas fa fa-play' height="40" width="40"></i>}
                </div>
                <div className="playlist-info-track">{props.keys}. {cleartext(props.track.author)}</div>
                <div className="playlist-duration">{props.track.name}</div>
            </div>
        </div>
    );
  }

  const cleartext = (html) => {
    const htmlText = html
    // Створення тимчасового DOM-елемента
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlText;

    // Отримання чистого тексту без HTML-тегів
    const plainText = tempElement.innerText || tempElement.textContent;
    return plainText
};


const DraggableItem = ({children}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [lastClickTime, setLastClickTime] = useState(0);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (isDragging) {
              const offsetX = e.clientX - startPosition.x;
              const offsetY = e.clientY - startPosition.y;
      
              const newX = Math.max(0, Math.min(window.innerWidth - 200, position.x + offsetX));
              const newY = Math.max(0, Math.min(window.innerHeight - 200, position.y + offsetY));
      
              setPosition({
                x: newX,
                y: newY,
              });
      
              setStartPosition({
                x: e.clientX,
                y: e.clientY,
              });
            }
          };
  
      const handleMouseUp = () => {
        setIsDragging(false);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isDragging]);
  
    const handleMouseDown = (e) => {
        const currentTime = new Date().getTime();
        if (currentTime - lastClickTime < 200) {
          // Якщо між кліками пройшло менше 200 мс, не розпочинайте переміщення
          setLastClickTime(0);
          return;
        }
    
        setIsDragging(true);
        setStartPosition({
          x: e.clientX,
          y: e.clientY,
        });
    
        setLastClickTime(currentTime);
      };
  
    return (
      <div className='playerui'
        style={{
          position: 'absolute',
          left: position.x,
          top: position.y,
          cursor: 'move',
        //   border: '1px solid #000',
          padding: '8px',
        //   backgroundColor: isDragging ? '#aaf' : '#afa',
          zIndex:9999,
        }}
        onMouseDown={handleMouseDown}
      >
        {children}
      </div>
    );
  };

export default SchoolDecks;

const FileUpload = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const login_token = localStorage.getItem('login_token');

    const handleFileChange = (e) => {
      setSelectedFiles([...selectedFiles, ...e.target.files]);
    };
  
    const handleSubmit = async () => {
      const formData = new FormData();

      selectedFiles.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      var  userData = {}
      userData = {
          deckid: props.deckid,
          userid: "69",
      };

      // Додайте інші дані в JSON форматі, якщо потрібно
      formData.append('json', JSON.stringify(userData));

      try {
        const response = await fetch('https://www.nebo.inupline.com/api/web/files/upload', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + login_token,
            },
          body: formData,
        });
        const data = await response.json();
        console.log(data);
        setSelectedFiles([]);
        props.update(true)
      } catch (error) {
        console.error('Error uploading files: ', error);
      }
    };

    const handleAddFile = () => {
        fileInputRef.current.click();
      };
  
    const handleRemoveFile = (indexToRemove) => {
        setSelectedFiles(selectedFiles.filter((_, index) => index !== indexToRemove));
      };
      
    const renderSelectedFiles = () => {
        return selectedFiles.map((file, index) => (
            <li key={index} className="lisinmarker" >
                <figure className="userlist" >
                    <div className='linenumero'> {index +1} </div>
                    <div className='reference'>{file.name}</div>
                    <div className='delfile' onClick={() => handleRemoveFile(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
            </figure>
          </li>
        ));
      };

      const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setSelectedFiles([...selectedFiles, ...files]);
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
      };

    return (
      <div className='form_upload'>


<div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      style={{
        border: '2px dashed #cccccc',
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        marginBottom: '20px',
        cursor: 'pointer',
      }}
    >
      <p>Arrastra y suelta archivos aquí, o</p> 
      <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} />
      <a onClick={() => document.querySelector('input[type=file]').click()}> <b>Seleccionar archivos</b></a>
      <div>

        {selectedFiles.length > 0 && (<div>
            <h3>Archivos seleccionados:</h3>
            <ul className='uploadfilelist'>
            {renderSelectedFiles()}
            </ul>
            <button className='btn' onClick={handleSubmit}>
            Enviar {selectedFiles.length} archivo{selectedFiles.length !== 1 ? 's' : ''}
            </button>
        </div>
            
        )}

      </div>
    </div>

      </div>
    );
  };



  const FileLoadList = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null); // Estado para almacenar el elemento seleccionado
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(false);

    const login_token = localStorage.getItem('login_token');


    useEffect(() => {
      setSelectedFiles(props.getFiles);
    }, [props.getFiles]);

    const handleRemoveFile = (indexToRemove) => {
      setIsModalOpen(true)
      setActiveIndex(indexToRemove)
      //setSelectedFiles(selectedFiles.filter((_, index) => index !== indexToRemove));
    };

    const handleDelete = () => {
      setIsModalOpen(false)
      setSelectedFiles(selectedFiles.filter((_, index) => index !== activeIndex));
      DeleteFileAPI()
    };

    const handleSelectFile = (index) => {
      props.selFiles(selectedFiles[index]);
      setSelectedItem(index)
      console.log(index)
    };

    const DeleteFileAPI = () => {
      const formData = new FormData();
      formData.append('file', selectedFiles[activeIndex].name);
      formData.append('folder', `/users/69/decks/${props.deckid}/files`);
  
      console.log("selectedFiles[activeIndex].name", selectedFiles[activeIndex].name)
      fetch('https://www.nebo.inupline.com/api/web/files/delete', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + login_token,
          },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log('DeleteFileAPI Відповідь з сервера:', data);
          props.update(true)
          // Додаткова обробка відповіді тут
        })
        .catch(error => {
          console.error('DeleteFileAPI Помилка:', error);
          // Обробка помилок тут
        });
    };

    
  // <div className={`lisinmarker ${selectedItem === 4 ? 'active' : ''}`} onClick={() => handleItemClick(4)}>

    const renderSelectedFiles = () => {
        return selectedFiles.map((file, index) => (
            <li key={index} className={`lisinmarker ${selectedItem === index ? 'active' : ''}`}  onClick={() => handleSelectFile(index)} >
                <figure className="userlist" >
                    <div className='linenumero'> {index +1} </div>
                    <div className='reference'>{file.name}</div>
                    <div className='delfile' onClick={() => handleRemoveFile(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
            </figure>
          </li>
        ));
      };

    return (
      <div className='form_upload'>
        {selectedFiles.length > 0 && (
          <div>
              <h3>File list:</h3>
              <ul className='uploadfilelist'>
              {renderSelectedFiles()}
              </ul>
          </div>
        )}

        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleDelete}
        />
      </div>
    );
  };

  const VideoPlayer = ({ url , setLoading  }) => {
    return <video controls src={url} style={{width: "100%", padding:"0px", borderRadius:"5px"}} />
  };

  const ImgView = ({ url }) => {
    return <img src={url} alt = "img" style={{width: "100%", padding:"0px", borderRadius:"5px"}} />;
  };

  const PDFview = ({ base64 }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
      setNumPages(nextNumPages);
    }

    //console.log("PDFview base64",base64)
  
    return (
      <div className="Example__container__document">
        {/* {base64 && <Document file={{data: atob(base64)}} onLoadSuccess={onDocumentLoadSuccess} style={{'--scale-factor': 0.5}}>
          {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document> }
        <p>
          Page {pageNumber} of {numPages}
        </p> */}

        <iframe title="PDF Viewer" src={`data:application/pdf;base64,${base64}`} width="100%" height="500px" sandbox="allow-same-origin allow-scripts"/>

      </div>
    );
  }


  const TestList = ({preguntas}) => {
    const [respuestas, setRespuestas] = useState(new Array(preguntas.length).fill(null));
  const [finalizado, setFinalizado] = useState(false);

  const seleccionarRespuesta = (indexPregunta, indexRespuesta) => {
    const nuevasRespuestas = [...respuestas];
    nuevasRespuestas[indexPregunta] = indexRespuesta;
    setRespuestas(nuevasRespuestas);
  };

  const calcularPuntaje = () => {
    let puntaje = 0;
    for (let i = 0; i < preguntas.length; i++) {
      if (respuestas[i] === preguntas[i].respuestaCorrecta) {
        puntaje += preguntas[i].puntos;
      }
    }
    return puntaje;
  };

  const finalizarExamen = () => {
    setFinalizado(true);
  };

  const repetirExamen = () => {
    setRespuestas(new Array(preguntas.length).fill(null));
    setFinalizado(false);
  };

  const verMisRespuestas = () => {
    alert("Tus respuestas:\n" + respuestas.map((respuesta, index) => `Pregunta ${index + 1}: ${preguntas[index].respuestas[respuesta]}`).join("\n"));
  };


    return (
      <div className='form_upload'>
        <h2>Preview:</h2>
      {preguntas.map((pregunta, index) => (
        <div key={index}>
          <p>{pregunta.pregunta}</p>
          {pregunta.respuestas.map((opcion, indexOpcion) => {
            const esRespuestaSeleccionada = respuestas[index] === indexOpcion;
            const esRespuestaCorrecta = indexOpcion === pregunta.respuestaCorrecta;
            return (
              <div key={indexOpcion}>
                <input
                  type="radio"
                  id={`opcion-${index}-${indexOpcion}`}
                  name={`pregunta-${index}`}
                  value={indexOpcion}
                  checked={esRespuestaSeleccionada}
                  onChange={() => seleccionarRespuesta(index, indexOpcion)}
                  disabled={finalizado}
                />
                <label htmlFor={`opcion-${index}-${indexOpcion}`}>
                  {esRespuestaSeleccionada ? <b>{opcion}</b> : opcion}
                </label>
                {finalizado && esRespuestaSeleccionada && (
                  <span style={{ color: esRespuestaCorrecta ? 'green' : 'red' }}>
                    {esRespuestaCorrecta ? ' (Correcta)' : ' (Incorrecta)'}
                  </span>
                )}
              </div>
            );
          })}
          {finalizado && (
            <p style={{border: "2px dashed rgb(204, 204, 204)", borderRadius: "5px", padding: "20px"}}>{pregunta.explicacion}</p>
          )}
        </div>
      ))}
      {!finalizado && (
        <button onClick={finalizarExamen}>Finalizar</button>
      )}
      {finalizado && (
        <>
          <p>Puntaje obtenido: {calcularPuntaje()}</p>
          <button onClick={repetirExamen}>Repetir</button>
        </>
      )}
                <button onClick={verMisRespuestas}>Ver mis respuestas</button>

      </div>
    );
  };

  function PreguntaForm({ index, preguntaSeleccionada, actualizarPregunta, agregarPregunta }) {
    const [pregunta, setPregunta] = useState('');
    const [respuestaCorrecta, setRespuestaCorrecta] = useState(0);
    const [respuestas, setRespuestas] = useState(['', '']);
    const [explicacion, setExplicacion] = useState('');
    const [puntos, setPuntos] = useState('');
  
    // Actualizar el estado cuando se selecciona una pregunta existente
    useEffect(() => {
      if (preguntaSeleccionada) {
        setPregunta(preguntaSeleccionada.pregunta);
        setRespuestaCorrecta(preguntaSeleccionada.respuestaCorrecta);
        setRespuestas(preguntaSeleccionada.respuestas);
        setExplicacion(preguntaSeleccionada.explicacion);
        setPuntos(preguntaSeleccionada.puntos);
      }
    }, [preguntaSeleccionada]);
  
    const agregarRespuesta = () => {
      setRespuestas([...respuestas, '']);
    };
  
    const handleRespuestaChange = (index, value) => {
      const nuevasRespuestas = [...respuestas];
      nuevasRespuestas[index] = value;
      setRespuestas(nuevasRespuestas);
    };

    const handleCrearNuevaPregunta = () => {
      const nuevaPregunta = {
        pregunta,
        respuestaCorrecta,
        respuestas,
        explicacion,
        puntos: puntos ? parseInt(puntos) : 0 // Asegurarse de que los puntos sean un número entero
      };

      agregarPregunta(nuevaPregunta)
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Validar que haya al menos una respuesta
      if (respuestas.length < 1) return;
  
      const nuevaPregunta = {
        pregunta,
        respuestaCorrecta,
        respuestas,
        explicacion,
        puntos: puntos ? parseInt(puntos) : 0 // Asegurarse de que los puntos sean un número entero
      };
  
      if (preguntaSeleccionada) {
        actualizarPregunta(nuevaPregunta);
      } else {
        agregarPregunta(nuevaPregunta);
      }
  
      // Reiniciar valores
      setPregunta('');
      setRespuestaCorrecta(0);
      setRespuestas(['', '']);
      setExplicacion('');
      setPuntos('');
    };
  
    return (
      <>        
      <h2 style={{padding:"0", margin:0}}>Editor</h2>
      
      <form onSubmit={handleSubmit} className="form_0">
        <label>
          Pregunta:
          <input type="text" value={pregunta} onChange={(e) => setPregunta(e.target.value)} />
        </label>
        <label>
          Respuesta Correcta:
          <select value={respuestaCorrecta} onChange={(e) => setRespuestaCorrecta(parseInt(e.target.value))}>
            {respuestas.map((_, index) => (
              <option key={index} value={index}>Opción {index + 1}</option>
            ))}
          </select>
        </label>
        <label>
          Respuestas:
          {respuestas.map((respuesta, index) => (
            // <input key={index} type="text" value={respuesta} onChange={(e) => handleRespuestaChange(index, e.target.value)} />
            <div key={index} className={`input_field ${respuestaCorrecta === index ? 'active' : ''}`}><span>{index+1}</span>
                <input id="note" name="note" type="text" placeholder="Idioma" value={respuesta} required onChange={(e) => handleRespuestaChange(index, e.target.value)}/>
            </div>

          ))}
          <button type="button" onClick={agregarRespuesta}>Agregar Respuesta</button>
        </label>
        <label>
          Explicación:
          <textarea value={explicacion} onChange={(e) => setExplicacion(e.target.value)} />
        </label>
        <label>
          Puntos:
          <input type="number" value={puntos} onChange={(e) => setPuntos(e.target.value)} />
        </label>
        <button type="submit">{preguntaSeleccionada ? 'Actualizar' : 'Guardar'}</button>
        <button type="button" onClick={handleCrearNuevaPregunta}>Crear Nueva Pregunta</button>
      </form>
      </>
    );
  }
  
  function PreguntaList({ preguntas, seleccionarPregunta, eliminarPregunta }) {
    const [selectedItem, setSelectedItem] = useState(null); // Estado para almacenar el elemento seleccionado
    //const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSelectFile = (val, index) => {
      seleccionarPregunta(val);
      setSelectedItem(index)
    };
    return (
      <div>
        {/* <h2 style={{padding:"0", margin:0}}>Preguntas</h2> */}
        <ul className='uploadfilelist'>
          {preguntas.map((pregunta, index) => (
            <li key={index} className={`lisinmarker ${selectedItem === index ? 'active' : ''}`} onClick={() => handleSelectFile(pregunta,index)}>
              <figure className="userlist" >
                <div className='linenumero'> {index +1} </div>
                <div className='reference'>{pregunta.pregunta}</div>
                <div className='delfile' onClick={() => eliminarPregunta(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
              </figure>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  function NextLevel({vnextid, vminpoints, nextDeckID, minPoint }) {
    const [nDeckID, setNDeckID] = useState(0); // Estado para almacenar el elemento seleccionado
    const [nMinPoint, setNMinPoint] = useState(0);
    const [nThemes, setNThemes] = useState(null);

    const projectid = localStorage.getItem('projectid');
    const login_token = localStorage.getItem('login_token');


    const handleID = (e) => {
      nextDeckID(e.target.value);
      setNDeckID(e.target.value);
    };

    const handlePoint = (e) => {
      minPoint(e.target.value);
      setNMinPoint(e.target.value);
    };

    // useEffect(() => {
    //     nextDeckID(nDeckID);
    //     minPoint(nMinPoint);
    // }, []);

    useEffect(() => {
      GetThemaList()
      setNDeckID(vnextid);
      setNMinPoint(vminpoints);
      console.log(vnextid,vminpoints)
  }, []);
    
    const GetThemaList = () => {
      fetch('https://www.nebo.inupline.com/api/web/anki/themas/' + projectid, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setNThemes(response.decks)
            console.log("all themas", response.decks)
          }
      });
    };

    return (
      <div>
        {/* <h2 style={{padding:"0", margin:0}}>Після</h2> */}
        <form className="form_0" style={{padding:"0",display:"flex" }}>
        <div style={{flex:"1" }}>
          <label className="desc" id="typeid_l" htmlFor="typeid">
            мінімальний прохідний бал
          </label>
          <div className="input_field" style={{width:"250px" }}> <span><i aria-hidden="true" className="fa fa-font"></i></span>
              <input id="minPoint" name="minPoint" type="text" placeholder="minPoint" value={nMinPoint} required onChange={handlePoint}/>
          </div>
        </div>
          {/* <UserSelect users={nThemes} onSelect={handleID} /> */}

        <div style={{flex:"1" }}>
          <label className="desc" id="typeid_l" htmlFor="typeid">
            Відкрити тему:
          </label>
          <div className="input_field" style={{width:"250px" }}>
              <select onChange={handleID} value={nDeckID}>
                <option value={0}>none</option>
                {nThemes && nThemes.map((itms, index) => (
                  <option key={index} value={itms.id}>{itms.note}</option>
                ))}
              </select>
          </div>
        </div>
        </form>
        <span style={{color:"red", }}> * УВАГА після додавання усіх питань НЕ ЗАБУТЬТЕ натиснути ЗБЕРЕГТИ !!!</span>

      </div>
    );
  }

  const UserSelect = ({ users, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
  
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const handleSelect = (user) => {
      setSelectedUser(user);
      onSelect(user);
      toggleOpen();
    };
  
    return (
      <div className="user-select">
        <div className="selected-user" onClick={toggleOpen}>
          {selectedUser ? (
            <>
              <img src={selectedUser.picture} alt={selectedUser.name} />
              <span>{selectedUser.note}</span>
            </>
          ) : (
            <span>Selecciona un usuario</span>
          )}
        </div>
        {isOpen && (
          <div className="options">
            {users.map((user, index) => (
              <div key={index} onClick={() => handleSelect(user)}>
                <img src={user.picture} alt={user.note} />
                <span>{user.note}</span>
                <span>{user.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  