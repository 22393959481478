import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';


export default function Index(props) {
  const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
  const user_leng_ui = localStorage.getItem('user_leng_ui');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');
    
    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
    const [SchoolGroup, setSchoolGroup] = useState([]); // IMPORT Group / Course
    const [SchoolTitle, setSchoolTitle] = useState([]); // IMPORT Group / Course
    const [SchoolDecks, setDecks] = useState([]); // IMPORT CLASS
    const [selectedID, setSelectedID] = useState(null);
    const [SchoolDeck, setDeck] = useState([]); // IMPORT DECKS

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    //const login_token = localStorage.getItem('login_token');
    const usrname = localStorage.getItem('usrname');

    function OutME(event) {
      navigate(`/`);
    }

    const ClassAll = localStorage.getItem('class_all');
    if (ClassAll ) { 
      var Class_all = JSON.parse(ClassAll); 
      console.log("Class", Class_all)
    }

  
    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            <div className='appinfopanel'>
              <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
              <div className="tHello" >
                {user_leng_ui === "0" && "Users"}
                {user_leng_ui === "1" && "Usuarios"}
                {user_leng_ui === "2" && "КОРИСТУВАЧІ"}
              </div>
            </div>
            
            <div className='BoxRang'>
              <div className='InfoChoisClass'>
                {user_leng_ui === "0" && "You are alone in the classroom for now."}
                {user_leng_ui === "1" && "Están solos en el aula por ahora."}
                {user_leng_ui === "2" && "Ви покищо одні в класі"}
              </div>
            </div>

            
        </div>

        </div>
    );
}