import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken,onMessage  } from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyAzLIA1MYHzVlP21kkrzr9MCYFdvSiao3Y",
//     authDomain: "inupline.firebaseapp.com",
//     projectId: "inupline",
//     storageBucket: "inupline.appspot.com",
//     messagingSenderId: "567575451454",
//     appId: "1:567575451454:web:a166ffe8ce3fb0acad3e6f",
//     measurementId: "G-JYFXHQ7BB0"
// }

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const firebaseApp = initializeApp({
  apiKey: "AIzaSyAzLIA1MYHzVlP21kkrzr9MCYFdvSiao3Y",
  authDomain: "inupline.firebaseapp.com",
  projectId: "inupline",
  storageBucket: "inupline.appspot.com",
  messagingSenderId: "567575451454",
  appId: "1:567575451454:web:a53ab9054feaac0bad3e6f",
  measurementId: "G-K6X9XNX3XS"
});

var DevID = '';


const messaging = getMessaging(firebaseApp);

let TokenDevice;

export async function GetDeviceID() {

  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BAJ6lE-U1DSWG7_sINlcw0xCKsu1zt_tlsgvm_yP_QdPrbFgr-fEejJpCbWR8PY-sNijrHz9GW4GGhacvjmNUyA' });

    if (currentToken) {
      // Оновити змінну TokenDevice, якщо отримано токен
      // Оновити змінну TokenDevice, якщо отримано токен
      return currentToken;
    } else {
      // console.log('No registration token available. Request permission to generate one.');
      return 'No registration token available. Request permission to generate one.'
      // Тут ви можете викликати функцію або відобразити повідомлення користувачу про те, що токен не отримано
    }
  } catch (err) {
    // console.log('An error occurred while retrieving token. ', err);
    return 'An error occurred while retrieving token. ' + err
    // Обробити помилку відповідно до вашого випадку використання
  }
}

// export function GetDeviceID() {
//   getToken(messaging, { vapidKey: 'BAJ6lE-U1DSWG7_sINlcw0xCKsu1zt_tlsgvm_yP_QdPrbFgr-fEejJpCbWR8PY-sNijrHz9GW4GGhacvjmNUyA' }).then((currentToken) => {
//     if (currentToken) {
//       // Send the token to your server and update the UI if necessary
//       DevID = currentToken;
//       console.log('currentToken=', DevID);
//       // ...
//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//       // ...
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
//   });
  
//   onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     // ...
//   });
  
//   //console.log("DevID",DevID);
//   return DevID
// }



