import React , { useEffect,useState } from 'react';
import QRCode from 'qrcode.react';
import { useNavigate, useParams} from "react-router-dom";  
import Tabs,{Tab, TabPage,TabBtn } from '../tabs/tabs';
import AddURL from "./AddURL"


function AppInfo(props) {
    //const [imgIco, setImgIco] = useState();
    const navigate = useNavigate();

    const toggleClass3 = () => {
      const oldClassName = document.getElementById('Menu03').className;
      if (oldClassName === 'menu2open') {
        const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
        document.getElementById('Menu03').className=newClassName;
        var workPageNew = "body"
        const workPageOld = document.getElementById('body').className;
        if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
        if (workPageOld === 'body' ) workPageNew = 'bodyright'
        if (workPageOld === 'bodyright' ) workPageNew = 'body'
        if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
        document.getElementById('body').className=workPageNew;
      }
    }
  
    const openLinkApp = (url) => {
      console.log("url",url)
      if (url) {
        if (url.startsWith("http")) {
          window.open(url, "_blank")
        } else {
            navigate(url);
        }
      }
    }

    //console.log(props.UnitInfo.id)
  
    // const iconLink = () => {
    //     let urlIcon = "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" + props.UnitInfo.url + "&size=256"
    //     setImgIco(urlIcon);
    // }
  
    // useEffect(() => {
    //     iconLink()
    //   }, [props]); // AUTOLOAD
  
    return (
        <div>
            <div className="aButtonClose" onClick={toggleClass3}>x</div>

            <Tabs> 
                <Tab label="Link">
                  <div className="container">
                    <div className="row">
                      {/* <img className="aLogo" src={!props.UnitInfo.ico ? imgIco : "/img/" + props.UnitInfo.ico} alt="logo "/> */}
                      <QRCode className="aLogo" value={props.UnitInfo.url} size={150} fgColor={props.QRcol1} bgColor={props.QRcol2} level={"H"} imageSettings={{src:"/img/" + props.UnitInfo.ico, height: 64, width: 64,excavate:true}}/>
                    </div>
                    
                    <div className="row">
                      <div className="aTitle">{props.UnitInfo.title}</div>
                    </div>
                    <div className="row">
                      <div className="aButtonOpen" onClick = {() => openLinkApp(props.UnitInfo.url)} >Iniciar aplicación </div>
                    </div>
                    <div className="row">
                      <div className="aNote">{props.UnitInfo.note}</div>
                    </div>
        
                    <div className="row">
                      {props.UnitInfo.info ? <div className="aInfo">{props.UnitInfo.info}</div>: null}
                    </div>
                  </div>
                </Tab>
                <Tab label={"Ajustes"}>
                  <AddURL url= {props.UnitInfo} ></AddURL>
                </Tab>
            </Tabs>
            
        </div>
    );
  }
  
export default AppInfo;