import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
// import {Card} from "../components/cards/cards";
// import LoadingUI from '../components/loading/loadingui';
import ImgF003 from '../../img/BFimg/F003.jpg';  //sadmin.png avatar.jpeg
import Avatar from '../../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from '../../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png
import flag_es from '../../img/ESCR2.png';  //sadmin.png avatar.jpeg temp02.png
import deck from '../../img/deck.png';  //sadmin.png avatar.jpeg temp02.png
import {useApiData, useLoadApiFoto} from "../../functions/useApiData"

import './../userlist.css'; // Стилі для елемента

import {StudentsCard} from "./../school_classes"

export function InviteList(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    //const dispatch = useDispatch();

    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    // const iconLink = () => {
    //     setImgIco(tmpimg)     
    // }

    // useEffect(() => {
    //     iconLink()
    // }, []); // AUTOLOAD

    return (
        <li className="lisinmarker" >
            <figure className="userlist" >
                <span className="linenumero">{props.index}</span>
                <span className="outdate"><i className="fa fa-share-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.datecreate}</span>
                <span className="acdate"><i className="fa fa-check-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.dateupdate}</span>

                <span className="status">
                    {props.json.status == 0 && <i className="fa fa-exclamation-circle" style={{color:"blue"}}></i>}
                    {props.json.status == 1 && <i className="fa fa-paper-plane" style={{color:"grey"}}></i>}
                    {props.json.status == 2 && <i className="fa fa-check-circle" style={{color:"green"}}></i>}
                    {props.json.status == 3 && <i className="fa fa-times-circle" style={{color:"red"}}></i>}
                    {props.json.status == 4 && <i className="fa fa-clock-o" style={{color:"orange"}}></i>}
                </span>

                {props.json.roleid !=7 & props.json.roleid !=8 ? <span className="email"> <i className="fa fa-envelope" style={{color:"green"}}></i>&nbsp;&nbsp;&nbsp;{props.json.email}</span>:null}
                {props.json.roleid == 7 && <span className="email"> <i className="fa fa-envelope" style={{color:"orange"}}></i>&nbsp;&nbsp;&nbsp;{props.json.email}</span>}
                {props.json.roleid == 8 && <span className="email"> <i className="fa fa-envelope" style={{color:"grey"}}></i>&nbsp;&nbsp;&nbsp;{props.json.email}</span>}
            </figure>
        </li>
    );
}


export function LevelCard(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    useEffect(() => {
        setImgIco(tmpimg)     

    }, []); // AUTOLOAD

    return (

        <div id = {"Card_" + props.ids} className={props.cs ? "card3 card-" + props.cs : "card3 card-3"} onClick={openLinkURL} style={props.style}>
                {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
                {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
                <img className= "lLogo" src={!props.imgs ? imgIco : "/img/" + props.imgs} alt="logo "/>
                <div className="llblTitle">lvl name:</div>
                <div className="lTitle">{props.title}</div>
                <div className="lNote">{props.note}</div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
        </div>
    );
}

export function DecksCard(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();

    const openLinkURL = (id) => {
        props.selid (id)
    }

    useEffect(() => {
        setImgIco(tmpimg)     
    }, []); // AUTOLOAD

    return (

        <div id = {"Decks_" + props.id} className={props.cs ? "card_deck card-" + props.cs : "card3 card-3"} onClick={() => openLinkURL(props.id)} style={props.style}>
                <img className= "dcLogo" src={star_anki} alt="dcLogo"/>
                {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                <div className="dclbl01">ID: {props.id}</div>
                <div className="dclbl02">CARDS: {props.cards}</div>
                <div className="dclbl03" style={{ color: props.colort}} >{props.label}</div>
                <div className="dclbl04" style={{ color: props.colort}} >{props.title}</div>
                <div className="dclbl05" style={{ color: props.colort}} >{props.note}</div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
        </div>
    );
}

export function GroupCard(props) {
    //WELCOM PAGE

    const iconsContext = require.context('../../img/flags/', false, /\.svg$/);
    const svgFiles = iconsContext.keys();

    const [imgIco, setImgIco] = useState();

    const openLinkURL = (id) => {
        props.selid (id)
    }

    // svgFiles.map((filename) => (
    //     console.log(filename, iconsContext(svgFiles[5]))
    // ))

    //console.log(iconsContext(svgFiles[69]))

    useEffect(() => {
        setImgIco(tmpimg)     
    }, []); // AUTOLOAD

    return (

        <div id = {"Group_" + props.component.id} className={"card3"} onClick={() => openLinkURL(props.component.id)} style={{ backgroundColor: props.component.colorb}}>
                <img className= "dcFlagLogo" src={iconsContext(svgFiles[!props.component.icoAva ? 236 : props.component.icoAva])} alt="dcFlagLogo"/>
                {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                <div className="dclbl01">ID: {props.component.id}</div>
                <div className="dclbl02">Students: {props.component.students}</div>
                {/* <div className="dclbl03b" style={{ color: props.component.colort}} >{props.component.label}</div> */}
                <div className="dclbl04b" style={{ color: props.component.colort}} >{props.component.title}</div>
                <div className="dclbl05b" style={{ color: props.component.colort}} >{props.component.note}</div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
        </div>
    );
}

function School(props) {
    const [SchoolArray, setSchoolArray] = useState([]); // IMPORT Group / Course
    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
    const [SchoolDecks, setDecks] = useState([]); // IMPORT DECKS
    const [SchoolStudents, setStudents] = useState([]); // IMPORT DECKS

    const [ProjectURL, setProjectURL] = useState([]); // IMPORT URL

    const apiEndpointAPI2 = `/project/url/${props.pid}`;

    const { data: UrlData, loading: loading2, error: APIError2 } =  useApiData('GET', apiEndpointAPI2);

    const [selectedID, setSelectedID] = useState(null);

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');

    const navigate = useNavigate();

    const [userRole, setUserRole] = useState(0);

    const [inviteList, setInviteList] = useState([]); // IMPORT Group / Course

    const apiEndpointInvite = `/invite/list/${props.pid}`;
    const { data: JSONInviteList, loading: loadingInvite, error: ErrorInvite } =  useApiData('GET', apiEndpointInvite);

    useEffect(() => { 
        if (JSONInviteList ) {
            setInviteList(JSONInviteList.invites)
            console.log ("JSONInviteList", JSONInviteList.invites)
        }
    }, [JSONInviteList]); // AUTOLOAD


    useEffect(() => {
        if (UrlData.URLs && UrlData.URLs.length > 0) {
            setProjectURL(UrlData.URLs)
            //console.log("UrlData",UrlData)
        } else {
            //console.warn("ListData.projects es undefined o vacío");
        }
    }, [props.pid,UrlData, selectedID]); // AUTOLOAD


        useEffect(() => {
            fetch('https://www.nebo.inupline.com/api/web/school/getCours/' + props.pid, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
                },
            })
            .then(response => response.json())
            .then(response => {
                if (response.status===200) {
                    //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                    setSchoolArray(response.levels)
                    setStudents(response.students)
                    // setLoading(false)
                    console.log("/getCours-" , response)
                } else {
                    // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                    console.warn("ERROR getCours-" , response)
                    setSchoolArray([])
                    setSchoolClass([])
                    setDecks([])
                //setLoading(false)
                }
            }); }, [props.pid]); // AUTOLOAD

            useEffect(() => {
                fetch('https://www.nebo.inupline.com/api/web/school/getDecks/' + props.pid, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + login_token,
                    },
                })
                .then(response => response.json())
                .then(response => {
                    if (response.status===200) {
                        //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                        setDecks(response.decks)
                        // setLoading(false)
                        console.log("/getDecks-" , response)
                    } else {
                        // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                        console.log("ERROR getDecks-" , response)
                    //setLoading(false)
                    }
                }); }, [props.pid]); // AUTOLOAD


            useEffect(() => {
                //console.log(selectedID)
                // navigate("/firstrun");

                fetch('https://www.nebo.inupline.com/api/web/school/getClass/' + selectedID, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + login_token,
                    },
                })
                .then(response => response.json())
                .then(response => {
                    if (response.status===200) {
                        //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                        setSchoolClass(response.groups)
                        // setLoading(false)
                        console.log("/getClass-" , response)
                    } else {
                        // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                        console.log("ERROR getClass-" , response)
                        setSchoolClass([])

                    //setLoading(false)
                    }

                }); }, [selectedID]); // AUTOLOAD


        const activeProjectId = localStorage.getItem('projectid');

        const handleChildValue = (childValue) => {
            // setSelectedID(childValue);
            // open class page
            //const class_id = localStorage.getItem('classid');
            localStorage.setItem('classid', childValue)

            navigate(`/classes`);
        };

        const handleStudentID = (childValue) => {
            // setSelectedID(childValue);
            // open class page
            //const class_id = localStorage.getItem('classid');
            localStorage.setItem('studentid', childValue)

            navigate(`/students`);
        };


        const handleShowDecks = (childValue) => {
            // setSelectedID(childValue);
            // open class page
            localStorage.setItem('deckid', childValue)
            navigate(`/decks`);
        };

        const handleShowAddDeck = (childValue) => {
            navigate(`/projects/adddeck`);
        };

        const handleShowAddClass = (childValue) => {
            navigate(`/projects/addclass`);
        };

        const handleToolsInvite = (childValue) => {
            console.log("vchat",childValue)
            navigate(`/vchat`);

        };

        const handleAddInvite = (childValue) => {
            navigate(`/invite`);
        };

        const handleGoHome = (childValue) => {
            navigate(`/`);
        };

        return (
            <div>
                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">PROJECTS {SchoolArray.length}</div>
                        <div className="aButtonClassConfig" onClick={() => handleShowAddClass()}><i className="fa fa-plus" aria-hidden="true"></i></div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <div className="classDeckslist" >
                        {SchoolArray.map((itemg, index) => ( 
                            <GroupCard key = {index} component={itemg} selid={handleChildValue}/>
                            // <div> {itemg.id} </div>
                        ))}
                    </div>
                </div>
                 
                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassHome"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">DOCUMENTACION {SchoolDecks.length}</div>
                        <div className="aButtonClassConfig" onClick={() => handleShowAddDeck()}><i className="fa fa-plus" aria-hidden="true"></i></div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <div className="classDeckslist" >
                        {SchoolDecks.map((itemg, index) => ( 
                            <DecksCard style={{ backgroundColor: itemg.colorb}} 
                            key = {index} 
                            id={itemg.id} 
                            deckid={itemg.deckid} 
                            title={itemg.title} 
                            label = {itemg.label}  
                            note = {itemg.note}  
                            cs={itemg.title} 
                            cards = {itemg.cards} 
                            ico={itemg.ico} 
                            colort={itemg.colort}
                            selid={handleShowDecks}/>
                        ))}
                    </div>
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">USERS </div>
                        {/* <div className="aButtonClassAdd"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <div className="classgroup" >
                            {SchoolStudents && SchoolStudents.map((itemg, index) => ( 
                                <StudentsCard 
                                    key = {index} // OK
                                    ids={itemg.id} // OK
                                    title={itemg.name} // OK - G1 ! => GRUPGROUP.GRUPNAME
                                    label = {itemg.label} // Label ? ! => GRUPGROUP.GRUPLABEL
                                    photo={itemg.photo} // OK  ! => GRUPGROUP.GRUPICON
                                    A1 = {itemg.A1} // Text color ! => GRUPGROUP.GRUPCOLORT
                                    A2 = {itemg.A2} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    A3 = {itemg.A3} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    level = {itemg.level} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    email = {itemg.email} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    status = {itemg.status} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    lastin = {itemg.lastin} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    startdate = {itemg.startdate} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    selid={handleStudentID} // Click Function
                                />
                            ))} 
                    </div>    
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">Invitation (invitations) </div>
                        <div className="aButtonClassImport" onClick={() => handleAddInvite()}><i className="fa fa-plus" aria-hidden="true"></i></div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig"><i className="fa fa-question-circle" aria-hidden="true"></i></div>
                    </div>

                    <ul className="grid_invite_user">
                            {inviteList && inviteList.map((itemg, index) => ( 
                                <InviteList 
                                    key = {index} 
                                    index = {index+1}
                                    json={itemg} 
                                    selid={handleChildValue} // Click Function
                                />
                            ))} 
                    </ul>    
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">TOOLS </div>
                        <div className="aButtonClassAdd"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </div>

                    

                    
                    {/* <div className="classlevellist" >
                        {SchoolArray.map((itemg, index) => ( 
                            <LevelCard key = {index} ids={itemg.id} title={itemg.title} date = {""} cs={1} note = {itemg.label} imgs={itemg.icoAva} selid={handleChildValue}/>
                        ))}
                    </div> */}
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">PRICE </div>
                        <div className="aButtonClassAdd"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </div>

                    {/* <div className="classlevellist" >
                        {SchoolArray.map((itemg, index) => ( 
                            <LevelCard style={{ backgroundColor: itemg.colorb,  color: itemg.colort }} 
                            key = {index} 
                            ids={itemg.id} 
                            title={itemg.title} 
                            date = {itemg.title}  
                            cs={itemg.title} 
                            note = {itemg.label} 
                            imgs={itemg.ico} 
                            selid={handleChildValue}/>
                            // <div> {itemg.id} </div>
                        ))}
                    </div> */}
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}><i className="fa fa-home" aria-hidden="true"></i></div>

                        <div className="cLabelClassHeaderBar">URLs</div>
                        <div className="aButtonClassAdd"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </div>

                    {/* <div className="classlevellist" >
                        {ProjectURL.map((itemg, index) => ( 
                            <LevelCard style={{ backgroundColor: itemg.colorb,  color: itemg.colort }} 
                            key = {index} 
                            ids={itemg.id} 
                            title={itemg.title} 
                            date = {itemg.title}  
                            cs={itemg.title} 
                            note = {itemg.label} 
                            imgs={itemg.ico} 
                            selid={handleChildValue}/>
                        ))}
                </div> */}
            </div>
        </div>
    );
}

export default School;
