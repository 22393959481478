import React , { useEffect,useState } from 'react';
import { useNavigate} from "react-router-dom";  
// import { useSelector, useDispatch } from 'react-redux';

export let ActElm = 0;

function LeftMenu(props) {
      // Filtrar elementos con etiqueta personalizada "menu"
    const menu = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'menu'
    );

    const menu_down = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'menu_down'
    );

    return (
        <div className="menuhide" id="Menu00">
            <div className = 'AppMenuBox'>
                <ul className="example-side-bar-list">
                    {menu}
                </ul>

                <ul className="example-side-bar-list-down">
                    {menu_down}
                </ul>
            </div>

            <div id="ToolTip"
                style={{
                position: 'fixed',
                display: 'none',
                top: '60px',
                left: '70px',
                // transform: 'translateX(10%)',
                backgroundColor: 'black',
                color: 'white',
                padding: '8px',
                borderRadius: '4px',
                zIndex:'1000',
            }}
          >
          </div>
        </div>
    )
};

export const UlMenu = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export const MenuItm = ({id,url,name,icon}) => {
    const navigate = useNavigate();

    // const pagejson = useSelector((state)=> state.application.app.page);

    const openLink2 = (url) => {
        
        // if (ActElm != id ) {
        //   navigate(url, {replace: true});
        // }

        navigate(url, {replace: true});
        // Ciclo para limpiar stilo        
        for (let id = 0; id <= 30; id++) {
            const element = document.getElementById(`MenuItm_${id}`);
            if (element) {
              element.style.borderRight  = '';
              element.style.width = '100%'; // Ocultar elemento
            }

          }
        // Marcar elemento pulsado

        const element = document.getElementById('MenuItm_'+ id);
        if (element) {
            element.style.borderRight  = '3px solid red'; // Mostrar elemento   
            element.style.width = 'calc(100% - 4px)'; // Ocultar elemento     
            ActElm = id;
        };
    };


    const handleMouseEnter = () => {
      handleChangeText();
    };
  
    const handleMouseLeave = () => {
      HideText();
    };

    const handleChangeText = (event) => {
        const element = document.getElementById('ToolTip');
        if (element) {
            element.textContent = name;
            element.style.display = 'block'; // Mostrar elemento        
        };
      };

      const HideText = () => {
        const element = document.getElementById('ToolTip');
        if (element) {
            element.style.display = 'none'; // Ocultar elemento
        };
    };

    const handleMouseMove = () => {
        const elementt = document.getElementById('ToolTip');
        const element = document.getElementById('MenuItm_'+ id);
        const containerRect = element.getBoundingClientRect();

        if (elementt) {
            elementt.style.top = (containerRect.top + 5) + 'px'; // Mostrar elemento        
        };
      };
      
      const toggleClassLSMenu = () => {
        const oldClassName = document.getElementById('LSMenu').className;
        const newClassName = oldClassName === 'LSmenuhide' ? 'LSmenuopen' : 'LSmenuhide'
        document.getElementById('LSMenu').className=newClassName;
      }

    return  <li className="example-side-bar-link" id = {"MenuItm_" + id}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                onClick={toggleClassLSMenu}
                >
                
                <span className="example-side-bar-link-icon" onClick = {() => openLink2(url)}>
                    <img className="searchIco" style={{filter:"invert(80%)", margin: "15px", width: "30px", color: "#f2f2f2"}} src={icon} alt={name}></img>
                </span>
        </li>;
  };

export default LeftMenu;