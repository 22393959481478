import React , { useRef, useState , useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { actions, application } from '../redux/store';
// import JSON_HomePage from '../json/json_home' // IMPORT ELEMENTS for HOME PAGE
import { useNavigate} from "react-router-dom";  
// import { GetDeviceID } from "../firebase"
import LoadingUI from '../../components/loading/loadingui';
import { actUser } from '../../redux/store';

function FirstRun() {
    const dispatch = useDispatch();
    const login_token = localStorage.getItem('login_token');

    localStorage.setItem('user_leng_ui', "0")

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState("loading ...");

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(false)
    }, []); // AUTOLOAD

    useEffect(() => {
        fetch('https://www.nebo.inupline.com/api/web/login/getts', {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'inLine ' + "234234fe23423ewfedf244132@3aws23",
            },
        })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
            //console.log("TS", response)
            setContent(response.app.ts)
                if (response) {
                    //console.log("OK ts-" , response)
                    //navigate("/");
                } else {
                    console.log("KO ts-" , response)
                    //navigate("/firstrun");
                }  
            } else {
            console.log("Login TS-" , response)
            setLoading(false)
            }
        }); }, []); // AUTOLOAD

    function Accept() {
        fetch('https://www.nebo.inupline.com/api/web/login/getts', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
            },
        })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
            console.log("TS", response)
            dispatch(actUser.setTS(response.ts_accept_v));

                if (response) {
                    console.log("OK ts-" , response)
                    navigate("/");
                } else {
                    console.log("KO ts-" , response)
                    navigate("/firstrun");
                }  
            } else {
            console.log("Login TS-" , response)
            }
        });
    }

    function OutME() {
        // setUser({});
        // googleLogout();
        dispatch(actUser.setUserEmail(""));
        dispatch(actUser.setUserPicture(""));
        dispatch(actUser.setFamilyName(""));
        dispatch(actUser.setGivenName(""));
        dispatch(actUser.setName(""));
        dispatch(actUser.setLoginToken(""));

        localStorage.setItem('usremail', "")
        localStorage.setItem('usrpicture', "")
        localStorage.setItem('usrfamily_name', "")
        localStorage.setItem('usrgiven_name', "")
        localStorage.setItem('usrname', "")
        localStorage.setItem('login_token', "")

        console.log("OutME")
        // navigate("oauth2", {replace: true});
      }

    if (loading){
        return (<LoadingUI tcapt = "Loading"></LoadingUI>)
    } else {
        return (
        <div className="container" >
            {/* <Cards homepage = {pagejson.homepage} appid= {props.InfoPage}/> */}
            {/* {pagejson ? <Cards homepage = {pagejson.homepage} appid= {props.InfoPage}/> : <div>LOAD</div> } */}
            {/* <div className='policy'> © 2023 - Restaurant Brands Iberia</div> */}
        
            <div className='FirstRunText' style={{
                        position: "absolute",
                        padding: "16px",
                        overflowy: "scroll", 
                        height: "80%", //"CALC(80vh - 50vh)"
                        // border: "1px solid #dce2e5",
                        borderradius: "4px"
                    }} >
                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                        <div className="aButtonOpen" style={{display: "inline-block", width: "30%"}} onClick = {Accept}>ACEPTAR</div>
                        <div className="aButtonOpen" style={{display: "inline-block", width: "30%"}} onClick = {OutME}>Login out</div>
                    </div>

                    {/* <div>
                        <input type="checkbox" id="check1" name="check" style={{display: "inline-block"}}/>
                        <label for="check1">ACEPTAR</label>
                        <input type="checkbox" id="check2" name="check" style={{display: "inline-block"}}/>
                        <label for="check2">Enviar una copia a mi correo</label>
                    </div> */}

                    {/* <div className="aButtonOpen" style={{display: "inline-block", width: "30%"}} >ACEPTAR</div> */}
                    
            
            {/* <div className="card-input">
                <label 
                    style={{
                        fontsize: "14px",
                        marginbottom: "5px",
                        fontweight: "500",
                        color: "#1a3b5d",
                        width: "100%",
                        display: "block",
                        userselect: "none"
                    }}
                    htmlFor="cardName" className="card-input__label">Email</label>
                <input
                    style={{
                    padding: "5px 15px 5px 15px ",
                    width:"35%"
                    }}
                        type="text"
                        value={usremail}
                        placeholder="Texto en uk-UA"
                        onChange={(e) => "email"}
                        />            
            </div> */}
        </div>
        );
    }
}

export default FirstRun;
