import React , { useRef, useState , useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tmpimg from '../../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png

import MDEditor, {
    commands,
    ICommand,
    EditorContext
  } from "@uiw/react-md-editor";

  const AudioPlayer = ({ audioUrl, playAudio }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = React.createRef(null);
  
    const togglePlay = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (isPlaying === false) {
            audioRef.current.pause();
          } else {
            audioRef.current.play();
          }
          setIsPlaying(!isPlaying);    
    }, [playAudio]); // AUTOLOAD

  
    return (
      <div className='BTNplay'>
        {/* controls */}
        <audio id="player"  ref={audioRef}  >   
            <source src={audioUrl} type="audio/mp3"/> 
        </audio>

        <button onClick={togglePlay}>{isPlaying ? <i className="fa fa-play" aria-hidden="true"></i> : <i className="fa fa-pause" aria-hidden="true"></i>}</button>
      </div>
    );
  };

  const cleartext = (html) => {
    const htmlText = html
    // Створення тимчасового DOM-елемента
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlText;

    // Отримання чистого тексту без HTML-тегів
    const plainText = tempElement.innerText || tempElement.textContent;
    return plainText
};

export function LevelCard_box(props) {
    //WELCOM PAGE
    const [isPlaying, setIsPlaying] = useState(false);

    const [imgIco, setImgIco] = useState();

    const [card, setCard] = useState();
    const user_id = useSelector((state)=> state.counter.user.user_id);


    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    useEffect(() => {
        setImgIco(tmpimg)     
        setCard(props.card)
    }, [props.card]); // AUTOLOAD

    const clearhtml = (html) => {
        const htmlText = html
        // Регулярний вираз для виділення HTML-тегів
        const regex = /<[^>]*>/g;
        // Знаходження усіх тегів в рядку
        const htmlTags = htmlText.match(regex);
        return htmlTags
    };

    const cleartext = (html) => {
        const htmlText = html
        // Створення тимчасового DOM-елемента
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlText;

        // Отримання чистого тексту без HTML-тегів
        const plainText = tempElement.innerText || tempElement.textContent;
        return plainText
    };

    return (

        <div id = {"Card_" + props.ids} className="card_list" style={{ backgroundColor: "#fff", borderLeft: props.mydeck.colotb + " solid  5px"}}>
            <div className="LabelID" style={{ color: props.mydeck.colotb}}>{props.card[0].a}</div>
            <div className="LabelNumber" style={{ color: props.mydeck.colotb}}>{props.keys +1}</div>
            {/* <div className="lNote">{props.card[0].b}</div> */}
            {/* <div className="SideA" dangerouslySetInnerHTML={{ __html: props.card[0].b }} />  */}
            <div className="SideA" > {<MDEditor.Markdown source={props.card[0].b} />} </div>
            <div className="SideB" > {<MDEditor.Markdown source={props.card[0].c} />}</div>
            {props.card[0].d &&  <AudioPlayer audioUrl={props.card[0].d} playAudio={isPlaying}/>}
                {props.card[0].lv == 0 && <div className="task__levelCard task__levelCard--0" > DISABLED </div> }
                {props.card[0].lv == 1 && <div className="task__levelCard task__levelCard--1" > Lv. BASIC </div> }
                {props.card[0].lv == 2 && <div className="task__levelCard task__levelCard--2" > Lv. MIDDLE </div> }
                {props.card[0].lv == 3 && <div className="task__levelCard task__levelCard--3" > Lv. ADVANCED </div> }
                {props.card[0].lv == 4 && <div className="task__levelCard task__levelCard--4" > Lv. NATIVE </div> }
            <div className="toolspanel">
                {/* <h1 className='title'>Actions</h1>
                <p className='label'>Робіть усе з даною карткою що заманеться...</p> */}
                <div className='btnbox'>
                    <div className="aButtonCardActions" onClick={() => setIsPlaying(!isPlaying) }> {isPlaying ? <i className="fa fa-pause" aria-hidden="true"></i> : <i className="fa fa-play" aria-hidden="true"></i>}</div>
                </div>
                <div className='btnboxR'>
                    {/* <div className="aButtonCardActions"><i className="fa fa-trash" aria-hidden="true"></i></div> */}
                    <div className="aButtonCardActions" onClick={openLinkURL}><i className="fa fa-pencil-square-o" aria-hidden="true"></i></div>                    
                </div>
            </div>{/* <img className= "lLogo" src={!props.imgs ? imgIco : "/img/" + props.imgs} alt="logo "/> */}
        </div>
    );
}

export function LevelCard_List(props) {
  const [selectedItem, setSelectedItem] = useState(null); // Estado para almacenar el elemento seleccionado

  const openLinkURL = (ids, index) => {
      props.selid (ids)
      setSelectedItem(index); // Establece el elemento seleccionado
      console.log("selectedItem", index)
  }

  useEffect(() => {
      // setImgIco(tmpimg)     
      // setCard(props.card)
  }, []); // AUTOLOAD


  return (
      <ul className="grid_invite_user">
              <li className="lisinmarker" >
                    <figure className="listcards" >
                      <span className="linenumero cb_0">N</span>
                      <span className="reference cb_0 ">REF</span>
                      <span className="type cb_0 ">LVL</span>
                      <span className="datatime cb_0">TITLE</span>
                  </figure>
              </li>
            {props.data && props.data.length > 0 ? props.data.map((itemg, index) => ( 
              <li key ={index} className={`lisinmarker ${selectedItem === index ? 'active' : ''}`}>
              <figure className="listcards" onClick={() => openLinkURL(itemg.id, index)} >
                  <span className="linenumero">{index +1}</span>
                  <span className="reference"> &nbsp;&nbsp;&nbsp;{itemg.card[0].a}</span>
                  {itemg.card[0].lv == 0 && <span className="type task__levelCard--0">DISABLED</span> }
                  {itemg.card[0].lv == 1 && <span className="type task__levelCard--1">BASIC</span> }
                  {itemg.card[0].lv == 2 && <span className="type task__levelCard--2">MIDDLE</span> }
                  {itemg.card[0].lv == 3 && <span className="type task__levelCard--3">ADVANCED</span> }
                  {itemg.card[0].lv == 4 && <span className="type task__levelCard--4">NATIVE</span> }
                      {/* <span className="type cb_4"><i className="fa fa-circle" aria-hidden="true"></i>&nbsp;{props.card[0].lv}</span> */}
                  <span className="datatime">{cleartext(itemg.card[0].b)}</span>
              </figure>
          </li>
                )) :  <div>На даний момент ця колода пуста. Ви можете створювати картку за карткою натискаючи <i className="fa fa-plus-square-o" aria-hidden="true"></i> , або імпортувати з програми ANKI <i className="fa fa-upload" aria-hidden="true"></i></div>
            }
        </ul>
  );
}