import { createSlice } from '@reduxjs/toolkit'; 

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Añade un 0 si el mes es de un solo dígito
  const day = String(now.getDate()).padStart(2, '0'); // Añade un 0 si el día es de un solo dígito
  const hours = String(now.getHours()).padStart(2, '0'); // Añade un 0 si la hora es de un solo dígito
  const minutes = String(now.getMinutes()).padStart(2, '0'); // Añade un 0 si los minutos son de un solo dígito
  const seconds = String(now.getSeconds()).padStart(2, '0'); // Añade un 0 si los segundos son de un solo dígito


const accountUser = createSlice ({
    name: 'account',
    initialState : { 
      findtxt:'',
      app: {
        id: 0,
        app: 'RBI Home',
        subid: '0',
        projectid: '',
        projectname: '',
        sidemenu: '0',
        page: false,
        update: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
        updatelogo: '',
        updatefondo: '',
        classname: '',
        ankicards: '',
      }
    },
    reducers: {
      setAppID: (state, action) => {
        state.app.subid = action.payload;
      },
      setProjectID: (state, action) => {
        state.app.projectid = action.payload;
      },
      setSideMenu: (state, action) => {
        state.app.sidemenu = action.payload;
      },
      setFindText: (state, action) => {
        state.findtxt = action.payload;
      },
      setActivePage: (state, action) => {
        state.app.page = action.payload;
      },
      setUpdateLogo: (state, action) => {
        state.app.updatelogo = action.payload;
      },
      setUpdateFondo: (state, action) => {
        state.app.updatefondo = action.payload;
      },
      setUpdateTime: (state, action) => {
        state.app.update = action.payload;
      },
      setProjectName: (state, action) => {
        state.app.projectname = action.payload;
      },
      setClassName: (state, action) => {
        state.app.classname = action.payload;
      },
      setAnkiCards: (state, action) => {
        state.app.ankicards = action.payload;
      }

      
    }
})


export default accountUser;

