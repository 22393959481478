import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';

import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from '../../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png

function Index(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    //const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    //const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    const login_userid = localStorage.getItem('userid');
    const usrname = localStorage.getItem('usrname');

    const [userThemaList, setUserThemaList] = useState([]); // IMPORT USER

    //const seldeckid = localStorage.getItem('seldeckid');
    const deck_id = localStorage.getItem('deckid');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null); // Estado para almacenar el elemento seleccionado
    const [preguntas, setPreguntas] = useState([])
    const [val_nextDeckID, setNextDeckID] = useState("0");
    const [val_minPoint, setMinPoint] = useState("0");

    useEffect(() => {
      //setLoading(false)
      //allStorage()
      GetUserThemaList();
      GetExamen();
      //console.log("deck_id" , deck_id)
    }, []);


    const GetUserThemaList = () => {
      const login_token = localStorage.getItem('login_token');
      const deck_id = localStorage.getItem('deckid');

      fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + deck_id, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            setUserThemaList(response.deck[0]);
            setSelectedFiles(response.files);
            console.log("getDeck" , response)
          }
      });
    };

    function OutME(event) {
      navigate(-1); // /invite
    }

    const GetExamen = () => {
      const login_token = localStorage.getItem('login_token');
      const deck_id = localStorage.getItem('deckid');
      fetch('https://www.nebo.inupline.com/api/web/school/getExamen/' + deck_id, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            const parsedExamen = JSON.parse(response.examen);
            if(parsedExamen) { 
              setPreguntas(parsedExamen);
              setNextDeckID(response.nextID);
              setMinPoint(response.minPoint);
            }
            console.log("getExamen ", response)
          }
      });
    };
    

    const renderSelectedFiles = () => {
      return selectedFiles.map((file, index) => (
          <li key={index} className={`lisinmarker ${selectedItem === index ? 'active' : ''}`} >
              <figure className="userlist" >
                  <div className='linenumero'> {index +1} </div>
                  <div className='reference'>{file.name}</div>
                  {/* <div className='delfile'><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
          </figure>
        </li>
      ));
    };

    return (
        <div>
          <div className='appinfopanel'>
            <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
          </div>
          <div id = "ANKI_MODE" className="ank_main_run" >
              <div className="userThemaslist" >
                {/* {userThemaList.map((itemg, index) => (  */}
                    <DecksCard  
                    // key = {index} 
                    id={userThemaList.id} 
                    deckid={userThemaList.deckid} 
                    title={userThemaList.title} 
                    label = {userThemaList.label}  
                    note = {userThemaList.note}  
                    cs={userThemaList.title} 
                    cards = {userThemaList.cards} 
                    ico={userThemaList.ico} 
                    colort={userThemaList.colort}
                    colotb={userThemaList.colotb}
                    status={userThemaList.status}/>
                {/* ))} */}
            </div>
              <div>
                <h3>File list:</h3>
                <ul className='uploadfilelist'>
                {renderSelectedFiles()}
                </ul>
            </div>
            
            <TestList preguntas={preguntas}/>
          </div>
        </div>
    );
}

function CardProject(props) {
    const navigate = useNavigate();
  
    const handleOpenProject = (id) => {
      //navigate("/class/" + id);
    }
  
    const handleloadurl = (id, dateupdate) => {
      const cacheBuster = new Date(dateupdate).getTime(); // Перетворіть в мілісекунди
      return `https://www.nebo.inupline.com/img/projects/${id}/fondo.jpeg?cache=${cacheBuster}`;
    }
    
    useEffect(() => {
        console.log("props.obj", props.obj)
    }, [props]); // AUTOLOAD
  
    return (
        <div className="index_card_project_list" 
        style={{backgroundImage: `url(${handleloadurl(props.obj.project_id,props.obj.update )})`, backgroundSize: 'cover' }}
        onClick={() => handleOpenProject(props.obj.project_id)}>
            <div className="transbox"></div>
            <div className="numID">{props.obj.project_id}</div>
            <div className="note">{props.obj.project_note }</div>
            <div className="title">{props.obj.project_name}</div>

            <NewIco id={props.obj.project_id} update={"items.update"}/>
        </div>
    );
  }

  function NewIco(props) {
    const [pid, setPid] = useState(props.id);
    //const randomCacheBuster = Math.floor(Math.random() * 10000);
    const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди
    const [defaultPhoto, setDefaultPhoto] = useState(true);
    const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + props.id + `/logo.png?cache=${cacheBuster}`);
  
    //setPid(id);
    return (
      <img className="prlogo" src={selectedPhoto} alt="Avatar" />
    )
  } 

export default Index;

export function DecksCard(props) {
  //WELCOM PAGE
  const [imgIco, setImgIco] = useState();

  const openLinkURL = (id) => {
      //props.selid (id)
      console.log(id)
  }

  useEffect(() => {
      setImgIco(tmpimg)     
  }, []); // AUTOLOAD

  return (
      <div id = {"Decks_" + props.id} className={"user_thema "} onClick={props.status === "LOCKED" ? null : () => openLinkURL(props.id)} style={props.status === "LOCKED" ? {opacity: "0.5",backgroundColor: "#808080"} : { backgroundColor: props.colotb}}>
              <img className= "userThemaLogo" src={star_anki} alt="dcLogo"/>
              {/* <img className= "dcimg" src={deck} alt="logo "/> */}
              <div className="dclbl01">ID: {props.id}</div>
              {/* <div className="dclbl02">CARDS: {props.cards}</div> */}
              <div className="dclbl03" style={{ color: props.colort}} >{props.label}</div>
              <div className="dclbl04" style={{ color: props.colort}} >{props.title}</div>
              <div className="dclbl05" style={{ color: props.colort}} >{props.note}</div>
              {props.status === "LOCKED" ? <div className="dclbl06">LOCKED</div> : null}
          {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
      </div>
  );
}



const TestList = ({preguntas}) => {
  const [respuestas, setRespuestas] = useState(new Array(preguntas.length).fill(null));
const [finalizado, setFinalizado] = useState(false);

const seleccionarRespuesta = (indexPregunta, indexRespuesta) => {
  const nuevasRespuestas = [...respuestas];
  nuevasRespuestas[indexPregunta] = indexRespuesta;
  setRespuestas(nuevasRespuestas);
};

const calcularPuntaje = () => {
  let puntaje = 0;
  for (let i = 0; i < preguntas.length; i++) {
    if (respuestas[i] === preguntas[i].respuestaCorrecta) {
      puntaje += preguntas[i].puntos;
    }
  }
  return puntaje;
};

const finalizarExamen = () => {
  setFinalizado(true);
};

const repetirExamen = () => {
  setRespuestas(new Array(preguntas.length).fill(null));
  setFinalizado(false);
};

const verMisRespuestas = () => {
  alert("Tus respuestas:\n" + respuestas.map((respuesta, index) => `Pregunta ${index + 1}: ${preguntas[index].respuestas[respuesta]}`).join("\n"));
};


  return (
    <div className='form_upload'>
      <h2>Preview:</h2>
    {preguntas.map((pregunta, index) => (
      <div key={index}>
        <p>{pregunta.pregunta}</p>
        {pregunta.respuestas.map((opcion, indexOpcion) => {
          const esRespuestaSeleccionada = respuestas[index] === indexOpcion;
          const esRespuestaCorrecta = indexOpcion === pregunta.respuestaCorrecta;
          return (
            <div key={indexOpcion}>
              <input
                type="radio"
                id={`opcion-${index}-${indexOpcion}`}
                name={`pregunta-${index}`}
                value={indexOpcion}
                checked={esRespuestaSeleccionada}
                onChange={() => seleccionarRespuesta(index, indexOpcion)}
                disabled={finalizado}
              />
              <label htmlFor={`opcion-${index}-${indexOpcion}`}>
                {esRespuestaSeleccionada ? <b>{opcion}</b> : opcion}
              </label>
              {finalizado && esRespuestaSeleccionada && (
                <span style={{ color: esRespuestaCorrecta ? 'green' : 'red' }}>
                  {esRespuestaCorrecta ? ' (Correcta)' : ' (Incorrecta)'}
                </span>
              )}
            </div>
          );
        })}
        {finalizado && (
          <p style={{border: "2px dashed rgb(204, 204, 204)", borderRadius: "5px", padding: "20px"}}>{pregunta.explicacion}</p>
        )}
      </div>
    ))}
    {!finalizado && (
      <button onClick={finalizarExamen}>Finalizar</button>
    )}
    {finalizado && (
      <>
        <p>Puntaje obtenido: {calcularPuntaje()}</p>
        <button onClick={repetirExamen}>Repetir</button>
      </>
    )}
              <button onClick={verMisRespuestas}>Ver mis respuestas</button>

    </div>
  );
};