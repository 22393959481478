import './style_loading.css';

function LoadingUI({tcapt}) {

  return (
    <div>
        <div id="page-preloader">
            <div className="ring"> {tcapt}
                <span className = "ld"></span>
            </div>
        </div>
    </div>
);

}
  
export function LoadingUI2({tcapt}) {

    return (
      <div>
          <div>
              <div className="ring"> {tcapt}
                  <span className = "ld"></span>
              </div>
          </div>
      </div>
  );
  
  }

export default LoadingUI;
