import React , { useEffect,useState } from 'react';

// import IcoHome from '../../img/home.png';  //sadmin.png
// import IcoJSON from '../../img/json.png';  //sadmin.png

import { useNavigate} from "react-router-dom";  
import { useParams  } from "react-router-dom"; 

function LeftSideMenu(props) {

    return (
        <div className="LSmenuhide" id="LSMenu">
                
            <div className = 'LSMenuBox'>
                <GroupBox array = {props.array} />
            </div>
        </div>
    )
};

function GroupBox(props) {

    const [count, setCount] = useState([false,true,false,false,false,false,false,false,false,false]);
    const [isActiveGroup, setActiveGroup] = useState("");
    const [isActivePage, setActivePage] = useState("");
    const [isListIndex, setListIndex] = useState("");
    const { cr, pr, gr, id } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const [isCompany, setCompany] = useState("");
    const [isProject, setProject] = useState("");

    let gname = [];
    const navigate = useNavigate();
    const [openId, setOpenId] = useState('');

    props.array.catalog.map((item, index) => (
        gname.push(item.group)
    ))

    const dataArr = new Set(gname);
    let result = [...dataArr];
  
    const handleClick = (index,company, project, group, title, i, page) => {
      //event.preventDefault();
      const collap = count; 
      collap[index] = false;
      setCount(collap);
      var linker = "/" + company + "/" + project.replace(" ","") + "/" + group + "/" + title
      //console.log(linker);
      //navigate(page, {replace: true});

      navigate(linker, {replace: true});

    //   setCompany(company);
    //   setProject(project);
      setActivePage(title);
      setActiveGroup(group);
      setListIndex(i);

      const oldClassName = document.getElementById('LSMenu').className;
        const newClassName = oldClassName === 'LSmenuhide' ? 'LSmenuhide' : 'LSmenuhide'
        document.getElementById('LSMenu').className=newClassName;

    }

    useEffect(() => {
      setCompany(props.array.company);
      setProject(props.array.project);
      if (id){ setActivePage(id);}
      if (gr){ setActiveGroup(gr);}
    }, [props]); // AUTOLOAD

    const handleTrigger = (index) => {
      const collap = count; 
      collap[index] = !collap[index];
      setCount(collap)
      //console.log(count)
      //navigate(page, {replace: true});
    }



    // useEffect(() => {
    //   var coll = document.getElementsByClassName("collapsible");
    //   var i;
      
    //   for (i = 0; i < coll.length; i++) {
    //     coll[i].addEventListener("click", function() {
    //       this.classList.toggle("active");
    //       var content = this.nextElementSibling;
    //       if (content.style.display === "block") {
    //         content.style.display = "none";
    //       } else {
    //         content.style.display = "block";
    //       }
    //     });
    //   }
    // }, []); // AUTOLOAD - 

    return (
      result.map((itemg, index) => ( 
        <details key = {index} open={count[index] === true} onClick={() => handleTrigger(index)} className = 'category_list_key'>      
          <summary>{itemg}</summary>

          <div id={'EditGroup'+index} className="editgroup"> Edit </div>
          
          {props.array.catalog.map((itemp, i) => (
            itemp.group === itemg && <div onClick={() => handleClick(index, props.array.company, props.array.project, itemp.group,itemp.title, i, itemp.page)} className = {isListIndex==i ? 'list_key_active' : 'list_key'} key={i}>
              {itemp.title}

                <div id={'EditList'+index} className="editlist"> Edit </div>

              </div>       
            // itemp.group === itemg && <button onClick={() => handleData(itemp.page)}  className = 'list_key' key={index} to={itemp.page}>{itemp.title}</button>  
            // itemp.group === itemg && <Link  className = 'list_key' key={index} to={itemp.page}>{itemp.title}</Link>       
         ))}
        </details>
      ))

    )
  }

export default LeftSideMenu;