import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';
import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import {DecksCard} from "../school"
import {useApiData} from "../../functions/useApiData"

export default function Index(props) {
  const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
  const [notification, setNotification] = useState([]); // IMPORT USER
  const [newmsg, setNewMsg] = useState(0); // IMPORT USER
  const user_leng_ui = localStorage.getItem('user_leng_ui');

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [userprojects, setProjects] = useState([]); // IMPORT USER
  // const [userprojoined, setProjoined] = useState([]); // IMPORT USER
  // const [userInSchool, setInSchool] = useState([]); // IMPORT USER
  // const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');
  
  // const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
  // const [SchoolGroup, setSchoolGroup] = useState([]); // IMPORT Group / Course
  // const [SchoolTitle, setSchoolTitle] = useState([]); // IMPORT Group / Course
  // const [SchoolDecks, setDecks] = useState([]); // IMPORT CLASS
  // const [selectedID, setSelectedID] = useState(null);
  // const [SchoolDeck, setDeck] = useState([]); // IMPORT DECKS

  // const usremail = localStorage.getItem('usremail');
  // const usrpicture = localStorage.getItem('usrpicture');
  // const token_device = localStorage.getItem('token_device');
  // const login_token = localStorage.getItem('login_token');
  // const usrname = localStorage.getItem('usrname');
  const apiEndpointClass = '/msg/newlist'; 
  const { data: NewNotificaciones, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);

  useEffect(() => { 
    //console.log ("getDesktop data", ClassDesktop)
    if (NewNotificaciones.notification && NewNotificaciones.notification.length > 0) {
        setNotification(NewNotificaciones.notification)
        // NewNotificaciones.notification
        setNewMsg(NewNotificaciones.countmsg)
        console.log("NewNotificaciones",NewNotificaciones)
    }

}, [NewNotificaciones]); // AUTOLOAD

  function OutME(event) {
    navigate(-1); // /invite
  }

  function openMSG(typeId, inviteid) {
    if(typeId == 1) { navigate(`/invite/${inviteid}`)} // /invite
  }


  
    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            {/* <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div> */}
            <div className='appinfopanel'>
              <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
              <div className="tHello" >
                {user_leng_ui === "0" && "notifications " + newmsg }
                {user_leng_ui === "1" && "notificaciones "+ newmsg}
                {user_leng_ui === "2" && "сповіщення "+ newmsg}
              </div>

              {/* <div className="txt_label_N1" style = {{color:'white', paddingLeft: "15px"}}>
                {user_leng_ui === "0" && "You have " + newmsg + " notifications:"}
                {user_leng_ui === "1" && "Tiene " + newmsg + " notificaciones:"}
                {user_leng_ui === "2" && "У Вас " + newmsg + " сповіщення:"}
              </div> */}
            </div>

            {/* <div className='icon_back'><i className="fa fa-bell-o" aria-hidden="true"></i></div>
            <div className='icon_back'><i className="fa fa-user-o" aria-hidden="true"></i></div> */}

            {/* <div className="aHello" >Hola</div> */}
            {/* <div className="aName" >{usrname}</div> */}
            {/* <div className="aHello" >У Вас {newmsg} сповіщення:</div> */}

            {/* <div className='BoxRang'>
              <div className='InfoChoisClass'>У Вас ще немає ніяких сповіщень</div>
            </div> */}
            

            {notification ? notification.map((items, index) => ( 
            <div key = {index} className="card_notification" onClick={() => openMSG(items.typeid, items.id)}>
              <div className="txt_label_00"><i className="fa fa-envelope-o"></i></div>
              <div className="txt_label_01">{items.msg}</div>
              <div className="txt_label_02">{items.datein}</div>
              <div className="txt_label_03">
                {user_leng_ui === "0" && "from "}
                {user_leng_ui === "1" && "desde "}
                {user_leng_ui === "2" && "від "}
              {items.from_userid}</div>
                <div className="txt_label_04">
                  {user_leng_ui === "0" && "Awaiting a response"}
                  {user_leng_ui === "1" && "En espera de una respuesta"}
                  {user_leng_ui === "2" && "В очікувані відповіді"}
                </div>
              <div className="txt_label_05"><i className="fa fa-envelope-o"></i>
                {user_leng_ui === "0" && "In more detail"}
                {user_leng_ui === "1" && "Más detalladamente"}
                {user_leng_ui === "2" && "Докладніше"} </div>
            </div>)): null}

            
        </div>

        </div>
    );
}