import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';
import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import {DecksCard} from "../school"
import {useApiData} from "../../functions/useApiData"

export default function Index(props) {
  const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const dispatch = useDispatch();

  const user_leng_ui = localStorage.getItem('user_leng_ui');

console.log(user_leng_ui)
  // const [userprojects, setProjects] = useState([]); // IMPORT USER
  // const [userprojoined, setProjoined] = useState([]); // IMPORT USER
  // const [userInSchool, setInSchool] = useState([]); // IMPORT USER
  // const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');
  
  // const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
  // const [SchoolGroup, setSchoolGroup] = useState([]); // IMPORT Group / Course
  // const [SchoolTitle, setSchoolTitle] = useState([]); // IMPORT Group / Course
  // const [SchoolDecks, setDecks] = useState([]); // IMPORT CLASS
  // const [selectedID, setSelectedID] = useState(null);
  // const [SchoolDeck, setDeck] = useState([]); // IMPORT DECKS

  // const usremail = localStorage.getItem('usremail');
  // const usrpicture = localStorage.getItem('usrpicture');
  // const token_device = localStorage.getItem('token_device');
  // const login_token = localStorage.getItem('login_token');
  // const usrname = localStorage.getItem('usrname');

  function BackIn(event) {
    navigate(`/`);
  }

  function OutME(event) {
    dispatch(actUser.setUserEmail(""));
    dispatch(actUser.setUserPicture(""));
    dispatch(actUser.setFamilyName(""));
    dispatch(actUser.setGivenName(""));
    dispatch(actUser.setName(""));
    dispatch(actUser.setLoginToken(""));
  
    localStorage.setItem('usremail', "")
    localStorage.setItem('usrpicture', "")
    localStorage.setItem('usrfamily_name', "")
    localStorage.setItem('usrgiven_name', "")
    localStorage.setItem('usrname', "")
    localStorage.setItem('login_token', "")

    localStorage.setItem('class_all', "") 
    localStorage.setItem('cards_all', "")

    navigate(`/`);
  }

  function OutALL(event) {
    dispatch(actUser.setUserEmail(""));
    dispatch(actUser.setUserPicture(""));
    dispatch(actUser.setFamilyName(""));
    dispatch(actUser.setGivenName(""));
    dispatch(actUser.setName(""));
    dispatch(actUser.setLoginToken(""));
  
    localStorage.clear();
   
    navigate(`/`);
  }
  
    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {BackIn(e)}}></i></div>
            {/* <div className='icon_back'><i className="fa fa-bell-o" aria-hidden="true"></i></div>
            <div className='icon_back'><i className="fa fa-user-o" aria-hidden="true"></i></div> */}

            {/* <div className="aHello" >Hola</div> */}
            {/* <div className="aName" >{usrname}</div> */}
            <div className="aHello">
              {user_leng_ui === "0" && "EXIT"}
              {user_leng_ui === "1" && "SALIDA"}
              {user_leng_ui === "2" && "ВИХІД"}
            </div>

            <div className='BoxRang'>
              <div className='InfoChoisClass'>
                {user_leng_ui === "0" && "Do you really want to exit the program with your user?"}
                {user_leng_ui === "1" && "¿Realmente quieres salir del programa con tu usuario?"}
                {user_leng_ui === "2" && "Ви дійсно хочете вийти із програми своїм користувачем?"}
              </div>
            </div>
              
            <div className="aClassList">
              <div className='ankRun' onClick={(e) => {OutME(e)}}> 
                {user_leng_ui === "0" && "End session"}
                {user_leng_ui === "1" && "Finalizar la sesión"}
                {user_leng_ui === "2" && "Завершити сеанс"}
              </div>
              <div className='ankRun' onClick={(e) => {OutALL(e)}}> 
                {user_leng_ui === "0" && "Exit and delete all data"}
                {user_leng_ui === "1" && "Salir y borrar todos los datos"}
                {user_leng_ui === "2" && "Вийти та видалити усі дані"}
              </div>
            </div>

            
        </div>

        </div>
    );
}