
import React,{ useState } from 'react';

import ButtonMenu from '../button_menu/button_menu';

function UpMenu(props) {     

    // Filtrar elementos con etiqueta personalizada "menu"
    const find_zona = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'find_zona'
    );

    const lmenu = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'lmenu'
    );

    const logo_zona = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'logo_zona'
    );

    const ava_zona = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'ava_zona'
    );

return (
    <div>
        {lmenu}
        {logo_zona}
        {find_zona}
        {ava_zona}
    </div>
    )
};

export default UpMenu;

export const TagZona = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export const TagLMenu = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export const TagLogo = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export const TagRMenu = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export function AppFullScreen(est) {
    var elem = document.documentElement;
    console.log("isFullScreen",est )
    if(est === true) { openFullscreen() } else { closeFullscreen() } 

    function openFullscreen() {
        ///  Fullscreen //////////////////////////
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
            // setFullScreen(false);
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
      
    function closeFullscreen() {
        ///  Fullscreen //////////////////////////
        if (document.exitFullscreen) {
            document.exitFullscreen();
            // setFullScreen(true);
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }
}