import React , { useEffect,useState } from 'react';
import QRCode from 'qrcode.react';
import { useNavigate, useParams} from "react-router-dom";  
import logo from '../../img/temp02.png';  //sadmin.png
import { useSelector, useDispatch } from 'react-redux';
import { actions, application } from '../../redux/store';

function AddURL(props) {
    //const [imgIco, setImgIco] = useState();
    const navigate = useNavigate();
    const [datosCargados, setDatosCargados] = useState(true); // Estado para indicar que los datos están cargados
    const project_id = useSelector((state)=> state.application.app.projectid);
    const login_token = useSelector((state)=> state.counter.user.login_token);
    const appid = useSelector((state)=> state.application.app.subid); //id de icono
    const dispatch = useDispatch();

    const [selURL, setURL] = useState([]);

    const [QRfondo, setQRfondo] = useState('#000000');
    const [QRcolor, setQRcolor] = useState('#FFFFFF');

    const toggleClass3 = () => {
      const oldClassName = document.getElementById('Menu03').className;
      if (oldClassName === 'menu2open') {
        const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
        document.getElementById('Menu03').className=newClassName;
        var workPageNew = "body"
        const workPageOld = document.getElementById('body').className;
        if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
        if (workPageOld === 'body' ) workPageNew = 'bodyright'
        if (workPageOld === 'bodyright' ) workPageNew = 'body'
        if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
        document.getElementById('body').className=workPageNew;
      }
    }
  
    const openLinkApp = (url) => {
      console.log("url",url)
      if (url) {
        if (url.startsWith("http")) {
          window.open(url, "_blank")
        } else {
            navigate(url);
        }
      }
    }
  
    const handleSubmit = (e) => {
      e.preventDefault();

      const userData = {
        projectid: project_id,
        roleid: "0",
        url: e.target.lurl.value,
        title: e.target.title.value,
        note: e.target.note.value,
        ico: "tm.png",
        bcolor: "",
        info: e.target.info.value,
      };

      //dispatch(application.setAppID(misProjects.project_id));

      fetch('https://www.nebo.inupline.com/api/web/project/url', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("APP->URL", response)
            dispatch(application.setUpdateTime(response.update));

          }
      });
    };

    useEffect(() => {
      // GET PROJECT
      //setDatosCargados(false); // Datos cargados con éxito
      console.log('Las props han cambiado:', props.url.id);

      fetch('https://www.nebo.inupline.com/api/web/project/url/' + project_id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
          },
      })
          .then(response => response.json())
          .then(response => {
              if (response.status===200) {
                setURL(response.URLs)
                //setSelTypes(response.projects[0].type_id)
                //setSelCompany(response.projects[0].company_id)

                //setDatosCargados(false); // Datos cargados con éxito
                console.log("URL OK-" , response.URLs)

              } else if (response.status===409) {
                console.log("URL KO-" , response)
              }
          });  
    }, [props]);

    return (
        <div>
            {datosCargados ? ( <div className="form_wrapper">
              <div className="form_container">
                <div className="title_container">
                  <h2>ADD URL / {props.url.id}</h2>
                </div>
                {props.url.id ? null : <div className="aButtonClose" onClick={toggleClass3}>x</div>}

                <div className="row clearfix">
                  <div className="">
                    <form onSubmit={handleSubmit}>

                    <input type="hidden" name="userid" readOnly={"user_id"} />
                    {/* <QRCode className="aLogo" value={"props.UnitInfo.url"} size={150} fgColor={QRcolor} bgColor={QRfondo} level={"H"} imageSettings={{src:"/img/add.png", height: 64, width: 64,excavate:true}}/> */}

                    {/* <PhotoComponent  nameLogo={"imgLogo"} nameFondo={"imgFondo"} pid= {"misProjects.project_id"} /> */}

                      <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                        <input id="title" name="title" type="text" placeholder="Title" value={props.url.title} required/>
                      </div>

                      <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                        <input id="note" name="note" type="text" placeholder="Note" value={props.url.note} required/>
                      </div>

                      <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sticky-note"></i></span>
                        <textarea id="info" name="info" type="text" placeholder="URL info:" value={props.url.info} required/>
                      </div>

                      <div className="input_field"> <span><i aria-hidden="true" className="fa fa-link"></i></span>
                        <input id="lurl" name="lurl" type="text" placeholder="URL:" value={props.url.url} required/>
                      </div>
                          
                          <div className="input_field select_option">
                            <select id="typeid">
                                {/* {misTypes.map((itemg, index) => ( 
                                  <option value={itemg.id} key={index}>{itemg.name}</option>
                                ))} */}
                                <option value={1} key={1}>URL</option>
                                <option value={2} key={2}>App page</option>
                            </select>
                            <div className="select_arrow"></div>
                          </div>

                          <div className="input_field select_option">
                          <select id="code">
                              {/* {misCompany.map((itemg, index) => ( 
                                <option value={itemg.id} key={index}>{itemg.name}</option>
                              ))} */}
                              <option value={"/"} key={1}>HOME</option>
                              <option value={"/anki"} key={2}>ANKI</option>
                          </select>
                            <div className="select_arrow"></div>
                          </div>
                      <input className="button" type="submit" value="SAVE" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            ) : (
              <p>Los datos de misProjects están vacíos. </p>
            )}
        </div>
    );
  }
  
  
export default AddURL;

function PhotoComponent(props) {
  const [defaultPhoto, setDefaultPhoto] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + props.pid + '/logo.png');
  const [defaultFondo, setDefaultFondo] = useState("/img/projects/" + props.pid + '/fondo.png');
  const randomCacheBuster = Math.floor(Math.random() * 10000);


  useEffect(() => {
    const img = new Image();
    img.src = '/img/projects/' + props.pid + '/logo.png';

    img.onload = () => {
      // La imagen existe, no necesitas hacer nada.
      setDefaultPhoto(false);
      setSelectedPhoto('/img/projects/' + props.pid + `/logo.png?cache=${randomCacheBuster}`);
    };

    img.onerror = () => {
      // La imagen no existe, cambia la URL por otra.
      setDefaultPhoto(true);
    };

    
  }, [props.pid]);

  useEffect(() => {
    const img = new Image();
    img.src = '/img/projects/' + props.pid + '/fondo.png';

    img.onload = () => {
      // La imagen existe, no necesitas hacer nada.
      //setDefaultPhoto(false);
      setDefaultFondo('/img/projects/' + props.pid + `/fondo.png?cache=${randomCacheBuster}`);
    };

    img.onerror = () => {
      setDefaultFondo('/img/projects/' + props.pid + `/fondo.jpeg?cache=${randomCacheBuster}`);

      // La imagen no existe, cambia la URL por otra.
      //setDefaultPhoto(true);
    };

    
  }, [props.pid]);


  const handleDefaultPhoto = () => {
    setDefaultPhoto(true);
    setSelectedPhoto(null);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Crear un objeto de imagen para obtener las dimensiones
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = function () {
        const imageSize = file.size; // Tamaño en bytes
        const imageResolution = {
          width: this.width,
          height: this.height,
        };
  
        console.log(`Tamaño: ${imageSize} bytes`);
        console.log(`Resolución: ${imageResolution.width}x${imageResolution.height}`);
  
        // Aquí puedes realizar acciones basadas en el tamaño y la resolución.
  
        setDefaultPhoto(false);
        setSelectedPhoto(URL.createObjectURL(file));
        props.nameLogo(file);
        console.log(defaultFondo);
      };
    }
  };

  const handleFondoInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      //setDefaultPhoto(false);
      setDefaultFondo(URL.createObjectURL(file));
      props.nameFondo(file);
    }
  };

  return (
    <div>
          <div className="pNote" style={{ backgroundImage: `url(${defaultFondo})`, backgroundSize: 'cover' }}>
            <div className="transbox" style={{width:"108%",height:"100%", margin:-10}}>
              <div className="imgButton_load">
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  name="fileInput"
                  id="fileInput"
                />
                <label htmlFor="fileInput" className="zbutton"><i className="fa fa-crosshairs" aria-hidden="true"></i>
              </label>
              
                <div className="zbutton" onClick={handleDefaultPhoto}><i className="fa fa-undo" aria-hidden="true"></i></div>

              </div>
            {defaultPhoto ? (
              <img className="aLogo"
              src={logo} // Замініть на свій шлях до фото за замовчуванням
              alt="Default Photo"
              />
            ) : (
              <img className="aLogo" src={selectedPhoto} alt="Selected Photo" />
            )}
      </div>
      <div>
      </div>


      </div>
    </div>
  );
}