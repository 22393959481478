import React , { useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import tmpimg from './../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import {useApiData} from "../functions/useApiData"
import LoadingUI from '../components/loading/loadingui';
import './style_school.css';
import ImgF003 from './../img/BFimg/F003.jpg';  //sadmin.png avatar.jpeg
import UserAva from './../img/guest_nano.png';  //sadmin.png avatar.jpeg
//import { useSelector, useDispatch } from 'react-redux';

import {DecksCard} from "./school"

import './AccordionItem.css'; // Стилі для елемента

export function GroupsCard(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    //const dispatch = useDispatch();

    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    const iconLink = () => {
        setImgIco(tmpimg)     
    }

    useEffect(() => {
        iconLink()
    }, []); // AUTOLOAD


    return (

        <div id = {"Card_" + props.ids} className={"card_group card-1"} onClick={openLinkURL} style={{ backgroundColor: props.colorb}}>
                {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
                {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
                {/* <img className= "lLogo" src={!props.imgs ? imgIco : "/img/" + props.imgs} alt="logo "/> */}
                <div className="gTitle" style={{ color: props.colort }}>{props.title}</div>
                <div className="gLabel" style={{ color: props.colort }}>lv. {props.level}</div>
                <div className="gLevel" style={{ color: props.colort }}>{props.ids}</div>
                <div className="gTime" style={{ color: props.colort }}>{props.time + "-" + props.timeend}</div>
                <div className="gNameclass"style={{ color: props.colort }}>{props.nameclass}</div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
        </div>
    );
}

export function StudentsCard(props) {
    
    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    return (

        <div id = {"Card_" + props.ids} className={"card_students card-1"} onClick={openLinkURL} style={props.style}>
                {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
                {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
                <img className= "sLogo" src={!props.photo ? UserAva : props.photo} referrerPolicy="no-referrer" alt="logo "/>
                <div className="sTitle">{props.title}</div>
                <div className="sEmail">{props.email}</div>
                {/* <div className="task__levelUser task__levelCard--0" > DISABLED </div> */}

                {props.level == 0 && <div className="task__levelUser task__levelCard--0" > DISABLED </div> }
                {props.level == 1 && <div className="task__levelUser task__levelCard--1" > Lv. BASIC </div> }
                {props.level == 2 && <div className="task__levelUser task__levelCard--2" > Lv. MIDDLE </div> }
                {props.level == 3 && <div className="task__levelUser task__levelCard--3" > Lv. ADVANCED </div> }
                {props.level == 4 && <div className="task__levelUser task__levelCard--4" > Lv. NATIVE </div> }

                <div className="sA1">{props.A1}</div>
                <div className="sA2">{props.A2}</div>
                <div className="sA3">{props.A3}</div>

                <div className="sId">ID: {props.ids}</div>
                <div className="sStatus">Status: {props.status}</div>
                <div className="sLastin">{props.lastin}</div>
                <div className="sDate">{props.startdate}</div>

            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
        </div>
    );
}

function InfoBox(props) {
    //WELCOM PAGE
    // const user_id = useSelector((state)=> state.counter.user.user_id);
    const handleClose = (e) => {
        //cid = null;
        props.close (e)
    };
    //TYPE BOXS
    //1 #0070D2; - norm
    //2 #C23934; - warning
    //3 #39c234; - success
    //4 #ff9113; - pending

    const MINUTE_MS = 3000;

    useEffect(() => {
    const interval = setInterval(() => {
        handleClose(false)
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])


    return (
        <div>
            <div className={"info-panel info-panel--" + props.msg.typeid}>
                <i className="icon fa fa-check-square-o" aria-hidden="true"></i>
                <div className="info-panel__content">
                    <p>{props.msg.title}</p>
                </div>
                <button className="button--icon" onClick={() => handleClose(false)}><i className="fa fa-times" aria-hidden="true"></i></button>
            </div>
        </div>
    );
}


function SchoolClasses(props) {
    const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id

    const class_id = localStorage.getItem('classid');

    //const [SchoolArray, setSchoolArray] = useState([]); // IMPORT Group / Course
    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT className
    const [SchoolGroup, setSchoolGroup] = useState([]); // IMPORT Group / Course
    const [SchoolTitle, setSchoolTitle] = useState([]); // IMPORT Group / Course
    const [SchoolDecks, setDecks] = useState([]); // IMPORT className
    const [SchoolStudents, setStudents] = useState([]); // IMPORT DECKS
    const [SchoolTeachers, setTeachers] = useState([]); // IMPORT DECKS

    const [msgInfoText, setInfoText] = useState({title: "test_title", typeid: 2 });
    const [classData, setClassData] = useState([]); // IMPORT Group / Course
    const [classShowList, setClassShowList] = useState(true); // IMPORT Group / Course

    const [infodeck, setInfodeck] = useState(false);
    const [editdeck, setEditdeck] = useState(false);

    //const [SchoolDecks, setDecks] = useState([]); // IMPORT DECKS

    //const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 3 + '/logo.png');

    const [selectedID, setSelectedID] = useState(null);

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const navigate = useNavigate();
    //const dispatch = useDispatch();

    const apiEndpoint = '/school/getClass/' + class_id;
    const { data: ClassData, loading: loading, error: ClassError } =  useApiData('GET', apiEndpoint);

    const apiEndpointClass = '/school/getDesktop/' + selectedID;
    const { data: ClassDesktop, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);

    //console.log ("selectedID",selectedID)


    useEffect(() => { 
        //setSchoolGroup(ClassData.levels)
        if (ClassData.groups ) {
            setSchoolClass(ClassData.groups)
            console.log ("getClass groups", ClassData)
        }

        if (ClassData.levels ) {
            setSchoolGroup(ClassData.levels)
            console.log ("getClass levels", ClassData)

        }
    }, [ClassData]); // AUTOLOAD

    useEffect(() => { 
        console.log ("getDesktop data", ClassDesktop)
        // if (ClassDesktop && ClassDesktop.length > 0) {

        if (ClassDesktop) {
            setClassData(ClassDesktop)
        }

        if (ClassDesktop.groups ) {
            setSchoolTitle(ClassDesktop.groups)
        }

        if (ClassDesktop.decks ) {
            setDecks(ClassDesktop.decks)
        }

        if (ClassDesktop.users ) {
            setStudents(ClassDesktop.users)
        }

        if (ClassDesktop.teachers ) {
            setTeachers(ClassDesktop.teachers)
        }
        
    }, [ClassDesktop,selectedID]); // AUTOLOAD

    const handleChildValue = (childValue) => {
        console.log ("Активний клас або група:", childValue)

        setSelectedID(childValue);
    };

    const handleHome = (childValue) => {
        //cid = null;
        navigate(-1);
    };

    const handleShowDecks = (childValue) => {
        // open className page
        // const deck_id = localStorage.getItem('deckid');

        localStorage.setItem('deckid', childValue)
        navigate(`/decks`);
    };

    const handleShowMSG = (val) => {
        setInfoText(val)
        setInfodeck(true)
    };

    const handleCloseInfo = (val) => {
        setInfodeck(val)
    };
    
    const handleGetDeck = (id) => {
        // Для оновлення даних в хук, методом дзвінка в апі
    }

    if (loading){
        return (<LoadingUI tcapt = "Loading"></LoadingUI>)
    } else {
        return (
        <div>

            <AccordionItem title={SchoolGroup.title + " " + SchoolClass.length + " "} view={false}> 
                <AcHeader tag="header">
                    {/* <div className="aButtonClassHome" onClick={handleHome}><i className="fa fa-home" aria-hidden="true"></i></div>
                    <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig" onClick={() => setEditdeck(!editdeck)}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                </AcHeader>

                <AcBody tag="body">
                    {infodeck? <InfoBox msg={msgInfoText} close={handleCloseInfo} />: null }
                    {/* {editdeck? <GroupeForm mydeck = {SchoolGroup} pid = {pid} apimsg = {handleShowMSG} uploadid={handleGetDeck}/>: null }  */}

                    <GroupeForm mydeck = {SchoolGroup} pid = {pid} apimsg = {handleShowMSG} uploadid={handleGetDeck}/>
                </AcBody>
            </AccordionItem>

            <AccordionItem title={"CLASSES"} view={classShowList}> 
                <AcHeader tag="header">
                    <div className="aButtonClassHome" onClick={handleHome}><i className="fa fa-home" aria-hidden="true"></i></div>
                    <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                </AcHeader>

                <AcBody tag="body">

                {/* {infodeck? <InfoBox msg={msgInfoText} close={handleCloseInfo} />: null }
                {editdeck? <GroupeForm mydeck = {SchoolGroup} pid = {pid} apimsg = {handleShowMSG} uploadid={handleGetDeck}/>: null }  */}

                    <div className="classgroup" > 
                        {SchoolClass && SchoolClass.map((itemg, index) => ( 
                            <GroupsCard 
                            key = {index} // OK
                            ids={itemg.id} // OK
                            title={itemg.title} // OK - G1 ! => GRUPGROUP.GRUPNAME
                            time = {itemg.time}  // OK ! TMLNTIMELINE.TMLNTIME + 
                            timeend = {itemg.timeend}  // OK ! TMLNTIMELINE.TMLNTIME + 
                            nameclass={itemg.nameclass} // OK ! => TMLNTIMELINE.TMLNCLASS
                            descrclass={itemg.descrclass} // OK ! => GRUPGROUP.GRUPABOUT
                            label = {itemg.label} // Label ? ! => GRUPGROUP.GRUPLABEL
                            level = {itemg.level} // Level ? ! => GRUPGROUP.GRUPLEVEL
                            icon={itemg.icoAva} // OK  ! => GRUPGROUP.GRUPICON
                            colort = {itemg.colort} // Text color ! => GRUPGROUP.GRUPCOLORT
                            colorb = {itemg.colorb} // Fon color ! => GRUPGROUP.GRUPCOLORB
                            fonfile = {itemg.fonfile} // Fon color ! => GRUPGROUP.GRUPCOLORB
                            selid={handleChildValue} // Click Function
                            />
                        ))}
                    </div>          
                </AcBody>
            </AccordionItem>

            
            {selectedID && <div className='classlist'> 
                <div className="classroom_header" style={{padding:`0px`, backgroundSize:`100%`,  background:`url(${ ImgF003 }) center no-repeat`}} >
                    <div className='transbox'> 
                        <div className="Slogo">
                        {/* { (<div className="classlogo" 
                            style={{width: "80px", height: "80px", backgroundColor: "rgb(230, 230, 230)", WebkitMaskImage: `url(${selectedPhoto})`, maskImage: `url(${selectedPhoto})`, WebkitMaskRepeat: "no-repeat", maskRepeat: "no-repeat",WebkitMaskSize:"100%",maskSize:"100%"  }} >
                            </div>
                            )} */}
                        </div>

                        <div className="aButtonSetings" onClick={() => setEditdeck(!editdeck)}><i className="fa fa-cog" aria-hidden="true"></i></div>

                        <div className="aula">
                            {SchoolGroup.title}
                        </div>
                        
                        <div className="ids">
                            {selectedID} 
                        </div>

                        <div className="time">
                            {SchoolTitle.time} - {SchoolTitle.timeend}
                        </div>

                        <div className="classroom">
                            {SchoolTitle.nameclass} 
                        </div>
                    
                        <div className="nivel">
                            {SchoolTitle.label} 
                        </div>

                        <div className="groupclass">
                            {SchoolTitle.title}           
                        </div>

                        <div className="students"  >
                            students           
                        </div>

                        <div className="number"  >
                            {SchoolStudents.length}            
                        </div>
                    </div>
                </div>

                {editdeck? <ClassForm myclass = {SchoolTitle} pid = {SchoolTitle.id} apimsg = {handleShowMSG} uploadid={handleGetDeck}/>: null } 

                <AccordionItem title={"Instructors: " + SchoolTeachers.length } > 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                        <div className="classgroup" >
                            {SchoolTeachers && SchoolTeachers.map((itemg, index) => ( 
                                <StudentsCard 
                                    key = {index} // OK
                                    ids={itemg.id} // OK
                                    title={itemg.name} // OK - G1 ! => GRUPGROUP.GRUPNAME
                                    label = {itemg.label} // Label ? ! => GRUPGROUP.GRUPLABEL
                                    photo={itemg.photo} // OK  ! => GRUPGROUP.GRUPICON
                                    A1 = {itemg.A1} // Text color ! => GRUPGROUP.GRUPCOLORT
                                    A2 = {itemg.A2} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    A3 = {itemg.A3} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    level = {itemg.level}
                                    selid={handleChildValue} // Click Function
                                />
                            ))} 
                        </div>     

                    </AcBody>
                </AccordionItem>

                <AccordionItem title={"Students: " + SchoolStudents.length }> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                        <div className="classgroup" >
                            {SchoolStudents && SchoolStudents.map((itemg, index) => ( 
                                <StudentsCard 
                                    key = {index} // OK
                                    ids={itemg.id} // OK
                                    title={itemg.name} // OK - G1 ! => GRUPGROUP.GRUPNAME
                                    label = {itemg.label} // Label ? ! => GRUPGROUP.GRUPLABEL
                                    photo={itemg.photo} // OK  ! => GRUPGROUP.GRUPICON
                                    A1 = {itemg.A1} // Text color ! => GRUPGROUP.GRUPCOLORT
                                    A2 = {itemg.A2} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    A3 = {itemg.A3} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    //const [level, setLevel] = useState('B2: Intermedio alto');
                                    startdate = {itemg.startdate}
                                    level = {itemg.level}
                                    selid={handleChildValue} // Click Function
                                />
                            ))} 
                        </div>      
                    </AcBody>
                </AccordionItem>
                
                <AccordionItem title={"Temas de estudio: " + SchoolDecks.length }> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                        <div className="classDeckslist" >
                            {SchoolDecks.map((itemg, index) => ( 
                                <DecksCard style={{ backgroundColor: itemg.colorb}} 
                                key = {index} 
                                id={itemg.id} 
                                deckid={itemg.deckid} 
                                title={itemg.title} 
                                label = {itemg.label}  
                                note = {itemg.note}  
                                cs={itemg.title} 
                                cards = {itemg.cards} 
                                ico={itemg.ico} 
                                colort={itemg.colort}
                                selid={handleShowDecks}/>
                            ))}
                        </div>
                    </AcBody>
                </AccordionItem>

                <AccordionItem title="Course program"> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                                            
                    </AcBody>
                </AccordionItem>

                <AccordionItem title="URLs"> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                                            
                    </AcBody>
                </AccordionItem>

                <AccordionItem title="Tools"> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                                            
                    </AcBody>
                </AccordionItem>

                <AccordionItem title="TIMELINE"> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                                             
                    </AcBody>
                </AccordionItem>
                
                <AccordionItem title="HISTORY"> 
                    <AcHeader tag="header">
                        <div className="aButtonClassAdd"><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div>
                    </AcHeader>

                    <AcBody tag="body">
                                             
                    </AcBody>
                </AccordionItem>
    
                {/* <div>
                    {classData.users && <UserComponent users={classData.users[0]} />} 
                    {classData.urls && <UrlComponent urls={classData.urls[0]} />}
                    {classData.deck && <DeckComponent deck={classData.deck[0]} />}
                    {classData.timeline && <TimelineComponent timeline={classData.timeline[0]} />}
                </div> */}
            </div> }
    </div>
    )}
}

export default SchoolClasses;


export const AccordionItem = ( props ) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    useEffect(() => { 
        if (props.view && props.view > 0) {
            setIsExpanded(props.view)
        }
    }, [props.view]); // AUTOLOAD

    const toggleAccordion = () => {
      setIsExpanded(!isExpanded);
    };

    const header = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'header'
    );

    const body = React.Children.toArray(props.children).find(
        (child) => child.props.tag === 'body'
    );
  
    return (
      <div className={`project_urls accordion-item ${isExpanded ? 'expanded' : ''}`}>
        <div className="accordion-header" >
            {isExpanded ? <div className="aButtonClassBack" onClick={toggleAccordion}><i className="fa fa-minus-circle" aria-hidden="true"></i></div> : <div className="aButtonClassBack" onClick={toggleAccordion}><i className="fa fa-plus-circle" aria-hidden="true"></i></div>}
            <div className="cLabelClassHeaderBar" onClick={toggleAccordion}>{props.title}</div>
            {header}
        </div>
        
        {isExpanded && <div className="accordion-content">{body}</div>}
      </div>
    );
  };

export const AcHeader = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};

export const AcBody = ({ tag, children }) => {
    return <div tag={tag}>{children}</div>;
};
  

function GroupeForm(props) {
    // Форма редагування групи (курсу)
    // Estados para almacenar los valores de los campos
    const [classID, setGiD] = useState(null);
    const [title, setTitle] = useState('GROUP_NAME');
    const [level, setLevel] = useState(0);
    const [flag, setFlag] = useState(236);
    const [note, setNote] = useState('note');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#d3dbd5'); // Valor inicial del color en negro
    //const [note, setNote] = useState('A2+');

    // const [alphaT, setAlphaT] = useState('0.1'); // Valor inicial del color en negro
    // const [alphaB, setlphaB] = useState('0.1'); // Valor inicial del color en negro
    const navigate = useNavigate();

    const iconsContext = require.context('./../img/flags/', false, /\.svg$/);
    const svgFiles = iconsContext.keys();

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');
    const activeProjectId = localStorage.getItem('projectid');

    useEffect(() => {
        setGiD(props.mydeck.id)
        setTitle(props.mydeck.title)     
        setLevel(props.mydeck.level)
        setFlag(props.mydeck.icoAva)
        setNote(props.mydeck.note)
        setColorT(props.mydeck.colort)
        setColorB(props.mydeck.colorb)
    }, [props]); // AUTOLOAD

    const [langArray, setLangArray] = useState([]);

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setTitle(e.target.value);
    };

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    const handleflagChange = (e) => {
        setFlag(e.target.value);
    };

    const handleLNGNameChange = (e) => {
        setNote(e.target.value);
    };

    const handleColorTChange = (e) => {
        setColorT(e.target.value);
    };

    const handleColorBChange = (e) => {
        setColorB(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('title:', title);
        console.log('level:', level);
        console.log('icoAva:', flag);
        console.log('colort:', colorT); 
        console.log('colorb:', colorB);
        console.log('note:', note);

        console.log('login_token:', login_token);
        console.log('projectid:', activeProjectId);

        const userData = {
            projectid: activeProjectId,
            title: title,
            level: level,
            icoAva: flag,
            colort: colorT,
            colorb: colorB,
            note: note,
        };

        fetch('https://www.nebo.inupline.com/api/web/school/postAddClass/' + classID, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddClass", response)
            navigate(`/projects`)
          } else {
            console.log("ERROR postAddClass-" , response)
        }
      });

    };

      function rgbaToHex(rgbaColor) {
        const match = rgbaColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
      
        if (!match) {
        //   throw new Error('Invalid RGBA color format');
        }
      
        const [r, g, b, a] = match;
      
        // Конвертувати значення кожного каналу в шістнадцятковий формат
        const hexR = (+r).toString(16).padStart(2, '0');
        const hexG = (+g).toString(16).padStart(2, '0');
        const hexB = (+b).toString(16).padStart(2, '0');
        const hexA = Math.round(+a * 255).toString(16).padStart(2, '0');
      
        // Об'єднати значення кольору та альфа-каналу
        // const hexColor = `#${hexR}${hexG}${hexB}${hexA}`;
        const hexColor = `#${hexR}${hexG}${hexB}`;

        return hexColor;
      }


      useEffect(() => {
        const options = document.querySelectorAll('.vodiapicker option');
        const updatedLangArray = [];
    
        options.forEach(option => {
          const img = option.getAttribute('data-thumbnail');
          const text = option.innerText;
          const value = option.value;
    
          const item = (
            <li key={value}>
              <img src={img} alt="" value={value} />
              <span>{text}</span>
            </li>
          );
    
          updatedLangArray.push(item);
        });
    
        setLangArray(updatedLangArray);
      }, []);


    return (
        <div className="form_0">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />
                
                        <label className="desc" id="title_l" for="title">
                            Назва предмету або групи:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="title" name="title" type="text" placeholder="NAME" value={title} required onChange={handleNameChange}/>
                        </div>

                        <label className="desc" id="typeid_l" for="typeid">
                            Рівні складності:
                        </label>
                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={""} key={0}>All Levels</option>
                                <option value={"1"} key={1}>Introductory Level</option>
                                <option value={"2"} key={2}>Basic Level</option>
                                <option value={"3"} key={3}>Intermediate Level</option>
                                <option value={"4"} key={4}>Advanced Level</option>
                                <option value={"5"} key={5}>Expert Level</option>
                            </select>
                            <div className="select_arrow"></div>
                        </div>

                        
                        <label className="desc" id="typeid_l" for="typeid">
                            Іконка або логотип:
                        </label>
                        <div className="input_field select_option">
                            <select value={flag} onChange={handleflagChange}>
                                {svgFiles.map((filename, index) => (
                                    <option key={index} value={index}>
                                        <img src={iconsContext(filename)} alt={filename} /><span>{filename}</span>
                                    </option>
                                ))}
                            </select>
                            <div className="select_arrow"></div>
                        </div>

                        <label className="desc" id="typeid_l" for="typeid">
                            Короткий опис:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={note} onChange={handleLNGNameChange}/>
                        </div>

                        <div className="input_field" > 
                            <label className="desc" id="typeid_l" for="lurl">
                                Колір тексту 
                            </label>
                            <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorT} required onChange={handleColorTChange}/>
                            <label className="desc" id="typeid_l" for="lurl">
                                Колір фону 
                            </label>
                            <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div>

                        {/* <div className="input_field"> 
                            Back color <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div> */}

                        <input className="button" type="submit" value="UPDATE"/>
                    </form>


                    <div style={{top: "0px", left: "500px", position: "absolute", width: "280px", color:"#b3b3b3", padding: "15px"}}>
                        PREVIEW
                        <div id = {"Group_" + props.ids} className={"card3 "}  style={{ backgroundColor: colorB, margin: "15px"}}>
                            <img className= "dcFlagLogo" src={"https://www.nebo.inupline.com/" + iconsContext(svgFiles[flag])} alt="dcFlagLogo"/>
                            {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                            <div className="dclbl01">CLASESS: 0</div>
                            <div className="dclbl02">STUDENTS: {flag}</div>
                            {/* <div className="dclbl03b" style={{ color: props.colort}} >{props.label}</div> */}
                            <div className="dclbl04b" style={{ color: colorT}} >{title}</div>
                            <div className="dclbl05b" style={{ color: colorT}} >{note}</div>
                            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
                        </div>
                    </div>
              </div> 
    );
}


function ClassForm(props) {
    // Форма редагування групи (курсу)
    // Estados para almacenar los valores de los campos
    const [classID, setGiD] = useState(null);
    const [title, setTitle] = useState('GROUP_NAME');
    const [locate, setLocate] = useState('AULA_NAME');
    const [time, setTime] = useState('01:30');
    const [timeSt, setTimeSt] = useState('01:30');
    const [timeFn, setTimeFn] = useState('01:30');
    const [note, setNote] = useState('NOTE_TEXT');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [price, setPrice] = useState('GROUP_NAME');
    const [dateSt, setDateSt] = useState('2024-02-22');
    const [dateFn, setDateFn] = useState('2024-07-24');

    //const [note, setNote] = useState('A2+');

    // const [alphaT, setAlphaT] = useState('0.1'); // Valor inicial del color en negro
    // const [alphaB, setlphaB] = useState('0.1'); // Valor inicial del color en negro
    //const navigate = useNavigate();

    // const iconsContext = require.context('./../img/flags/', false, /\.svg$/);
    // const svgFiles = iconsContext.keys();

    //const login_token = useSelector((state)=> state.counter.user.login_token);

    useEffect(() => {
        setGiD(props.myclass.id)
        setTitle(props.myclass.title)     
        setLocate(props.myclass.nameclass)
        setNote(props.myclass.label)
        setTime(props.myclass.timeleft)
        setTimeSt(props.myclass.time)
        setTimeFn(props.myclass.timeend)
        setPrice(0)
        // setNote(props.mydeck.note)
        // setColorT(props.mydeck.colort)
        // setColorB(props.mydeck.colorb)
    }, [props]); // AUTOLOAD

    const [langArray, setLangArray] = useState([]);

    //handleDateStChange

    const handleDateStChange = (e) => {
        setDateSt(e.target.value);
    };

    const handleDateFnChange = (e) => {
        setDateFn(e.target.value);
    };

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

     const handleNameChange = (e) => {
        setTitle(e.target.value);
    };

    const handleLocateChange = (e) => {
        setLocate(e.target.value);
    };

    const handleflagChange = (e) => {
        setTime(e.target.value);
    };

    const handleLNGNameChange = (e) => {
        setNote(e.target.value);
    };

    const handleColorTChange = (e) => {
        setColorT(e.target.value);
    };

    const handleColorBChange = (e) => {
        setColorB(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor
    //     console.log('title:', title);
    //     console.log('level:', level);
    //     console.log('icoAva:', flag);
    //     console.log('colort:', colorT); 
    //     console.log('colorb:', colorB);
    //     console.log('note:', note);

    //     console.log('login_token:', login_token);
    //     console.log('projectid:', props.pid);

    //     const userData = {
    //         projectid: props.pid,
    //         title: title,
    //         level: level,
    //         icoAva: flag,
    //         colort: colorT,
    //         colorb: colorB,
    //         note: note,
    //     };

    //     fetch('/api/school/postAddClass/' + classID, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': 'Bearer ' + login_token,
    //         },
    //         body: JSON.stringify(userData),
    //     })
    //   .then(response => response.json())
    //   .then(response => {
    //       if (response.status===200) {
    //         console.log("API->postAddClass", response)
    //         navigate(`/projects/${props.pid}`)
    //         // dispatch(application.setUpdateTime(response.update));
    //       } else {
    //         // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
    //         console.log("ERROR postAddClass-" , response)
    //     //setLoading(false)
    //     }
    //   });

    };

      function rgbaToHex(rgbaColor) {
        const match = rgbaColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
      
        if (!match) {
        //   throw new Error('Invalid RGBA color format');
        }
      
        const [, r, g, b, a] = match;
      
        // Конвертувати значення кожного каналу в шістнадцятковий формат
        const hexR = (+r).toString(16).padStart(2, '0');
        const hexG = (+g).toString(16).padStart(2, '0');
        const hexB = (+b).toString(16).padStart(2, '0');
        const hexA = Math.round(+a * 255).toString(16).padStart(2, '0');
      
        // Об'єднати значення кольору та альфа-каналу
        // const hexColor = `#${hexR}${hexG}${hexB}${hexA}`;
        const hexColor = `#${hexR}${hexG}${hexB}`;

        return hexColor;
      }


      useEffect(() => {
        const options = document.querySelectorAll('.vodiapicker option');
        const updatedLangArray = [];
    
        options.forEach(option => {
          const img = option.getAttribute('data-thumbnail');
          const text = option.innerText;
          const value = option.value;
    
          const item = (
            <li key={value}>
              <img src={img} alt="" value={value} />
              <span>{text}</span>
            </li>
          );
    
          updatedLangArray.push(item);
        });
    
        setLangArray(updatedLangArray);
      }, []);


    return (
        <div className="form_0">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />
                
                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Назва класу ( {classID}):
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="class_name" name="class_name" type="text" placeholder="NAME" value={title} required onChange={handleNameChange}/>
                        </div>

                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Місце проведення:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-map-marker"></i></span>
                            <input id="class_name" name="class_name" type="text" placeholder="NAME" value={locate} required onChange={handleLocateChange}/>
                        </div>

                        
                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Примітка (короткий опис):
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sticky-note"></i></span>
                            <input id="class_name" name="class_name" type="text" placeholder="NAME" value={note} required onChange={handleLNGNameChange}/>
                        </div>

                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Початок занятя:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-hourglass-start"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={timeSt} onChange={handleflagChange}/>
                        </div>

                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Кінець занятя:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-hourglass-end"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={timeFn} onChange={handleflagChange}/>
                        </div>
                        
                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Час одного занятя:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-hourglass-half"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={time} onChange={handleflagChange} disabled="disabled"/>
                        </div>

                        <fieldset>
                            <legend>Дні занять в тиждень:</legend>

                        <div className="input_field" > 

                            <div >
                                <input id="Ch_l" name="Ch_l" type="checkbox" tabIndex="1"/>
                                <label className="choice" htmlFor="Ch_l">Понеділок</label>
                            </div>

                            <div>
                                <input id="Ch_m" name="Ch_m" type="checkbox" tabIndex="2"/>
                                <label className="choice" htmlFor="Ch_m">Вівторок</label>
                            </div>

                            <div>
                                <input id="Ch_x" name="Ch_x" type="checkbox" tabIndex="3"/>
                                <label className="choice" htmlFor="Ch_x">Середа</label>
                            </div>

                            <div>
                                <input id="Ch_j" name="Ch_j" type="checkbox" tabIndex="4"/>
                                <label className="choice" htmlFor="Ch_j">Четвер</label>
                            </div>

                            <div>
                                <input id="Ch_v" name="Ch_v" type="checkbox" tabIndex="5"/>
                                <label className="choice" htmlFor="Ch_v">Пятниця</label>
                            </div>

                            <div>
                                <input id="Ch_s" name="Ch_s" type="checkbox" tabIndex="6"/>
                                <label className="choice" htmlFor="Ch_s">Субота</label>
                            </div>

                            <div>
                                <input id="Ch_d" name="Ch_d" type="checkbox" tabIndex="7"/>
                                <label className="choice" htmlFor="Ch_d">Неділя</label>
                            </div>
                        </div>
                        </fieldset>
                        <div className="input_field"> 
                            <label className="desc" htmlFor="start_date">Start date:</label>
                            <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                                <input type="date" id="start_date" name="trip-start" value= {dateSt} min="2024-01-01" max="2030-12-31" onChange={handleDateStChange}/>
                            </div>
                        </div>

                        <div className="input_field"> 
                            <label className="desc" htmlFor="start_date">End date:</label>
                            <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-out"></i></span>
                                <input type="date" id="start_date" name="trip-start" value={dateFn} min="2024-01-01" max="2030-12-31" onChange={handleDateFnChange}/>
                            </div>
                        </div>

                        {/* <fieldset>
                            <legend>Форма навчання:</legend>

                            <div className="radio">
                                <input id="radio-1" name="radio" type="radio" disabled/>
                                <label  for="radio-1" className="radio-label">Класична лекційна форма</label>
                                <label className="desc" for="radio-1">Де викладач передає знання студентам через усні лекції або презентації.</label>

                            </div>

                            <div className="radio">
                                <input id="radio-2" name="radio" type="radio" disabled/>
                                <label  for="radio-2" className="radio-label">Семінари та групові заняття</label>
                                <label className="desc" for="radio-1">Де студенти активно взаємодіють між собою та з викладачем для обговорення матеріалу, вирішення завдань тощо.</label>

                            </div>

                            <div className="radio">
                                <input id="radio-3" name="radio" type="radio" checked/>
                                <label  for="radio-3" className="radio-label">Дистанційне навчання</label>
                                <label className="desc" for="radio-1">З використанням онлайн-платформ, відеоконференцій, вебінарів тощо, що дозволяє студентам отримувати освіту з використанням інтернет-технологій.</label>
                            </div>

                            <div className="radio">
                                <input id="radio-4" name="radio" type="radio" disabled/>
                                <label  for="radio-4" className="radio-label">Практичні заняття та лабораторні роботи</label>
                                <label className="desc" for="radio-4">Де студенти навчаються через практичне виконання завдань, експерименти тощо.</label>
                            </div>

                            <div className="radio">
                                <input id="radio-5" name="radio" type="radio"/>
                                <label  for="radio-5" className="radio-label">Індивідуальні консультації</label>
                                <label className="desc" for="radio-5">Де студентам надаються індивідуальні настанови, допомога та підтримка викладачем або наставником.</label>
                            </div>
                        </fieldset> */}
                        
                        

                        <label className="desc" id="class_name_l" htmlFor="class_name">
                            Ціна курсу (з особи за весь період):
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-eur"></i></span>
                            <input id="class_name" name="class_name" type="text" placeholder="NAME" value={price} required onChange={handlePriceChange}/>
                        </div>
                        
                        <div className="input_field" > 
                            <label className="desc" id="typeid_l" htmlFor="lurl">
                                Колір тексту 
                            </label>
                            <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorT} required onChange={handleColorTChange}/>
                            <label className="desc" id="typeid_l" htmlFor="lurl">
                                Колір фону 
                            </label>
                            <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div>

                        {/* <div className="input_field" > 
                            <label className="" htmlFor="Ch_disable">
                                Активувати курс/групу
                                <input id="Ch_disable" name="Ch_disable" type="checkbox" className="toggle" />
                                <span className="toggle-check slide"></span>
                            </label>
                        </div> */}
                        
                        <div className="input_field" > 
                            <div>
                                <input id="Ch_disable" name="Ch_disable" type="checkbox" tabIndex="7"/>
                                <label className="choice" htmlFor="Ch_disable">Активувати курс/групу</label>
                            </div>
                        </div>

                        <br />

                        <input className="button" type="submit" value="UPDATE"/>
                    </form>


                    <div style={{top: "0px", left: "500px", position: "absolute", width: "280px", color:"#b3b3b3", padding: "15px"}}>
                        {/* PREVIEW
                        <div id = {"Group_" + props.ids} className={"card3 "}  style={{ backgroundColor: colorB, margin: "15px"}}>
                            <img className= "dcFlagLogo" src={"https://www.nebo.inupline.com/" + iconsContext(svgFiles[flag])} alt="dcFlagLogo"/>
                            <div className="dclbl01">CLASESS: 0</div>
                            <div className="dclbl02">STUDENTS: {flag}</div>
                            <div className="dclbl04b" style={{ color: colorT}} >{title}</div>
                            <div className="dclbl05b" style={{ color: colorT}} >{note}</div>
                        </div> */}
                    </div>
              </div> 
    );
}