import './style.css';
import { useEffect, useState } from 'react';
import { useParams  } from "react-router-dom"; 

// ICONS
  import IcoHome from '../../img/home.png';  
  import IcoJSON from '../../img/json.png';  
  import IcoNewProject from '../../img/newproject.png';  

  import IcoSettings from '../../img/settings.png';  
  import IcoAccount from '../../img/account.png';  
  import IcoManual from '../../img/manual.png';  
  import IcoDashboard from '../../img/dashboard.png';  

  import ImgRBI from '../../img/rbi_logo.png';  
  import ImgRBItxt from '../../img/rbi_logo_txt.png';  
  import ImgNebo from '../../img/nebo.png';  

  import IcoSearch from '../../img/search.png';  //sadmin.png

// ELEMENOTOS
  import UpMenu, {TagZona, TagLMenu, TagLogo, TagRMenu, AppFullScreen} from "./UpMenu"
  import LeftMenu, { UlMenu, MenuItm } from "./LeftMenu"
  import RightMenu from "./RightMenu"
  import ChatMenu from "./ChatMenu"

  import Linkers from "../linkers/linkers";
  import LeftSideMenu from './LeftSideMenu';
  import ButtonMenu from '../button_menu/button_menu';

  // SIDE menu
  import SideMenu from "./SideMenu"
  import AppInfo from "../SideMenu/AppInfo"
  import AddURL from "../SideMenu/AddURL"

  import PrCompany from "../SideMenu/Company"


  import { useSelector, useDispatch } from 'react-redux';
  import { actUser, application } from '../../redux/store';
  import {useApiData} from "../../functions/useApiData"


function Navbar(props) {
  const dispatch = useDispatch();

  let users = useSelector((state)=> state.counter.user);
  const findtxt = useSelector((state)=> state.application.findtxt);
  const [isFullScreen, setFullScreen] = useState(true);
  const usr_ts = useSelector((state)=> state.counter.user.ts); // TS de usuario
  const usr_picture = useSelector((state)=> state.counter.user.picture); // TS de usuario

  const { cr, pr, gr, id } = useParams() // URL parametro  :cr/:pr/:gr/:id

    const [QRfondo, setQRfondo] = useState('#000000');
    const [QRcolor, setQRcolor] = useState('#FFFFFF');

    const [isCompany, setCompany] = useState("");
    const [cURL, setCurl] = useState("");
    const [isProject, setProject] = useState("");
    const [isActiveGroup, setActiveGroup] = useState("");
    const [isActivePage, setActivePage] = useState("");

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const usrfamily_name = localStorage.getItem('usrfamily_name');
    const usrgiven_name = localStorage.getItem('usrgiven_name');
    const randomCacheBuster = Math.floor(Math.random() * 10000);
    const [animate, setAnimate] = useState(false);

    const [newmsg, setNewMsg] = useState(null); // IMPORT USER

    const apiEndpointClass = '/msg/newlist'; 
    const { data: NewNotificaciones, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);

    useEffect(() => { 
      //console.log ("getDesktop data", ClassDesktop)
      if (NewNotificaciones.countmsg ) {
          // NewNotificaciones.notification
          setNewMsg(NewNotificaciones.countmsg)
          //console.log("NewNotificaciones",NewNotificaciones)
      }
  }, [NewNotificaciones]); // AUTOLOAD

    useEffect(() => {
      const interval = setInterval(() => {
        // Перевірка, якщо newmsg не дорівнює 0, тоді запускаємо анімацію
        if (newmsg > 0) {
          setAnimate(true);
          // console.log("setAnimate(true)");
          setTimeout(() => {
            setAnimate(false);
            // console.log("setAnimate(false)");
          }, 2000); // Час анімації
        }
      }, 5000); // Інтервал анімації

      return () => clearInterval(interval);
    }, []);


    useEffect(() => {
      setCompany(props.usermenu.company);
      setProject(props.usermenu.project);
      if (id){ setActivePage(id);}
      if (gr){ setActiveGroup(gr);}


      //console.log("usr_picture redax", usr_picture)
      //console.log("usrpicture local", usrpicture)

    }, [props]); // AUTOLOAD

    useEffect(() => {
      dispatch(actUser.setUserEmail(usremail));
      dispatch(actUser.setUserPicture(usrpicture));
      dispatch(actUser.setFamilyName(usrfamily_name));
      dispatch(actUser.setGivenName(usrgiven_name));

      // console.log(users)
      // console.log(usrfamily_name)

      // if(users) users = localStorage.getItem("user")
    }, []); // AUTOLOAD

    useEffect(() => {
      if (props.usermenu.links){
        document.documentElement.style.setProperty('--top_body','75px')
      } else {
        document.documentElement.style.setProperty('--top_body','50px')
      }
  
      // console.log(props.usermenu.catalog)
    }, [props.usermenu.links]); // AUTOLOAD

    const handleSearchTermChange = (event) => {
        dispatch(application.setFindText(event.target.value));
    };

    const toggleClass = () => {
      const oldClassName = document.getElementById('Menu00').className;
      const newClassName = oldClassName === 'menuopen' ? 'menuhide' : 'menuopen'
      document.getElementById('Menu00').className=newClassName;

      // ONLY HIDE SUBMENU
      const LSMClassName = document.getElementById('LSMenu').className;
      const nLSMClassName = LSMClassName === 'LSmenuhide' ? 'LSmenuhide' : 'LSmenuhide'
      document.getElementById('LSMenu').className=nLSMClassName;

      var workPageNew = ""
      const workPageOld = document.getElementById('body').className;
      if (workPageOld === 'bodyright' ) workPageNew = 'bodycenter'
      if (workPageOld === 'body' ) workPageNew = 'bodylite'
      if (workPageOld === 'bodylite' ) workPageNew = 'body'
      if (workPageOld === 'bodycenter' ) workPageNew = 'bodyright'
      //const workPageNew = workPageOld === 'body' ? 'bodylite' : 'body'
      document.getElementById('body').className=workPageNew;

      //console.log(workPageOld, workPageNew) 
    }

    const toggleClass1 = () => {
      const oldClassName = document.getElementById('Menu02').className;
      const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
      document.getElementById('Menu02').className=newClassName;
    }

    const toggleClass2 = () => {
      const oldClassName = document.getElementById('Menu_Chat').className;
      const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
      document.getElementById('Menu_Chat').className=newClassName;
    }

    const toggleFullScreen = () => {
      setFullScreen(!isFullScreen); // Cambiar el estado a su opuesto
    };


    const handleMouseEnter = (name) => {
      const element = document.getElementById('ToolTip2');
      if (element) {
          element.textContent = name;
          element.style.display = 'block'; // Mostrar elemento        
      };
    };

    const handleMouseLeave = () => {
      const element = document.getElementById('ToolTip2');
      if (element) {
          element.style.display = 'none'; // Ocultar elemento
      };
  };

  const handleMouseMove = (elname, event) => {
      const elementt = document.getElementById('ToolTip2');
      const element = document.getElementById(`${elname}`);
      const containerRect = element.getBoundingClientRect();

      if (elementt) {
          elementt.style.top = (containerRect.top + 50) + 'px'; // Mostrar elemento        
          elementt.style.left = (event.clientX - 100) + 'px'; // Mostrar elemento        
      };
    };

    return (
        <div className="appbar">
          <div id="ToolTip2"
                style={{
                position: 'fixed',
                display: 'none',
                top: '60px',
                left: '70px',
                // transform: 'translateX(10%)',
                backgroundColor: 'black',
                color: 'white',
                padding: '8px',
                borderRadius: '4px',
                zIndex:'1000',
            }}
          ></div>

            { users.family_name ? 
            <div>
              
              <UpMenu modo = {props.modo} UserInfo = {users} logo={ImgRBI} logotxt={ImgRBItxt}>
                {/* { props.modo == 1 ? null : } */}
                {!usr_ts ? null : 
                  <TagLMenu tag="lmenu">
                    <ButtonMenu MenuShow = {toggleClass} />
                  </TagLMenu>
                }
                
                {!usr_ts ?
                  <TagLogo tag="logo_zona">
                    {/* {LoadAvatar(miUser,"appava",toggle2Class,'m')} */}
                    <img className="applogo" src={ImgNebo} alt="nebo" style={{width:"120px"}}></img>
                    <p className="applogotext" id = "cpt_app">{""} </p>
                    {/* {LoadAvatar(miUser,"appava",toggle2Class,'m')} */}
                  </TagLogo>: 
                  <TagLogo tag="logo_zona">
                    {/* {LoadAvatar(miUser,"appava",toggle2Class,'m')} */}
                    {/* <img className="applogo" src={ImgRBI} alt=" " onClick={() => {setFullScreen(!isFullScreen) ? AppFullScreen(isFullScreen) : AppFullScreen(isFullScreen) }}></img> */}
                    <img className="applogo" src={ImgNebo} alt="nebo" style={{width:"120px"}} onClick={() => {setFullScreen(!isFullScreen) ? AppFullScreen(isFullScreen) : AppFullScreen(isFullScreen) }}></img>

                    {/* <img className="applogotxt" src={ImgRBItxt} alt=" "></img> */}
                    <p className="applogotext" id = "cpt_app">{""} </p>
                    {/* {LoadAvatar(miUser,"appava",toggle2Class,'m')} */}
                  </TagLogo>
                }
                
                {/* {!usr_ts ? null: 
                  <TagZona tag="find_zona">
                    <div className="search-container">
                      <img className="searchIco" src={IcoSearch} alt="search"></img>
                      <input type="text" placeholder="Busque sus aplicaciones" name="search" value={findtxt} onChange={handleSearchTermChange}/>
                    </div>
                  </TagZona> 
                } */}
                
              {!usr_ts ? <TagRMenu tag="ava_zona">
                <p className="navbar_username" id = "cpt_username">{users.family_name + " " + users.given_name} </p>
                <p className="navbar_usercompany" id = "cpt_username">{users.email} </p>
                <img className="appava" src={`${usrpicture}?cache=${randomCacheBuster}`} alt="img"></img> 
                {/* <img className="comentava" src={ImgComent} alt="coment" onClick={toggleClass2}></img> */}
                </TagRMenu>: 
                <TagRMenu tag="ava_zona">
                {/* <p className="navbar_username" id = "cpt_username">{users.family_name + " " + users.given_name} </p>
                <p className="navbar_usercompany" id = "cpt_username">{users.email} </p> */}
                <div className="appava_msg" id="appava_msg"
                  onClick={toggleClass2} 
                  onMouseEnter={() => handleMouseEnter("Сповіщення")}
                  onMouseLeave={() => handleMouseLeave()}
                  onMouseMove={(event) =>handleMouseMove("appava_msg", event )}
                >
                  {/* <i class="fa fa-bell" aria-hidden="true"></i> */}
                  {/* className={animate ? 'clapper' : ''} */}
                  <svg id={animate ? 'bell_anim' : 'bell'} x="0" y="0" viewBox="0 0 459.334 459.334" className={animate ? 'animate' : ''}>
                    <path className={animate ? 'clapper' : ''} d="M175.216,404.514c-0.001,0.12-0.009,0.239-0.009,0.359c0,30.078,24.383,54.461,54.461,54.461
                      s54.461-24.383,54.461-54.461c0-0.12-0.008-0.239-0.009-0.359H175.216z"/>
                    <path className='gong' d="M403.549,336.438l-49.015-72.002c0-22.041,0-75.898,0-89.83c0-60.581-43.144-111.079-100.381-122.459V24.485
                      C254.152,10.963,243.19,0,229.667,0s-24.485,10.963-24.485,24.485v27.663c-57.237,11.381-100.381,61.879-100.381,122.459
                      c0,23.716,0,76.084,0,89.83l-49.015,72.002c-5.163,7.584-5.709,17.401-1.419,25.511c4.29,8.11,12.712,13.182,21.887,13.182
                      H383.08c9.175,0,17.597-5.073,21.887-13.182C409.258,353.839,408.711,344.022,403.549,336.438z"/>
                  </svg>

                {/* <span className="num-count">{newmsg}</span> */}
                {newmsg > 0 && <span className="num-count">{newmsg}</span>}

                </div>
                <img className="appava" src={usrpicture} alt="img" 
                onClick={toggleClass1} 
                onMouseEnter={() => handleMouseEnter("Користувач")}
                onMouseLeave={() => handleMouseLeave()}
                onMouseMove={(event) =>handleMouseMove("appava_msg", event )} referrerPolicy="no-referrer"/>
                {/* <img className="comentava" src={ImgComent} alt="coment" onClick={toggleClass2}></img> */}
                </TagRMenu>
                }
              </UpMenu>

              { props.usermenu.links ? <Linkers company={isCompany} cURL={cURL} project={isProject} group ={isActiveGroup} page={isActivePage} /> : null }

            <LeftMenu UserInfo = {props.UserInfo} >
              <UlMenu tag="menu">
                <MenuItm id={1} url= {"/"} name={"Home"} icon={IcoHome}></MenuItm>
                <MenuItm id={2} url= {"/projects/new"} name={"New Project"} icon={IcoNewProject}></MenuItm>
                <MenuItm id={3} url= {"/"+ isCompany } name={"Documentacion"} icon={IcoManual}></MenuItm>
                {/* <MenuItm id={4} url= {"/"} name={"DASHBOARD"} icon={IcoDashboard}></MenuItm>
                <MenuItm id={5} url= {"/anki"} name={"ANKI_LIST_CARD"} icon={IcoDashboard}></MenuItm>
                <MenuItm id={5} url= {"/traductor"} name={"TRADUCTOR AI"} icon={IcoDashboard}></MenuItm> */}
              </UlMenu>

              <UlMenu tag="menu_down">
                {/* <MenuItm id={10} url= {"oauth2"} name={"Account"} icon={IcoAccount}></MenuItm>
                <MenuItm id={11} url= {"Settings"} name={"Settings"} icon={IcoSettings}></MenuItm> */}
              </UlMenu>
            </LeftMenu>

            <RightMenu json_project = {props.json_project} userprojoined = {props.userprojoined}> 

            </RightMenu>

            <ChatMenu json_project = {props.json_project} userprojoined = {props.userprojoined}> 
                
            </ChatMenu>

            <SideMenu> 
                {props.SideMenu === "appid" && props.PageInfo[props.AppID] ? <AppInfo UnitInfo = {props.PageInfo[props.AppID]} QRcol1 = {QRfondo}  QRcol2 = {QRcolor} /> : null}
                {props.SideMenu === "appid" && props.AppID==="new" ? <AddURL UnitInfo = {props.PageInfo[props.AppID]} QRcol1 = {QRfondo}  QRcol2 = {QRcolor} /> : null}
                {props.SideMenu === "company" ? <PrCompany /> : null}
            </SideMenu>
            {props.usermenu.menu ? <LeftSideMenu array = {props.usermenu}/> : <div id="LSMenu"></div> }
            </div>
              : null }
        </div>
    );
  }

export default Navbar;

