import React , {useState, useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import {useApiData} from "../../functions/useApiData"
import { useSelector, useDispatch } from 'react-redux';

export default function Index(props) {
  const { invid } = useParams() // URL parametro  :cr/:pr/:gr/:id
  const [invite, setInvite] = useState([]); // IMPORT USER
  const user_leng_ui = localStorage.getItem('user_leng_ui');

  const navigate = useNavigate();
  const login_token = localStorage.getItem('login_token');

  const apiEndpointClass = '/invite/id/' + invid; 
  const { data: NewNotificaciones, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);

  useEffect(() => { 
    //console.log ("getDesktop data", ClassDesktop)
    if (NewNotificaciones.invite) {
        setInvite(NewNotificaciones.invite)
        // NewNotificaciones.notification
        //setNewMsg(NewNotificaciones.countmsg)
        console.log("newlist",NewNotificaciones.invite)
    }
}, [NewNotificaciones]); // AUTOLOAD

  function OutME(event) {
    navigate(-1)
  }

  const SendOK = (e) => {
    e.preventDefault();

    const userData = {
      id: invid,
      cmd: "2",
    };

    // Luego, puedes realizar tu solicitud o enviar formData a través de tu lógica de manejo de API
    // Ejemplo con fetch:
    fetch("https://www.nebo.inupline.com/api/web/invite/response", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
        body: JSON.stringify(userData),
      })
      .then(response => response.json())
      .then(data => {
        // Manejar la respuesta de la API
        console.log("ws_upload_card OK", data);
      })
      .catch(error => {
        // Manejar errores
        console.error("ws_upload_card ERROR", error);
      });

      navigate("/")
  };

  const SendNO = (e) => {
    e.preventDefault();

    const userData = {
      id: invid,
      cmd: "3",
    };
    // formData.append("DeckName", deckName);

    // Luego, puedes realizar tu solicitud o enviar formData a través de tu lógica de manejo de API
    // Ejemplo con fetch:
    fetch("https://www.nebo.inupline.com/api/web/invite/response", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
        body: JSON.stringify(userData),
      })
      .then(response => response.json())
      .then(data => {
        // Manejar la respuesta de la API
        console.log("ws_upload_card OK", data);
      })
      .catch(error => {
        // Manejar errores
        console.error("ws_upload_card ERROR", error);
      });

      navigate("/")
  };

    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            <div className='icon_back'><i className="fa fa-arrow-left" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>

            <div className="card_invite">
              <div className="txt_label_01">{invite.projectname}</div>
                <div className="txt_label_03">
                  {user_leng_ui === "0" && "from "}
                  {user_leng_ui === "1" && "desde "}
                  {user_leng_ui === "2" && "від "} 
                  {invite.username}
                </div>
              <div className="txt_label_02">{invite.datecreate}</div>

              <div className='inviteSI' onClick={(e)=> SendOK(e)}> OK </div>
              <div className='inviteNO' onClick={(e)=> SendNO(e)}> NO </div>
            </div>            
        </div>

        </div>
    );
}