//import GhMenu from './item'
import './style.css';
//import React, { useState, useEffect } from 'react';

function ButtonMenu(props) {
    //WELCOM PAGE

    const toggleClass = () => {
      const oldClassName = document.getElementById('nav-icon-x').className;
      const newClassName = oldClassName === 'open' ? '' : 'open'
      document.getElementById('nav-icon-x').className=newClassName;
      props.MenuShow(newClassName);
    }
  
    // if (clos === true) {
    //   document.getElementById('nav-icon-x').className='';
    // }
    
    return (
        <div id="nav-icon-x" onClick={toggleClass}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
    );
  }
  
export default ButtonMenu;