import React , {useState, useEffect} from 'react';
//import "./style_index.css"
import { useNavigate} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';

function Index(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user_leng_ui = localStorage.getItem('user_leng_ui');

    const [lngID, setLngID] = useState(user_leng_ui);

    const [userprojects, setProjects] = useState([]); // IMPORT USER
    const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    //const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    const usremail = localStorage.getItem('usremail');
    const usrpicture = localStorage.getItem('usrpicture');
    const token_device = localStorage.getItem('token_device');
    //const login_token = localStorage.getItem('login_token');
    const usrname = localStorage.getItem('usrname');


    const OPEN_exit = () => {
      navigate(`/exitapp`);
    };


  const handleLevelChange = (e) => {
    setLngID(e.target.value);
    localStorage.setItem('user_leng_ui', e.target.value)

    //const user_leng_ui = localStorage.getItem('user_leng_ui');

};

    return (
        <div>
          <div id = "ANKI_MODE" className="ank_main" >
            {/* <div className='icon_back'><i className="fa fa-sign-out" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
            <div className='icon_back'><i className="fa fa-bell-o" aria-hidden="true"></i></div>
            <div className='icon_back'><i className="fa fa-user-o" aria-hidden="true"></i></div> */}

            {/* <div className="aHello" >Hola</div>
            <div className="aName" >{usrname}</div>
            <div className="aNick" >{usremail}</div> */}

            <div className='BoxRang'>
              <div className='InfoChoisClass'>
                {lngID === "0" && "User profile"}
                {lngID === "1" && "Perfil de usuario"}
                {lngID === "2" && "Профіль користувача"}
              </div>
            </div>
            <div className="form_0" style={{backgroundColor:"grey"}}>
              <label className="desc" id="typeid_l" htmlFor="typeid">
                    Language
              </label>
                  <div className="input_field select_option">
                      <select id="typeid" value={lngID} onChange={handleLevelChange}>
                          <option value={"0"}>ENGLISH</option>
                          <option value={"1"}>ESPAÑOL</option>
                          <option value={"2"}>УКРАЇНСЬКА</option>
                      </select>
                      <div className="select_arrow"></div>
                  </div>
                  </div>
                
            <div className="aClassList">
              <div className='ankRun' onClick={(e) => {OPEN_exit(e)}}>
                {lngID === "0" && "Change user"}
                {lngID === "1" && "Cambiar usuario"}
                {lngID === "2" && "Змінити користувача"}
              </div>
            </div>

            {/* <div className='BoxMainMenu'>
              <div className='Box1'><i className="fa fa-home" aria-hidden="true"  onClick={(e) => {OPEN_Main(e)}}></i></div>
              <div className='Box1 select'><i className="fa fa-book" aria-hidden="true" onClick={(e) => {OPEN_Projects(e)}}></i></div>
              <div className='Box1'><i className="fa fa-bar-chart" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-comments" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-user" aria-hidden="true"></i></div>
            </div> */}
        </div>

        </div>
    );
}

export default Index;

