import React , { useEffect,useState } from 'react';
import { useNavigate} from "react-router-dom";  

// import IcoHome from '../../img/home.png';  //sadmin.png

import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';
import {useApiData} from "../../functions/useApiData"

import './style.css';

import Tabs,{Tab, TabPage,TabBtn } from '../tabs/tabs';

function CardProject(props) {
  const navigate = useNavigate();
  //const randomCacheBuster = Math.floor(Math.random() * 10000);

  //const [defaultFondo, setDefaultFondo] = useState(`https://www.nebo.inupline.com/img/projects/` + 3 + `/fondo.png?cache=${randomCacheBuster}`);


  //WELCOM PAGE
  // console.log ("CardProject props.json",props.json)

  const handleOpenProject = (id) => {
    //console.log ("CardProject id", id)
    toggleClass1();
    navigate("/projects/" + id);
  }

  const handleloadurl = (id, dateupdate) => {
    //console.log ("CardProject id", id)
    const cacheBuster = new Date(dateupdate).getTime(); // Перетворіть в мілісекунди

    return `https://www.nebo.inupline.com/img/projects/${id}/fondo.jpeg?cache=${cacheBuster}`;
  }

  // useEffect(() => {
  //   //props.json.map((items, index) => (setPid(items.project_id) ));
  //   const img = new Image();
  //   img.src = 'https://www.nebo.inupline.com/img/projects/' + pid + '/logo.png';

  //   img.onload = () => {
  //     // La imagen existe, no necesitas hacer nada.
  //     setDefaultPhoto(false);
  //     setSelectedPhoto('https://www.nebo.inupline.com/img/projects/' + pid + '/logo.png');
  //     console.log("! existe")
  //   };
  //   img.onerror = () => {
  //     // La imagen no existe, cambia la URL por otra.
  //     setDefaultPhoto(true);
  //     console.log("no existe", img.src)
  //   };

    
  // }, [pid]);

  const toggleClass1 = () => {
    const oldClassName = document.getElementById('Menu02').className;
    const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
    document.getElementById('Menu02').className=newClassName;
  }

  return (
    <div>
      {props.json ? props.json.map((items, index) => ( 
        <div className="card_project" style={{backgroundImage: `url(${handleloadurl(items.project_id,items.update )})`, backgroundSize: 'cover' }} key = {index} onClick={() => handleOpenProject(items.project_id)}>
          <div className="transbox"></div>
          <div className="item1">{items.company_name}</div>
          <NewIco id={items.project_id} update={items.update}/>
          <div className="item3">{items.project_name}</div>  
          <div className="item4">{items.type_name}</div>
          {/* <div className="item5">Copy link</div> 
          <div className="item6">Invited people</div>
          <div className="item7">5 users</div> */}
          <div className="cButton">...</div>
        </div>
      )): null}
    </div>
  );
}

function NewIco(props) {
  //const randomCacheBuster = Math.floor(Math.random() * 10000);

  const cacheBuster = new Date(props.update).getTime(); // Перетворіть в мілісекунди


  const [defaultPhoto, setDefaultPhoto] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState("https://www.nebo.inupline.com/img/projects/" + props.id + `/logo.png?cache=${cacheBuster}`);

  //setPid(id);
  return (
    <img className="item2" src={selectedPhoto} alt="Avatar" />
  )
} 

function ChatMenu(props) {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usrpicture = localStorage.getItem('usrpicture');
  const [notification, setNotification] = useState([]); // IMPORT USER
  const [newmsg, setNewMsg] = useState(0); // IMPORT USER

  const toggleClass2 = () => {
    const oldClassName = document.getElementById('Menu_Chat').className;
    const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
    document.getElementById('Menu_Chat').className=newClassName;
  }

  const apiEndpointClass = '/msg/newlist'; 
  const { data: NewNotificaciones, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpointClass);

  useEffect(() => { 
    //console.log ("getDesktop data", ClassDesktop)
    if (NewNotificaciones.notification && NewNotificaciones.notification.length > 0) {
        setNotification(NewNotificaciones.notification)
        // NewNotificaciones.notification
        setNewMsg(NewNotificaciones.countmsg)
        //console.log("NewNotificaciones",NewNotificaciones)
    }

}, [NewNotificaciones]); // AUTOLOAD

    return (
        <div className="menu2hide" id="Menu_Chat">
            <div className = 'UserNotificationBox'>
            <div className="txt_label_N1">У Вас {newmsg} сповіщення:</div>

            {notification ? notification.map((items, index) => ( 
            <div className="card_notification" key = {index} onClick={toggleClass2}>
              <div className="txt_label_00"><i className="fa fa-envelope-o"></i></div>
              <div className="txt_label_01">{items.msg}</div>
              <div className="txt_label_02">{items.datein}</div>
              <div className="txt_label_03">від {items.from_username}</div>
              <div className="txt_label_04">В очікувані відповіді</div>
              <div className="txt_label_05"><i className="fa fa-envelope-o"></i> Докладніше</div>
            </div>)): null}

            {/* <div className="card_notification">
              <div className="txt_label_00"><i className="fa fa-envelope-o"></i></div>
              <div className="txt_label_01">Запрошення: Школа іноземних мов</div>
              <div className="txt_label_04">В очікувані відповіді</div>
              <div className="txt_label_05"><i className="fa fa-envelope-o"></i> Докладніше</div>
            </div>

            <div className="card_notification">
              <div className="txt_label_00"><i className="fa fa-envelope-o"></i></div>
              <div className="txt_label_01">Запрошення: Від Heorhiy Klimov</div>
              <div className="txt_label_04">В очікувані відповіді</div>
              <div className="txt_label_05"><i className="fa fa-envelope-o"></i> Докладніше</div>
            </div> */}

            <div className="txt_label_N2">Показати усі повідомлення</div>
            <div className="txt_label_N3" onClick={toggleClass2}>сховати</div>


              {/* <Tabs> 
                <Tab label="My Projects">
                  
                </Tab>
                <Tab label="Invited...">
                  
                </Tab>
                <Tab label="Ajustes">
                  
                </Tab>
              </Tabs> */}

                {/* <div className='policy'> © 2023 - Restaurant Brands Iberia</div>
                <div className='prvt'> Privacidad</div> */}
            </div>
        </div>
    )
};


export default ChatMenu;


