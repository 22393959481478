import './style.css';
import {  Link } from "react-router-dom";

function Linkers(props) {
    return (
        <header className="StyleLinkersBar">
            <ol className="breadcrumb">
                {!props.company? "": <li className="breadcrumb-item"><Link to= {"/" + props.company}>{props.company}</Link></li> }
                {!props.project? "": <li className="breadcrumb-item"><Link to={"/" + props.company + "/" + props.project}>{props.project}</Link></li>}
                {!props.group? "": <li className="breadcrumb-item"><Link to={"/" + props.company + "/" + props.project + "/" + props.group}>{props.group}</Link></li>}
                {!props.page? "": <li className="breadcrumb-item active"><Link to={"/" + props.company + "/" + props.project + "/" + props.group + "/" + props.page}>{props.page}</Link></li> }
            </ol>
        </header>
    );
}
  
export default Linkers;


