// import './../style.css';
// import '../../../css/widgsystem.css';
// import './style.css';

import React , {useState, useEffect} from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
// import { myUsers, AppCaption} from "../../../redux/state";
//import GostAva from '../../../img/guest_nano.png';  //sadmin.png
import ListTrello, { ListItm, ListElement }  from "./widgets/listtrello";
// import DayBox from "./widgets/daybox";
import SWlistbox from "./widgets/SWlistbox";


function TestTouch(props) {
      return (
        <div className = "saback-box">
            <ListTrello lwidth = "75" margin = "17">
          <ListItm name = "XXX">
            
            {/* <DayBox></DayBox> */}

          </ListItm>

          <ListItm name = "asdsdf">
            
          </ListItm>

          <ListItm name = "asdsdf">
          
           <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '3'></ListElement>
            <ListElement exmpl = '3'></ListElement>
          </ListItm>

          <ListItm name = "asdsdf">
            <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>            
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '3'></ListElement>
            <ListElement exmpl = '3'></ListElement>            
            <ListElement exmpl = '4'></ListElement>

            <SWlistbox></SWlistbox>

          </ListItm> 
          </ListTrello> 


          {/* <SwipeComponent/> */}

          </div>
      );
    }

    export default TestTouch;


    const SwipeComponent = () => {
      const [currentIndex, setCurrentIndex] = useState(0);
      const [xDown, setXDown] = useState(null);
      const [yDown, setYDown] = useState(null);
    
      const switcher = (newIndex, bl, evt) => {
        const $list = document.getElementById(newIndex);
        const $swipe = document.querySelector('.swipe');
    
        if ($list) {
          $swipe.classList.remove('animate', 'animate2');
    
          if (bl === -1) {
            $list.classList.add('animate2');
          } else if (bl === 1) {
            $list.classList.add('animate');
          } else if (bl === 0) {
            $swipe.classList.remove('animate', 'animate2');
          }
        }
      };
    
      const handleTouchStart = (evt) => {
        const index = evt.target.id;
        if (!index) {
          document.querySelector('.swipe').classList.remove('animate');
        }
    
        if (evt.type === 'touchstart') {
          setXDown(evt.touches[0].clientX);
          setYDown(evt.touches[0].clientY);
        } else {
          setXDown(evt.clientX);
          setYDown(evt.clientY);
        }
      };
    
      const handleTouchEnd = () => {
        setXDown(null);
        setYDown(null);
      };
    
      const handleTouchMove = (evt) => {
        let xUp, yUp;
        const index = evt.target.id;
    
        if (!xDown || !yDown) {
          return;
        }
    
        if (evt.type === 'touchmove') {
          xUp = evt.touches[0].clientX;
          yUp = evt.touches[0].clientY;
        } else {
          xUp = evt.clientX;
          yUp = evt.clientY;
        }
    
        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;
    
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          if (xDiff > 0) {
            setCurrentIndex(prevIndex => prevIndex < 1 || (xDiff === 0) ? prevIndex + 1 : 1);
          } else {
            setCurrentIndex(prevIndex => prevIndex > -1 || (xDiff > 1) ? prevIndex - 1 : -1);
          }
          switcher(index, currentIndex, evt);
        }
    
        setXDown(null);
        setYDown(null);
      };
    
      useEffect(() => {
        const $listsWrap = document.querySelector('.scrollable-content');
        const $mainContent = document.querySelector('.content');
    
        $listsWrap.addEventListener('touchstart', handleTouchStart, true);
        $listsWrap.addEventListener('touchmove', handleTouchMove, true);
        $mainContent.addEventListener('mousedown', handleTouchStart, true);
        $mainContent.addEventListener('mousemove', handleTouchMove, true);
        $mainContent.addEventListener('mouseup', handleTouchEnd, true);
    
        return () => {
          $listsWrap.removeEventListener('touchstart', handleTouchStart, true);
          $listsWrap.removeEventListener('touchmove', handleTouchMove, true);
          $mainContent.removeEventListener('mousedown', handleTouchStart, true);
          $mainContent.removeEventListener('mousemove', handleTouchMove, true);
          $mainContent.removeEventListener('mouseup', handleTouchEnd, true);
        };
      }, []);
    
      return (
        <div className="content">
            <div className="scrollbale-content">
              <ul className="lists">
                <li className="swipe"  id={'swipe_'+ 0}>
                  <div className="toolfirm firma"><i className="fa fa-check-square-o" aria-hidden="true"></i></div>
                  <div className="box">

                    {/* {LoadAvatar(item, "list_partes_user", null ,'m')} */}

                    <div className="pusername">{231} {3}</div>
                    
                    <div className="pcompany">EMPRESA</div>
                    <div className="plugar">LUGAR DE TRABAJO</div>
                    <div className="pdate">01-05 ENERO 2025</div>
                    <div className="phoras">0</div>

                    <div className="dsem">
                      <div className="sem sd1">9</div>
                      <div className="sem sd2">9</div>
                      <div className="sem sd3">9</div>
                      <div className="sem sd4">9</div>
                      <div className="sem sd5">8</div>
                      <div className="sem sd6">0</div>
                      <div className="sem sd7">0</div>
                    </div>

                    <div className="nsem">
                      <div className="sen ">l</div>
                      <div className="sen ">m</div>
                      <div className="sen ">x</div>
                      <div className="sen ">j</div>
                      <div className="sen ">v</div>
                      <div className="sen ">s</div>
                      <div className="sen ">d</div>
                    </div>
                    


                  </div>
                  <div className="toolbox trash"><i className="fa fa-pencil" aria-hidden="true"></i></div>
                  <div className="toolbox view"><i className="fa fa-eye" aria-hidden="true"></i></div>
                  {/*<div className="toolbox edit"><i className="fa fa-pencil" aria-hidden="true"></i></div>*/}

                </li>
              </ul>
            </div>
          </div>
      );
    };
    