import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams, useHistory } from "react-router-dom";  
import './style_anki.css';
import logo from './../img/logo_company.png';  //sadmin.png
import { useSelector } from 'react-redux';

export default function AnkiMain(props) {

    const { pid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const login_token = useSelector((state)=> state.counter.user.login_token);
    const [card_c1, setC_1] = useState(0);
    const [card_c2, setC_2] = useState(0);
    const [card_c3, setC_3] = useState(0);

    // console.log ("pid", pid)
    const navigate = useNavigate();
    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    const usr_name = useSelector((state)=> state.counter.user.name); // email de usuario
    const usr_email = useSelector((state)=> state.counter.user.email);

    // const usr_companyid = useSelector((state)=> state.counter.company.id);
    const usr_companyid = useSelector((state)=> state.application.app.projectid);
    const usr_companyname = useSelector((state)=> state.application.app.projectname);

    const class_all = useSelector((state)=> state.application.app.classname);
    const cards_all = useSelector((state)=> state.application.app.ankicards.cards);

    useEffect(() => {
        // iconLink()
        console.log("REDUX", class_all)
        document.querySelector('.appbar').style.display = 'none';
        document.getElementById('root').style.backgroundColor = '#1E1E1E';
    }, [pid]); // AUTOLOAD

    const handleHome = (childValue) => {
        //document.querySelector('.appbar').style.display = 'block';
        navigate(-1);
    };

    const handleRun = (childValue) => {
        navigate(`/anki/run`);
    };

    const cmdNextCard = () => {
        fetch('https://www.nebo.inupline.com/api/web/anki/nextcard', {
          method: 'GET',
          headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + login_token,
          },
        })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
                setC_1(response.c_new)
                setC_2(response.c_rep)
                setC_3(response.c_pas)
                console.log("nextcard-" , response)
            } else {
                console.log("ERROR Deck-" , response)
            //setLoading(false)
            }
        }); 
      }


    return (

        <div id = "ANKI_MODE" className="ank_main" >
            <div className='icon_back' onClick={handleHome}><i className="fa fa-arrow-left" aria-hidden="true"></i></div>
            <div className="aHello" >Hola</div>
            <div className="aName" >{usr_name}</div>
            <div className="aNick" >{usr_email}</div>

            <div className="aClassList">
                <img className= "ankLogo" src={selectedPhoto} alt="logo "/>
                <div className="ankName" >{usr_companyname}</div>
                <div className="ankPowerBy" >class_all.levels.title <i className="fa fa-angle-double-right" aria-hidden="true"></i> class_all.groups[0].title class_all.groups[0].nameclass</div>
                <div className='ankSellvl'>Lv. {class_all.groups[0].level}</div>
                <div className='ankProgres'>0%</div>

                <div className='BoxRang'>
                    <div className='Box1'>{card_c1}
                        <div className='Box1lbl'>Нові</div>
                    </div>
                    <div className='Box1'>{card_c2}
                        <div className='Box1lbl'>Повторити</div>
                    </div>
                    <div className='Box1'>{card_c3}
                        <div className='Box1lbl'>Навчання</div>
                    </div>
                </div>

                <div className='ankRun' onClick={handleRun}><i className="fa fa-play" aria-hidden="true"></i> ВЧИТИ ЗАРАЗ</div>
            </div>
        </div>
    );
}