import { configureStore } from '@reduxjs/toolkit';

import ACCOUNT_USER from "./slice/user";
import ACCOUNT_APP from "./slice/app";

// BASIC DE REDAX.actions
export const actUser = ACCOUNT_USER.actions;// CS_actions;
export const application = ACCOUNT_APP.actions;

// BASIC DE REDAX.reducer
export const store = configureStore({
  reducer: {
    counter: ACCOUNT_USER.reducer,
    application: ACCOUNT_APP.reducer}
});