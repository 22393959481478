import React , {useRef, useState, useEffect} from 'react';
import "./style_player.css"
import { useNavigate} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import { actUser, application } from '../../redux/store';
// import Slider from "react-slick";
// import { baseUrl } from "./config";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function Index(props) {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    // const [userprojects, setProjects] = useState([]); // IMPORT USER
    // const [userprojoined, setProjoined] = useState([]); // IMPORT USER
    // //const [userInSchool, setInSchool] = useState([]); // IMPORT USER
    // const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + 5 + '/logo.png');

    // const usremail = localStorage.getItem('usremail');
    // const usrpicture = localStorage.getItem('usrpicture');
    // const token_device = localStorage.getItem('token_device');
    // //const login_token = localStorage.getItem('login_token');
    // const usrname = localStorage.getItem('usrname');

    const [playList, setPlayList] = useState([]); // IMPORT DECKS

    const login_token = useSelector((state)=> state.counter.user.login_token);

    useEffect(() => { handleGetDeck(11) }, [login_token]); // AUTOLOAD

    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      // focusOnSelect: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 2
    };

    const handleGetDeck = (id) => {
      
      fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + id, {
          method: 'GET',
          headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + login_token,
          },
      })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
              //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
              
              // setLoading(false)
              // console.log("/deck-" , response.deck[0])
              // console.log("/cards-" , response.cards[0])

              // setInfoText(response.message)
              // setInfodeck(true)

              

              // Створення масиву setPlayList
              const loadPlayList = response.cards.map(cardData => {
                  const card = cardData.card[0];
              
                  return {
                  name: card.a,
                  author: card.c,
                  duration: card.e,
                  url: card.d,
                  };
              });

              setPlayList(loadPlayList)

              console.log("playList",loadPlayList)
          } else {
              // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
              console.log("ERROR Deck-" , response)
              
          //setLoading(false)
          }
      }); 
  };


    return (
        <div>
            {/* <div className='icon_back'><i className="fa fa-sign-out" aria-hidden="true" onClick={(e) => {OutME(e)}}></i></div>
            <div className='icon_back'><i className="fa fa-bell-o" aria-hidden="true"></i></div>
            <div className='icon_back'><i className="fa fa-user-o" aria-hidden="true"></i></div> */}

            {/* <div className="aHello" >Hola</div>
            <div className="aName" >{usrname}</div>
            <div className="aNick" >{usremail}</div> */}

            {/* <div className='BoxRang'>
              <div className='InfoChoisClass'>Модуль АудіоКартки в розробці</div>


            </div> */}

            
              <div className="slider-container">
                    {/* <Slider {...settings}>
                      <div>
                        <img width = "120px" src={ "https://react-slick.neostack.com/img/react-slick/abstract01.jpg"} />
                      </div>
                      <div>
                        <img width = "120px" src={ "https://react-slick.neostack.com/img/react-slick/abstract02.jpg"} />
                      </div>
                      <div>
                        <img width = "120px" src={ "https://react-slick.neostack.com/img/react-slick/abstract03.jpg"} />
                      </div>
                      <div>
                        <img width = "120px" src={ "https://react-slick.neostack.com/img/react-slick/abstract04.jpg"} />
                      </div>
                    </Slider> */}
                  </div>

            {/* <MusicPlayer list= {playList}/> */}
                
            {/* <div className='BoxMainMenu'>
              <div className='Box1'><i className="fa fa-home" aria-hidden="true"  onClick={(e) => {OPEN_Main(e)}}></i></div>
              <div className='Box1 select'><i className="fa fa-book" aria-hidden="true" onClick={(e) => {OPEN_Projects(e)}}></i></div>
              <div className='Box1'><i className="fa fa-bar-chart" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-comments" aria-hidden="true"></i></div>
              <div className='Box1'><i className="fa fa-user" aria-hidden="true"></i></div>
            </div> */}

        </div>
    );
}


const MusicPlayer = ({list}) => {
  const [songs, setSongs] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  
  const progressBarRef = useRef(null);


  useEffect(() => {
      if (list) {setSongs(list)};
  }, [list]);

  useEffect(() => {
    if (isPlaying) {
      // Ваш код для відтворення пісні (використовуйте аудіо API або бібліотеки)
      const audio = new Audio(songs[currentSongIndex]?.url);

      audio.play(); // Reproducir el audio después de 2 segundos

    //   let counter = 0;

    // const intervalo = setInterval(() => {
    //   if (counter < 3) { // Repetir tres veces
    //     audio.currentTime = 0; // Reiniciar la reproducción al principio
    //     //audio.play(); // Reproducir el audio
    //     setTimeout(() => {
    //       audio.play(); // Reproducir el audio después de 2 segundos
    //     }, 3000);
    //     counter++; // Incrementar el contador
    //   } else {
    //     clearInterval(intervalo); // Detener el intervalo después de tres repeticiones
    //     nextSongHandler()
    //   }
    // }, 1000); // Intervalo de 3 segundos (3000 milisegundos)

        audio.addEventListener('ended', () => {
        // При завершенні пісні переходимо на наступну
        // setTimeout(setIsPlaying(true), 2000) ;
        // setTimeout(setIsPlaying(true), 2000) ;
        setTimeout(nextSongHandler, 2000) ;
        // nextSongHandler()
        console.log(currentSongIndex)
      });

      const handleTimeUpdate = () => {
        setCurrentTime(Math.floor(audio.currentTime));
          // Оновлюємо ширину прогрес-бару відповідно до часу відтворення
          const progressPercent = (audio.currentTime / audio.duration) * 100;
          progressBarRef.current.style.width = `${progressPercent}%`;
      };
  
      const handleLoadedMetadata = () => {
        setDuration(Math.floor(audio.duration));
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);

      // audio.volume = isMuted ? 0 : 1;

      return () => {
        // При зміні пісні або вимиканні компонента зупиняємо відтворення
        audio.pause();
        //audio.currentTime = 0;
      };
    }
  }, [currentSongIndex, isPlaying, songs]);

  const playPauseHandler = () => {
    setIsPlaying(!isPlaying);
  };

  const prevSongHandler = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex - 1 + songs.length) % songs.length);
  };

  const nextSongHandler = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
  };

  const playSongHandler = (index) => {
      setCurrentSongIndex((index));
      setIsPlaying(true);
      //console.log("playSongHandler", index)
  };

  // const toggleMute = () => {
  //     setIsMuted(!isMuted);
  // };

  return (
    <>
      <div className="app_player-ctn">
          <div className="app_infos-ctn">
              <div className="app_timer"> {currentTime}</div>
              <div className="app_title">{songs[currentSongIndex]?.name}</div>
              <div className="app_duration">{duration}</div>
          </div>
          <div id="APPmyProgress">
              <div ref={progressBarRef} id="APPmyBar"></div>
          </div>
          <div className="app_btn-ctn">
              <div className="app_btn-action first-btn" onClick={prevSongHandler}>
                  <div id="btn-faws-back">
                  <i className='fas fa fa-step-backward'></i>
                  </div>
              </div>
              <div className="app_btn-action" onclick="rewind()">
                  <div id="btn-faws-rewind">
                      <i className='fas fa fa-backward'></i>
                  </div>
              </div>
              <div className="app_btn-action" onclick="toggleAudio()">
                  <div id="btn-faws-play-pause" onClick={playPauseHandler}>
                  {isPlaying ? <i className='fas fa fa-pause'></i> : <i className='fas fa fa-play'></i>}
                  </div>
              </div>
              <div className="btn-play" onclick="forward()">
                  <div id="btn-faws-forward">
                  <i className='fas fa fa-forward'></i>
                  </div>
              </div>
              <div className="app_btn-action" onClick={nextSongHandler}>
                  <div id="btn-faws-next">
                  <i className='fas fa fa-step-forward'></i>
                  </div>
              </div>
              
              {/* <div className="btn-mute" id="toggleMute" onClick={()=>setListPlayer(!listPlayer) }>
                  <i className="fas fa fa-list" aria-hidden="true"></i>
              </div> */}
          </div>
      </div>

      <div className="app_playlist" >
        {songs && songs.length > 0 ? songs.map((itemg, index) => ( 
            <ListSound key = {index} keys = {index} track={itemg} act = {currentSongIndex} playindex = {playSongHandler}/>
            )) :  <div>Список відтворення пустий (у цій колоді немає аудіо файлів)</div>
        }
      </div>
    </>
  );
};

const ListSound = (props) => {

  const handlePlayPause = (index) => {
      props.playindex(index)
  }

  return (
    <div className="app_playlist-track-ctn" id = {"audio_" + props.keys} onClick={() => handlePlayPause(props.keys)}>
        <div className="playlist-btn-play">
            {props.keys === props.act ? <i className='fas fa fa-pause' height="40" width="40"></i> : <i className='fas fa fa-play' height="40" width="40"></i>}
        </div>
        <div className="playlist-info-track">{props.keys}. {cleartext(props.track.author)}</div>
        <div className="playlist-duration">{props.track.name}</div>
    </div>
  );
}

const cleartext = (html) => {
  const htmlText = html
  // Створення тимчасового DOM-елемента
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlText;

  // Отримання чистого тексту без HTML-тегів
  const plainText = tempElement.innerText || tempElement.textContent;
  return plainText
};

export default Index;

const Carousel = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === children.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? children.length - 1 : currentSlide - 1);
  };

  return (
    <div className="carousel">
      <div className="carousel-container">
        {React.Children.map(children, (child, index) => (
          <div className={index === currentSlide ? "slide active" : "slide"}>{child}</div>
        ))}
        <button onClick={prevSlide} className="prev">&#10094;</button>
        <button onClick={nextSlide} className="next">&#10095;</button>
      </div>
    </div>
  );
};